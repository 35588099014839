import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import {
  callAPIMiddleware,
  startCloseMiddleware,
  businessRulesMiddleware,
  formMiddleware,
  formSubmit,
  guidanceSubmit,
  addressMiddleware,
  analyticsMiddleware,
} from '../middleware'

import appReducer from '../reducers'

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable no-underscore-dangle */

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }

  return appReducer(state, action)
}

export const middleware = [
  thunk,
  callAPIMiddleware,
  formSubmit,
  guidanceSubmit,
  startCloseMiddleware,
  businessRulesMiddleware,
  formMiddleware,
  addressMiddleware,
  analyticsMiddleware,
]
const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middleware),
  ),
)

const persistor = persistStore(store)

export {
  store,
  persistor,
}
