import { store as ReduxStore } from 'store'
import { submitForm, submitGuidance, updateStateObject } from 'actions/form'
import { findIndex } from 'lodash'
import {
  isEmptyObj,
  isUndefinedOrNull,
  compose,
  isFalsy,
  isEmptyArray,
} from 'common/utils'
import routes from 'common/routes'
import Async from '../containers/HOC/Async'
import history from '../internalHistory'


/*
 * return Routes information based on Guidance Cookie.
 * Routes folder has changed to equate the correct path.
*/

const routeInfo = (info) => {
  const { components } = routes

  const pathObj = {
    split: {
      paths: routes.paths.split,
    },
    default: {
      paths: routes.paths.default,
    },
    guidanceJourney: {
      paths: routes.paths.guidance,
    },
  }
  if (info === 'paths') {
    return pathObj.split.paths
  }
  if (info === 'components') return components

  return null
}

/*
 * Underscored functions are used only private.
 * Just a convention doesn't actually make it private
 */

/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
class Pager {
  constructor() {
    this.routes = []
    this.components = routeInfo('components')
    this.path = {
      current: 0,
      next: 0,
      compKey: '',
      nextCompKey: '',
      completeUrl: '',
    }
    this.panicPaths = []
    this.panicIsDirty = false
    this.pathValue = ''
  }

  /**
   * Find index of pathname
   */
  _findByPath(path) {
    return findIndex(this.routes, p => p === path)
  }

  _resetRoutes(compKey) {
    if (compKey === '/your-needs') this.routes = [...routes.paths.split]

    return false
  }

  /**
   * Updates the browser path
   */
  _updatePath = (path = '/') => history.push(path)

  /**
   * is the next path within range of the routes array
   */
  _isNextPathInRange = () => this.path.next + 1 <= this.routes.length

  /**
   * Updates the next paths to the new index
   */
  _updateNextCompKey = (inc = 1) => {
    this.path.next += inc
    this.path.nextCompKey = this.routes[this.path.next]
  }

  /**
   * Are joint pages allowed
   */
  _allowJoint = store =>
    store && store.form && store.form.who.value === 'joint'

  /**
   * Is the next component skippable, if so check the next component and if that is skippable,
   * continue till it finds the next component or do the finalcheck
   * checks state to see if its already been entered, it check if the component object has the
   * skippable path, and finaiy is the user has manually updated the page, if not skip
   *
   * if index is passed it will return the next non skippable index of the route
   * otherwise it update the path.next and path.nextCompKey values
   */
  _isSkippable(index) {
    const { nextCompKey, next } = this.path

    const nextIndex = index || next
    const compKey = isUndefinedOrNull(index) ? nextCompKey : this.routes[index]

    const store = ReduxStore.getState() ? ReduxStore.getState() : {}
    const isJoint = this._allowJoint(store)

    if (this._isNextPathInRange()) {
      const componentToCheck = this.components[compKey]
      const componentStore = store.form[componentToCheck.store] || {}
      const storeIsValid = (!isUndefinedOrNull(componentStore) || !isEmptyObj(componentStore))
      const storeIsNotEmpty = storeIsValid && (!isFalsy(componentStore.value) || (isJoint && !isFalsy(componentStore.value2)))

      if ((storeIsNotEmpty && !componentStore.isDirty && componentToCheck.skippable) ||
        (!isJoint && this.routes[nextIndex].includes('joint'))) {
        if (isUndefinedOrNull(index)) {
          this._updateNextCompKey()
        }
        return this._isSkippable(!isUndefinedOrNull(index) ? index + 1 : undefined)
      }
      return index
    }
    return index
  }

  /**
   * Returns url with the joint removed.
   */
  _removeJoint = str =>
    str && str.includes('/joint') ? str.replace('/joint', '') : str

  /**
   * If user when navigated manually or showhow the index gets out of sync this updates
   */
  _checkPath() {
    const windowPath = this._findByPath(window.location.pathname)
    const { current } = this.path

    if (current !== windowPath) {
      this.path.completeUrl = this.routes[windowPath]
      this.path.current = windowPath
      this.path.next = windowPath + 1
      this.path.compKey = this.routes[windowPath]
      this.path.nextCompKey = this.routes[windowPath + 1]
    }
  }

  /**
   * Check all required data is collected if not rolvse to the missing infos page
   */
  _finalCheck() {
    const { components } = this
    this.routes.forEach(r => {
      if (components[r]) {
        const componentToCheck = this.components[r]
        const store = ReduxStore.getState() ? ReduxStore.getState() : {}
        const componentStore = store.form[componentToCheck.store] || {}
        const storeIsValid = (!isUndefinedOrNull(componentStore) || !isEmptyObj(componentStore))

        /** Route gets added to panic paths if:
          * - Page Store is not valid(empty or undefined)
          * - If the store is valid but the value is empty or falsy
          */

        // Filter out containers store from panic paths if not necessary to be selected
        if (store.form.address.existingMortgage === 'no') {
          if (this.panicPaths.includes('/mortgage')) {
            const filteredPanic = this.panicPaths.filter(item => item !== '/mortgage')
            this.panicPaths = filteredPanic
          }
        }


        // If not joint check value1
        if (
          (!r.includes('joint')) &&
          (!storeIsValid || isFalsy(componentStore.value))
        ) {
          this.panicPaths.push(r)
          this.panicIsDirty = true
        } else if (
          // if joint check value2
          (r.includes('joint') && this._allowJoint()) &&
          (!storeIsValid || isFalsy(componentStore.value2))
        ) {
          this.panicPaths.push(r)
          this.panicIsDirty = true
        }
      }
    })

    if (!isEmptyArray(this.panicPaths)) {
      this.path.current = 0
      this.path.next = 0
      this.path.compKey = this.panicPaths[0]
      this.path.nextCompKey = this.panicPaths[0]
      this.next()
    } else {
      const formData = ReduxStore.getState().form

      if (formData.needs.value === 'calculator') {

        // Moved this outside the ReduxStore.dispatch(submitGuidance) promise
        // because in certain scenarios, incorrect CI value would be sent to the
        // service as CI was getting set correctly only after the pricing call.
        // This means, includeCI value was true when it should not be and vice versa.
        const {
          payoutType: { value: payoutTypeValue },
          children: { covered: childrenCovered, value: { ageOfYoungest } }
        } = formData

        let ciValue = 'ci'
        if (payoutTypeValue === 'poci' && childrenCovered && ageOfYoungest < 18) {
          ciValue = 'cci'
        } else if (payoutTypeValue === 'poci' && !childrenCovered && ageOfYoungest >= 18) {
          ciValue = 'ci'
        } else if (payoutTypeValue !== 'poci') {
          ciValue = 'noci'
        }

        ReduxStore.dispatch(updateStateObject({
          ci: {
            ...ReduxStore.getState().form.ci,
            value: ciValue,
          },
        }))

        ReduxStore.dispatch(submitGuidance())
          .then(() => {
            // update form here with values
            // CoverType + PolicyType is selected via user and is already pre-dispatched
            const reduxStoreData = ReduxStore.getState().form
            const { data } = reduxStoreData
            const { lifeGuidance: { recommendedSumAssured } } = data
            const { ciGuidance: { recommendedCI, recommendedChildCI } } = data
            const { termGuidance: { recommendedTerm } } = data

            ReduxStore.dispatch(updateStateObject({
              amount: {
                ...reduxStoreData.amount,
                value: recommendedSumAssured
              },
              ci: {
                ...reduxStoreData.ci,
                ciAmount: recommendedCI,
                cciAmount: recommendedChildCI,
              },
              term: {
                ...reduxStoreData.term,
                value: recommendedTerm,
              }
            }))
          })
          .then(() => {
            history.push('/summary')
          })
      } else {
        ReduxStore.dispatch(submitForm())
        history.push('/indicative-quote')
      }
    }
  }

  /**
   * Set up logic
   */
  createPager(params, ...funcs) {
    this.routes = isUndefinedOrNull(params) ? routeInfo('paths') : params
    if (funcs) {
      compose(...funcs)(this.path)
    }
    const index = this._findByPath(window.location.pathname)
    this.path.current = index
    this.path.next = index + 1
    this.path.compKey = this.routes[index]
    this.path.nextCompKey = this.routes[index + 1]
    if (this.path.compKey !== '/your-needs') this.path.nextCompKey = this.routes[index + 1]
  }

  /**
   * Retruns array of all paths for pathr loops
   */
  getRoutes() {
    return this.routes
  }

  /**
   * Retunrs component name for routing
   */
  getComponent() {
    this._checkPath()
    this._resetRoutes(this.path.compKey)
    return this.components[this.path.compKey]
  }



  /**
   * Returns component to load from split test paths
   */
  getNextComponentFromNeeds() {
    const store = ReduxStore.getState()
    const need = store.form.needs.value
    this.pathValue = need

    // push routes to array to get new route array.
    if (need === 'popular') this.routes = [...routes.paths.split, ...routes.paths.default]
    else if (need === 'calculator') this.routes = [...routes.paths.split, ...routes.paths.guidance]
    else this.routes = [...routes.paths.split, ...routes.paths.default]

    // preload on nextCompKey
    const index = this._findByPath(window.location.pathname)
    this.path.nextCompKey = this.routes[index + 1]
    const next = this.getNext()
    const nextComponent = Async({ loader: () => import(/* webpackChunkName: "[request]" */`containers/${next}`) })
    nextComponent.preload().catch(e => e)

    // nextPage
    this.next()
  }

  /**
   * Get the first url that is not skippable
   * used on router for the default path
   * e.g. if coverfor is already populated and is skippable skip to name page
   */
  getFirstCompent() {
    const index = this._isSkippable(0)
    return this.routes[index]
  }

  /**
   * Gets next path for dumb containers
   */
  next() {
    const store = ReduxStore.getState() ? ReduxStore.getState() : {}
    const existingMortgage = store.form.address?.existingMortgage
    const value = store.form.ci?.value
    const hasChildren = store.form.children.value?.hasChildren
    const policy = store.form.who.value
    const needs = store.form.needs.value

    const {
      next,
      nextCompKey,
      compKey,
    } = this.path

    // skip mortgage page if no existing motgage
    const isExistingMortgage = (
      existingMortgage === 'no' &&
      compKey === '/address' &&
      nextCompKey === '/mortgage'
    )

    // skip cci
    const isCCISplitTestNoCI = (
      value === 'noci' &&
      compKey === '/critical-illness' &&
      nextCompKey === '/child-critical-illness'
    )

    // skip partner and children
    const singleNoChildren = (
      policy === 'single' &&
      hasChildren === null &&
      needs === 'calculator' &&
      nextCompKey === '/partner'
    )

    // skip partner
    const singleWithChildren = (
      needs === 'calculator' &&
      policy === 'single' &&
      hasChildren === true &&
      nextCompKey === '/partner'
    )

    // skip children
    const jointWithoutChildren = (
      needs === 'calculator' &&
      policy === 'joint' &&
      compKey === '/partner' &&
      hasChildren === null &&
      nextCompKey === '/children'
    )

    // for default journey
    const needsPopularDefault = (
      needs === 'popular' &&
      nextCompKey === '/your-needs'
    )

    // for default journey
    const needsSingle = (
      needs === 'popular' &&
      policy === 'single' &&
      nextCompKey === '/partner'
    )

    if (
      (!this._allowJoint(store) && this._isNextPathInRange() && isEmptyArray(this.panicPaths))
    ) {
      this._isSkippable()
    }

    // If reached final page or if panic routes has been called and finished
    if (!this._isNextPathInRange() || (isEmptyArray(this.panicPaths) && this.panicIsDirty)) this._finalCheck()
    else if (!isEmptyArray(this.panicPaths)) {
      // If panicpaths has items then run through them
      this._updatePath(this.panicPaths[0])
      this.panicPaths.shift()
    } else {
      // Else run through normal route array
      const catchAll = () => {
        this._updatePath(this.routes[next])
        this.path.completeUrl = this.routes[next]
        this.path.current = next
        this.path.next = next + 1
        this.path.compKey = this.routes[next]
        this.path.nextCompKey = this.routes[next + 1]
      }

      const skipOver = (amount) => {
        this._updatePath(this.routes[next + amount])
        this.path.completeUrl = this.routes[next + amount]
        this.path.current = next
        this.path.next = next + amount
        this.path.compKey = this.routes[next]
        this.path.nextCompKey = this.routes[next + amount]
      }

      if (singleNoChildren) skipOver(2)
      else if (singleWithChildren || jointWithoutChildren || isExistingMortgage || isCCISplitTestNoCI || needsPopularDefault || needsSingle) skipOver(1)
      else catchAll()

    }
  }

  /**
   * Preload the next component
   */
  getNext() {
    if (this._isNextPathInRange() && isEmptyArray(this.panicPaths)) {
      this._isSkippable()
      return this.components[this.path.nextCompKey].component
    }
    return null
  }
}
/* eslint-enable no-underscore-dangle */
/* eslint-enable prefer-destructuring */

export default new Pager()
