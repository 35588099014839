import { isEmptyObj } from 'common/utils'
import { isUndefined, isNull } from 'lodash'
import {
  REMOVE_FORM,
  REMOVE_VALUE,
  RESET_INVIDUAL_FORM,
} from '../actions/form'
import { store } from '../store'

const allowedActions = [REMOVE_FORM]

const formMiddleware = ({ dispatch }) =>
  next => action => {
    const { type, key, value } = action

    if (!type || !allowedActions.includes(type)) {
      return next(action)
    }

    const retainedData = store.getState()
    if (type === REMOVE_FORM) {

      /**
       * Fire an action to remove 2nd policy holder data (if they decide to start again)
       * Only if they select single cover
       */
      if (key === 'name' && value.value === 'single') {
        /**
         * Get the data from the store to check if the values have been populated
         * Iterate of the obj, filter out any properties that have the property key of {value2}
         */

        Object.keys(retainedData).map((i => {
          const obj = retainedData[i]

          Object.keys(obj).map(o => {
            const storeKey = o
            if (!isUndefined(obj[o]) && !isNull(obj[o])) {
              if (typeof obj[o] === 'object' && !isEmptyObj(obj[o])) {
                const findKey = Object.prototype.hasOwnProperty.call(obj[o], 'value2')

                /**
                 * Dispatch the data that is only applicable to single cover
                 */
                if (findKey) {
                  dispatch({
                    type: REMOVE_VALUE,
                    storeKey,
                    data: obj[o],
                  })
                }
              }
            }
            return true
          })
          return true
        }))
      }

      /**
       * Remove price data & any error
       */
      Object.keys(retainedData).map((i => {
        const storeData = retainedData[i]
        if (i === 'form') {
          Object.keys(storeData).map(o => {
            if (o === 'data' || o === 'error') {
              dispatch({
                type: RESET_INVIDUAL_FORM,
              })
            }
            return true
          })
        }
        return true
      }))
    }

    return null
  }

export default formMiddleware
