/**
 * Is value undefined or null
 * @param {any} val - variable to check
 * @return {boolean} if variable is undefined or null
 */
const isUndefinedOrNull = (val) =>
  (val === undefined || val === null)

/**
 * Is string empty
 * @param {any} str - string to check
 * @return {boolean} if string is empty
 */
const isEmptyString = (str) =>
  typeof (str) === 'string' && (str === '' || str.length === 0)

/**
 * Is Object
 * @param {any} val - val to check
 * @return {boolean} if val is object
 */
const isObject = (val) =>
  val.constructor === Object

/**
 * Is Empty Object
 * @param {object} obj - obj to check length
 * @return {boolean} if obj is empty
 */
const isEmptyObj = (obj) =>
  obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object)


/**
 * Is Array
 * @param {any} val - val to check
 * @return {boolean} if val is Array
 */
const isArray = (val) =>
  val && val.constructor === Array

/**
 * Is Empty Array
 * @param {array} arr - arr to check length
 * @return {boolean} if arr is empty
 */
const isEmptyArray = (arr) =>
  isUndefinedOrNull(arr) || (Array.isArray(arr) && arr.length === 0)

/**
 * Check if any item is partially in a string e.g. is ['abc','def','ghi'] is in abcxyz
 * @param {string} str - string to check
 * @param {array} arr - list of items to check
 * @returns {boolean} if string includes any items of array
 */
const isStrPartlyInAnyItemFromArr = (str, arr) =>
  arr.map(i => str.includes(i)).some(Boolean)

/**
 * Check is value is Falsy
 * @param {any} - value you want to check
 * @returns {boolean} if value is falsy
 */
const isFalsy = (val) =>
  isUndefinedOrNull(val) || isEmptyString(val) || isEmptyArray(val) ||
  isEmptyObj(val) || Number.isNaN(val) || val === false || val === 0


/**
 * Checks if value is number, NOTE does not check is parseable
 * @param {any} value you want to check
 * @returns {boolean} if value is number
 */
const isNumber = val =>
  val && Number.isInteger(val)

/**
 * Formats a string/number to currency value (add commas)
 * @param {string|number} str - value you want to format
 * @return {string} Formatted string
*/
const formatCurrency = (str) => {
  const x = (parseFloat(str)).toString().split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? `.${x[1]}` : ''
  while (/(\d+)(\d{3})/.test(x1)) {
    x1 = x1.replace(/(\d+)(\d{3})/, '$1,$2')
  }
  return x1 + x2
}

/**
 * Format name object
 * @param {obj} obj - Name object from API
 * @return {string} Formatted name "firstName surname"
 */
const formatName = (obj) =>
  isEmptyObj(obj) ? '' : `${obj.firstName} ${obj.surname}`

/**
 * Takes Address object and formats to string
 * @param {object} addressObj - Valid address object from API
 * @param {string} endOfLineChar - What string to add at the end of the line
 * @return {string} flat string of address object with commas "address 1, address 2"
 */
const formatAddress = (addressObj, endOfLineChar = ', ') =>
  isEmptyObj(addressObj) ? '' : Object.keys(addressObj).map(i => addressObj[i]).filter(Boolean).join(endOfLineChar)


/**
+ * Gets year out of date
+ * @param {date} d - a valid date string
+ * @return {string} string containing only the year of the date
+ */
const getYear = (d) =>
  (new Date(d)).getFullYear()


/**
 * Shallow compare objects
 * @param {object} o1 - Object 1 to check
 * @param {object} o2 - Object 2 to check
 * @return {boolean} If objects are the same
 */
const shallowObjectCompare = (o1, o2) => {
  if (isUndefinedOrNull(o1) || isUndefinedOrNull(o2)) {
    return o1 === o2
  }
  const o1Keys = Object.keys(o1)
  const o2Keys = Object.keys(o2)

  if (o1Keys.length !== o2Keys.length) {
    return false
  }

  const compo1 = o1Keys.map(key => o1[key] === o2[key]).every(key => key === true)
  const compo2 = o2Keys.map(key => o2[key] === o1[key]).every(key => key === true)

  if (!compo1 || !compo2) {
    return false
  }

  return true
}

/**
 * Take object and searches for key
 * @param {object} obj - object to look through
 * @param {string} key - key to look for
 * @return {boolean} If object has key
 */
const hasPropKey = (obj, key) =>
  Object.keys(obj).includes(key)

/**
 * Find all keys in a string and replace with new value
 *
 * @param {string} str - String you want to change
 * @param {array|string} key - Key you want find and replace e.g. {amount}
 * @param {array|string} value - Value to replace key with
 * @return {string} New string with replaced key
 */
const replaceKeyInString = (str, key, value) => {
  if (isArray(key)) {
    let newString = str
    key.forEach((v, i) => {
      newString = newString.replace(new RegExp(v, 'g'), value[i])
    })
    return newString
  }

  return str.replace(new RegExp(key, 'g'), value)
}

/**
 * Remove all keys with false values in an object
 *
 * @param {array} arr - Array you want to change
 */
const removeEmptyOrFalseValues = (arr) => {
  arr.forEach((v, i) => {
    if (isEmptyObj(arr[i])) {
      arr.splice(i, 1)
    }
  })

  return arr
}
/**
 * Remove all empty objects from Array
 *
 * @param {array} arr - Array you want to change
 */
const removeEmptyObject = (arr) => arr.filter(k => Object.keys(k).length !== 0)

/**
 * Format a string into a date string
 * @param {str} str - Valid date string
 * @return {date} New date in format (yyyy-mm-dd)
 */
const stringToDate = (str) => {
  const newDate = str.split('-')
  const formatDate = new Date(newDate[2], newDate[1] - 1, newDate[0])
  return formatDate
}

/**
 * Convert date to yyyy-mm-dd format
 * @param {Date} date - Valid date string
 * @return {date} New date in format (yyyy-mm-dd)
 */
const toRequestDate = (date) => {
  const formattedDate = typeof date === 'string' ? stringToDate(date) : date
  const formatDate = new Date(formattedDate)
  const day = (`0${formatDate.getDate()}`).slice(-2)
  const month = (`0${formatDate.getMonth() + 1}`).slice(-2)
  const year = formatDate.getFullYear()

  return `${year}-${month}-${day}`
}
/**
 * Replace dashes with slashes
 * @param {Date} date - Valid date string
 * @return {date} New date in format (yyyy-mm-dd)
 */
const dashesToSlashes = date =>
  date
    .split('-')
    .join('/')

/**
 * Clears local storage
 */
const clearStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

/**
 * Find value of nested object
 * @param {object} obj - object to search
 * @param {array} arr - keys to search for
 * @return {any} found value
 */
const findNestedObj = (obj, arr) =>
  arr.length < 1 ? obj : findNestedObj(obj[arr[0]], arr.slice(1))

/**
 * Removes whitespace from string
 * @param {string} str - string
 * @returns {string} string without
 */
const removeWhitespace = (str) =>
  str ? str.toString().replace(/\s/g, '') : str

/**
 * Removes whitespace and special characters from string
 * @param {string} str - string
 * @returns {string} string without whitespace nor special characters
 */
const removeWhitespaceAndSpecial = (str) =>
  str ? str.toString().replace(/\s|[!@#$%^&*(),.?":{}|<>]/g, '') : str

/**
 * Mergers props.style with inline styles, inline will be overwritten with props
 * @param {object} c - inline style
 * @param {object} c - props
 * @returns {object} style object
 */
const mergeStyles = (c, n) => Object.assign({}, c, hasPropKey(n, 'style') ? n.style : {})


/**
 *  Event hanlder to only allow numbers to be entered into input type
 *  @param {object} event
 *  @param {string,array}
 */
const onlyAllowNumber = (e) => {
  const { value } = e.target
  const hasInvalidChars = /\d|\W/
  // Allow A-z dash, single quote
  const onlyAllowedChars = /[0-9]+/
  // left and right apostrophe because IOS
  const isApostrphes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    e.target.value = value
      .split('')
      // replaces apples apostrophes with normal ones for ios
      .map(c => isApostrphes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedChars.test(c))
      .join('')
  }
}

/**
 * Event handler that prevents number/special char or emojis from being entered into input
 * @param {object} event
 */
const onlyAllowChar = e => {
  const { value } = e.target
  const hasInvalidChars = /\d|\W/
  // Allow A-z dash, single quote
  const onlyAllowedChars = /[a-zA-Z-']+/
  // left and right apostrophe because IOS
  const isApostrphes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    e.target.value = value
      .split('')
      // replaces apples apostrophes with normal ones for ios
      .map(c => isApostrphes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedChars.test(c))
      .join('')
  }
}

/**
 * Event handler that prevents number/special char or emojis from being entered into input
 * @param {object} event
 */
const onlyAllowCharAndSpaces = e => {
  const { value } = e.target
  const hasInvalidChars = /\d|\W/
  // Allow A-z dash, single quote
  const onlyAllowedChars = /[a-zA-Z-' ]+/
  // left and right apostrophe because IOS
  const isApostrphes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    e.target.value = value
      .split('')
      // replaces apples apostrophes with normal ones for ios
      .map(c => isApostrphes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedChars.test(c))
      .join('')
  }
}

/**
 * Event handler that prevents special char or emojis from being entered into input
 * @param {object} event
 */
const onlyAllowCharAndNumber = e => {
  const { value } = e.target
  const onlyAllowedCharcters = /[A-Za-z-/\\ &0-9]+/
  const hasInvalidChars = /\d|\W/
  const isApostrphes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    e.target.value = value
      .split('')
      .map(c => isApostrphes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedCharcters.test(c))
      .join('')
  }
}

/**
 * Removes invalid characters from string
 * @param {object} event target event
 * @returns {string} returns validated string
 */
const nameSantizer = e => {
  const { value } = e.target
  const allowedCharacters = /[a-zA-Z-]+(?: [a-zA-Z-]?)*/g
  const spaceChecker = value.split(' ')

  if (spaceChecker[0] !== ' ') {
    e.target.value = ''
    if (!isUndefinedOrNull(value.match(allowedCharacters))) {
      e.target.value = value.match(allowedCharacters).join('')
    }
  }
}

/**
 * Event handler that prevents special char or emojis from being entered into input
 * @param {object} event
 */
const emailCharsOnly = e => {
  const { value } = e.target
  const onlyAllowedCharcters = /[A-Za-z-@._0-9]+/
  const hasInvalidChars = /\d|\W/
  const isApostrphes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    e.target.value = value
      .split('')
      .map(c => isApostrphes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedCharcters.test(c))
      .join('')
  }
}

/**
 * Take muliple function and compose a single function to execute. See reducer pattern
 * composed from right to left
 * @param  {...Function} funcs - the functions to compose
 * @returns {Function} asd
 */
const compose = (...funcs) => {
  if (funcs.length === 0) {
    return args => args
  }

  if (funcs.lenght === 1) {
    return funcs[0]
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)))
}
/**
 *  Returns the age based on DOB entered
 *  @param {string} str - string
 */
const validateAge = (day, month, year) => {
  // first check if dob is in a valid format
  const regexCir = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/
  const dob = `${day}/${month}/${year}`
  const leapYear = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  let msg = ''

  if (regexCir.test(dob) && leapYear.test(dob)) {
    const today = new Date()
    const birthDate = new Date(`${year}/${month}/${day}`)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1
    }

    return age
  }
  if (day === 0) {
    msg = 'day invalid'
  } else if (month === 0) {
    msg = 'month invalid'
  } else if (!leapYear.test(dob) && regexCir.test(dob)) {
    msg = 'leap year'
  } else {
    return false
  }

  return msg
}
/**
 *  Validate Date
 *  @param {string} str - string
 */
const validateDate = (day, month, year) => {
  const date = new Date(`${year}-${month}-${day}`)
  const isValidDate = date.getDate() === parseInt(day, 0)
  return isValidDate
}

/**
 *  Validate Email
 *  @param {string} str - string
 */
const validateEmail = (str) =>
  /[_a-z0-9.-]*[a-z0-9]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})/ig.test(str)


/**
 *  Validate Phone
 *  @param {string} str - string
 */
const validatePhone = (str) =>
  /^(020[7,8]{1}[1-9]{1}[0-9]{2}[0-9]{4})|(0[1-8]{1}[0-9]{3}[0-9]{6})\s*$/i.test(str)

/**
 *  Gets the value of a cookie
 *  @param {string} cname - string
 */
const getCookie = (cname) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i = 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
    return null
  }
  return ''
}

/**
 * Count the number of occurrences of a character in a string
 * @param {string} str, the string to check
 * @param {string} char, the character to look for
 * @returns {number} the number of times the char is found in the string
 */
const noOfOccurrences = (str, char) =>
  str && str.split(char).length

/**
 *  Gets the value how far the user has scrolled
 *  @returns {number} the number of how far the user has scrolled
 */
const getScrollTop = () =>
  document.body.scrollTop || window.pageYOffset || document.body.parentElement.scrollTop
// const updateAnalytics = (data) => data && window.dataLayer.push(data)

/**
 * Make a dataLayer Page event push
 * @param {obj} data, obj which should contain url and title for the dataLayer push
 * @returns {func} a dataLayer push with a event pageName with PageUrl and PageTitle variables
 */
const updatePageAnalytics = (data) =>
(window.dataLayer.push({
  event: 'pageName',
  PageUrl: `/virtual/qqw/${data.url.trim()}`,
  PageTitle: `Widget ${data.title.trim()} page`,
}))

/**
 * Make a dataLayer Page and Protect State event push
 * @param {obj} data, obj which should contain url and title for the dataLayer push
 * @returns {func} a dataLayer push with a event pageName with PageUrl and PageTitle variables
 */
const updateProtectOptionsAnalytics = (data) =>
(window.dataLayer.push({
  event: 'GuidanceProtectOptions',
  options: data,
}))

/**
 * Make a dataLayer Page event push
 * @param {obj} data, obj which should contain url and title for the dataLayer push
 * @returns {func} a dataLayer push with a event pageName with PageUrl and PageTitle variables
 */
const updateInfoTabAnalytics = (data) =>
(window.dataLayer.push({
  event: 'infoTab',
  ...data,
}))

const updateErrorAnalytics = (data) =>
(window.dataLayer.push({
  event: 'pageError',
  ...data,
}))


/**
 *  Scroll To Bottom of page
 */

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  })
}

/**
 *  Scroll To Top of page
 */

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const formatDateToYearFirst = date =>
  new Date(date).toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('-')


const reverseDate = date =>
  date.split('-')
    .reverse()
    .join('-')

/**
 * Convert date to english format
 * @param {Date} date - Valid date string
 * @return {date} New date in english date format (dd/mm/yyyy)
 */
const toLocalDate = (date) =>
  new Date(formatDateToYearFirst(date))

/**
 *  Determines whether or not a value is in range
 *  @param {number} min, minimum value
 *  @param {number} max, maximum value
 *  @param {number} val, value you're checking against
 *  @returns {bool} returns whether true or false
 */
const inRange = (min, max, val) =>
  Boolean(val >= min && val <= max)
/**
 * Moves components to top of page
 */
const moveToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

/**
 * Check if two objects or arrays match
 * @param {object|array} value, the first value
 * @param {object|array} other, the second value
 * @returns {bool} whether of not the values match
 */
const areTheyEqual = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // If nothing failed, return true
  return true
}

/**
 *  Checks whether there is a joint policy and returns a store value
 *  This way we can return the correct retained data needed
 *  @param {bool} jointPolicy, whether the there is a joint policy or not
 *  @param {string} path, pathname
 *  @returns {string} returns value or value2 for the correct key
 */
const retainedDataKey = (jointPolicy, path) =>
  jointPolicy && path.includes('joint') ? 'value2' : 'value'

/**
 *  Checks whether there is a joint policy and returns a store value
 *  This way we can return the correct retained data needed
 *  @param {string} path, query string name
 *  @returns {string} returns query string value
 */
const getQueryString = (str) => {
  const queryString = new URLSearchParams(window.location.search)
  return queryString.get(str)
}

/**
 *  Map data from 2 objects
 *  @param {obj} defaults, default obj we need to map data to
 *  @param {obj} storeData, the data from the store
 *  @param {string} key, key we map data to
 *  @returns {string} returns value or value2 for the correct key
 */
const mapMyData = (defaults, storeData, key) =>
  Object.assign(defaults, { [key]: storeData })

/**
 * Take string of words and coverts to title case
 * @param {string} str, the string to change
 * @returns {string} String in title case
 */
const toTitleCase = str =>
  str && str.replace(/\w\S*/g, (c) => c.charAt(0).toUpperCase() + c.substr(1).toLowerCase())

/**
 * Create default critical illness amount
 * @param {number} amount, cover amount allowed
 * @param {object} rules, the rules from the business rules service
 * @returns {number} return value (default CI)
 */
const calculateCI = (amount, rules) => {
  let CIpercent
  if (amount > rules[0]?.sumAssuredLowerBound && amount <= rules[1]?.sumAssuredLowerBound) {
    CIpercent = rules[0].defaultPercentage
  } else if (amount >= rules[1]?.sumAssuredLowerBound && amount <= rules[2]?.sumAssuredLowerBound) {
    CIpercent = rules[1].defaultPercentage
  } else if (amount >= rules[2]?.sumAssuredLowerBound) {
    CIpercent = rules[2].defaultPercentage
  }

  const recommendCIAmount = Math.round(((amount / 100) * CIpercent))
  const defaultCIAmount = recommendCIAmount >= 5000 ? recommendCIAmount : 5000
  return defaultCIAmount
}

/**
 * Create default critical illness amount
 * @param {number} ciAmount, CI Amount requested
 * @param {number} coverAmount, Cover Amount requested
 * @returns {boolean} return is valid CI
 */
const validCI = (ciAmount, amount) => {
  const isValid = ciAmount <= amount && ciAmount >= 5000
  if (ciAmount === undefined || amount === undefined) {
    return true
  }
  return isValid
}

/**
 * Format date
 * @param {string} date, convert date to YYYY-MM-DD
 * @returns {string} return date
 */
const formatDate = date => {
  const d = new Date(date)
  let month = (d.getMonth() + 1)
  let day = d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = `0 ${month}`
  if (day.length < 2) day = `0 ${day}`

  return [year, month, day].join('-')
}

const getNextDate = date => {
  const today = new Date()
  return today.getTime() > date.getTime() ? new Date(date.setDate(date.getFullYear() + 1)) : date
}

/**
 * Get day before
 * @param {string} date, date
 * @returns {string} return date
 */
const getDayBefore = date =>
  new Date(date.setDate(date.getDate() - 1))


/**
 * Quote Valid Date
 * @param {string} dobs, dobs of users
 * @returns {string} return date
 */
const quoteValidDate = dobs => {
  const date = new Date()
  const year = date.getFullYear()
  const dateIn30Days = new Date(date.setDate(date.getDate() + 30))
  const dob1 = dobs[0].split('-')
  const dob2 = dobs[1] ? dobs[1].split('-') : null

  const dob1NextBday = getDayBefore(getNextDate(new Date(`${dob1[1]}-${dob1[0]}-${year}`)))
  const dob2NextBday = !isUndefinedOrNull(dob2) ? getDayBefore(getNextDate(new Date(`${dob2[1]}-${dob2[0]}-${year}`))) : null


  const dates = [dob1NextBday.getTime(), dateIn30Days.getTime(), !isUndefinedOrNull(dob2NextBday) && dob2NextBday.getTime()]
  const datesFiltered = dates.filter(Boolean)

  const expiryDate = new Date(Math.min(...datesFiltered))

  return [`0${expiryDate.getDate()}`.slice(-2), `0${(expiryDate.getMonth() + 1)}`.slice(-2), expiryDate.getFullYear()].join('/')
}
/**
 * Add decimal places to value
 * @param {number} val
 * @param {number} places
 * @returns {string}
 */

const decimalPlace = (val, places) =>
  (val !== undefined ? parseFloat(val).toFixed(places) : '0.00')

/**
 * Insert span around price
 * @param {number} val
 * @returns {string}
 */
const splitAtDecimal = (price) =>
  price.split('.')

/**
 * Read cookie value
 * @param {string} string, this is a key of the cookie
 * @returns {string/null} string, this is a value in lowercase  of the cookie
 */

const getCookieValue = (key) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`)
  return b ? b.pop().toLowerCase() : null
}

/**
 * Decide what component to return based on the cookie
 * @param {string} string, this is a key/value cookie
 * @returns {string}
 */
const chooseComponent = (str) => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  const splitTestKey = `SplitTest__QQ__${str}`
  let component

  Object.keys(ca).map(i => {
    if (ca[i].length > 1) {
      const split = ca[i].split('=')
      const key = split[0].trimLeft().trim()
      const value = split[1].trimLeft().trim()

      if (key === splitTestKey && getCookieValue(splitTestKey) === 'b') {
        component = `${str}_${value}`
        return false
      }
    }
    return true
  })
  return component || str
}
/**
 * Truncates a strong and adds an elipsis
 * @param {string} string to truncate
 * @param {number} length to truncate
 * @returns {sting} return string truncated with elipsis
 */
const truncateStr = (input, length) => !isUndefinedOrNull(input) && input.length > length ? `${input.substring(0, length)}...` : input


/**
 * Returns true or false if str matches the regex
 * @param {string} string to test against regex
 * @returns {bool} return bool if matches regex
 * */
const validPostCode = (str) =>
  /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/ig.test(str)


/**
 * Returns an array split with a space
 * @param {array} array the array
 * @returns {string} return string of split array with spaces
 * */
const joinArrayWithSpace = (array) => array.join(' ')

/*
 * Create unique id based on component and location
 * @param {string} string, this is a key/value cookie
 * @returns {string}
 */
const uniqueIdentifer = (id = '') => {
  const { location } = window
  return `${location.pathname.replace('/', '')}_${id}`
}

/*
 * Create new address array
 * @param {array} address being passed through
 * @returns {array}
 */
const createNewAddressArray = (arr) => (
  arr.map(obj => {
    const newObj = Object
      .keys(obj)
      .filter(k => obj[k] !== null && obj[k] !== undefined && obj[k] !== '')
    return ({
      addressno: obj.Line1,
      addresspostcode: obj.PostCode,
      value: newObj.reduce((r, key) => (r.concat(obj[key])), []).join(', '),
    })
  })
)

/*
 * Remove empty key values from object
 * @param {object} removes empty / undefined key values
 * @returns {object}
 */
const removeEmpty = (obj) => {
  Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k])
  return obj
}

/*
 * Sanitize and encode all HTML in a user-submitted string
 * @param  {String} str  The user-submitted string
 * @return {String} str  The sanitized string
 */
const sanitizeString = (str) => {
  const temp = document.createElement('div')
  temp.textContent = str
  return temp.textContent
}

const numberWithCommas = (x) => !isUndefinedOrNull(x) && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')


/**
 * Remove undefined and null and empty values from object
 * @param {obj} pass in an obj
 * @returns {obj} return object reduced - removal of undefined and null and empty values
 */
const removeUnderfinedNullFromObj = (obj) => (Object
  .keys(obj)
  .filter(k => obj[k] !== null && obj[k] !== undefined && obj[k] !== '')
  .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {}))

/**
 * Returns true or false if number is even
 * @param {number | string} pass in a number or string
 * @returns {bool} return boolean whether param is even
 */
const isEven = (n) => parseFloat(n) % 2 === 0

/**
+ * Gets day out of date
+ * @param {date} d - a valid date string
+ * @return {string} string containing only the day of the date
+ */
const getDay = (d) => (new Date(toRequestDate(d)).getDate())

/**
+ * Return child critical illness amount
+ * @param {Number} number - amount = number
+ * @param {Number} number - percentage = number
+ * @param {Number} number - maximum = number
+ * @return {Number} Number containing sum of CCI
+ */
const childCIValue = (amt, percentage, maximum) => !isUndefinedOrNull(maximum) && ((amt * (percentage / 100) < maximum) ? Math.round((amt * (percentage / 100))) : maximum)

/**
+ * Checks if a given field lenght is valid
+ * @param {String} fieldValue - a value of the field
+ * @param {Number} maxFieldLength - maximum field lenght
+ * @return {Boolean} returns true/false depending on the outcome
+ */
const fieldLengthValidator = (fieldValue, maxFieldLength) => (fieldValue.length <= maxFieldLength)

/**
+ * If string can be converted to number, preceed, if not return empty string.
+ * @param {String} fieldValue - a value of the field
+ * @return {String} returns string of number with commas
+ */
const formatNumberInput = (val) => {
  if (parseInt(val, 10)) return parseInt(val.replace(/,/g, ''), 10).toLocaleString()
  return ''
}

/**
 * Caclulate a percentage of a given amount
 * @param {Number} total - total amount
 * @param {Number} DivideBy - number we want to find a percentage of
 * @return {Number} returns a number representing a percentage of a value
*/
const calculatePercentage = (total, divideBy) => {
  const percentageValue = divideBy / total * 100
  return !Number.isNaN(percentageValue) ? percentageValue : 0
}

/**
 * Push to datalayer when summary accordion open/closes
 * @param {Boolean} checkedInput - boolean
 * @param {Event} event - click event
 * @return {Null} return datalayer event or null
 *
*/
const SummaryDLAccordion = (checkedProp, e, newPage = false, mobile) => {
  const { currentTarget: { parentElement: { id, parentElement: { id: nestedId } } } } = e

  let elId

  if (!newPage) {
    elId = id
  } else {
    elId = id
  }

  if (mobile) {
    elId = nestedId
  }

  const checked = !checkedProp // reverse boolean as passed down prop doesn't re-render with updated state
  if (elId) {
    const n = checked ? 'iqDropdownOpen' : 'iqDropdownClosed'
    return window.dataLayer.push({
      event: n,
      [n]: elId,
    })
  }
  return null
}

/**
 * Push to datalayer when summary accordion buttons clicked
 * @param {Event} event - click event
 * @return {Null} datalayer event or null
 *
*/
const SummaryDLTab = (e) => {
  // eslint-disable-next-line
  const dataID = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.dataset.id // horrible but currently only way to retrive section product.
  const { target: { innerText } } = e
  if (dataID && innerText) {
    window.dataLayer.push({
      event: 'iqButton',
      'iqButton': `${dataID} - ${innerText}`,
    })
  }
  return null
}

/**
 * Push to datalayer when summary help icon
 * @param {Event} event - click event
 * @return {Null} datalayer event or null
 *
*/
const SummaryDLHelpIcon = (e) => {
  const dataID = e.currentTarget.parentElement.parentElement.parentElement.dataset.id
  window.dataLayer.push({
    event: 'iqHelp',
    'iqHelp': dataID,
  })
}

/**
 * Push to datalayer when summary help icon
 * @param {event} string - name of event
 * @param {name} string - name of event
 * @param {value} string - value of event
 *
*/
const DataLayerEventNameValue = (event, name, value) => {
  if (event && name && value) {
    window.dataLayer.push({
      event,
      [name]: value,
    })
  }
  return null
}

/**
 * Return a true or false value based on the given values
 * @param {Any} valueToCheck - any value or expression
 * @param {Any} trueValue - a value to return if it is evaluated to true
 * @param {Any} falseValue - a value to return if it is evaluated to false
 * @return {Any} returns a true or valse value that was provided to the function
 *
*/
const getTrueOrFalseValue = (valueToCheck, trueValue, falseValue) => valueToCheck ? trueValue : falseValue

/**
 * Business rules for the summary page
 * @param {String} operation - which calculation to run
 * @param {Amount} amount - value to use for the calculation
 * @return {Number} returns a number respresinting a total value
*/
const summaryPageCalculations = (operation, amount) => {
  switch (operation) {
    case 'lifeInsuranceBillsPerMonth':
      return amount / 60
    case 'lifeInsuranceNumberOfChildren':
      return amount / 10000
    case 'criticalIllnessMortgageTotal':
      return amount * 24
    case 'criticalIllnessBillsTotal':
      return amount * 24
    default:
      return 0
  }
}

/**
 * Returns a plural or singular of the word
 * @param {Number} value - value for determining if singular or plural needs to be returned
 * @param {String} singular - a string for singular version of the word
 * @param {String} plural - a string for plural version of the word
 * @return {String} returns a string of plural or singular version of the word
*/
const getSingularOrPlural = (value, singular, plural) => Number(value) === 1 ? singular : plural

/**
 * Checks if the value provided is a valid Integer
 * @param {(String|Number)} value - a value to validate
 * @return {(String|Number|Boolean)} returns a string or number (depending on value type) if it is a valid integer, otherwise returns false
*/
const validNumber = (value) => {
  const regex = new RegExp(/^[0-9]*$/)
  if (!regex.test(value)) return false
  return value
}

const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  const to = "aaaaeeeeiiiioooouuuunc------"
  // eslint-disable-next-line no-plusplus
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const getOpeningHours = (inputDate, openingHours, bankHolidays) => {
  const { dateOne, dateTwo } = inputDate

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const tomorrowDatePreIntl = dateTwo.setDate(dateTwo.getDate() + 1)
  // generate date and time based off timezone (so azure agent updates as it sits -1 hour).
  const tomorrow = new Intl.DateTimeFormat('en-GB', { weekday: 'long', year: 'numeric', day: 'numeric', month: 'numeric' }).format(tomorrowDatePreIntl)
  const date = new Intl.DateTimeFormat('en-GB', { weekday: 'long', year: 'numeric', day: 'numeric', month: 'numeric' }).format(dateOne)
  const time = new Intl.DateTimeFormat('en-GB', { timeZone: 'Europe/London', timeStyle: 'long' }).format(dateOne).slice(0, -3)

  // return day, hour and date in correct format.
  const day = date.split(',')[0]
  const hour = Number(time.slice(0, 2))
  // hour = hour.substring(0, 0) === '0' ? hour.slice(0, 0) : hour

  const formattedTodayDate = date.split(',')[1]
    .replace(new RegExp('/', 'g'), '-') // replace / with -
    .replace(' ', '') // trim spaces
    .split('-')
    .reverse()
    .join('-')

  const formattedTomorrowDate = tomorrow.split(',')[1]
    .replace(new RegExp('/', 'g'), '-') // replace / with -
    .replace(' ', '') // trim spaces
    .split('-')
    .reverse()
    .join('-')

  const dayIndex = days.indexOf(day)
  const tomorrowIndex = dayIndex === 6 ? 0 : dayIndex + 1

  if (bankHolidays.includes(formattedTodayDate)) {
    if (hour > openingHours.bankholiday.close) {
      return `Open tomorrow at ${tomorrowIndex === 6 ? openingHours.saturday.human.open : openingHours.weekday.human.open}`
    }
    if (hour >= openingHours.bankholiday.open) return (`Open until ${openingHours.bankholiday.human.close} tonight`)
    return `Open at ${openingHours.bankholiday.human.open}`
  }

  if (dayIndex === 0) {
    if (bankHolidays.includes(formattedTomorrowDate)) {
      return `Open tomorrow at ${openingHours.bankholiday.human.open}`
    }
    return `Open tomorrow at ${openingHours.weekday.human.open}`
  }

  if (dayIndex > 0 && dayIndex < 6) {
    if (hour > openingHours.weekday.close) {
      return `Open tomorrow at ${tomorrowIndex === 6 ? openingHours.saturday.human.open : openingHours.weekday.human.open}`
    }

    if (hour >= openingHours.weekday.open) {
      return (`Open until ${openingHours.weekday.human.close} tonight`)
    }
    return `Open at ${openingHours.weekday.human.open}`
  }

  if (dayIndex === 6) {
    if (hour > openingHours.saturday.close) return ("Closed tomorrow")
    if (hour > openingHours.saturday.open) return (`Open until ${openingHours.saturday.human.close} tonight`)
    return `Open at ${openingHours.saturday.human.open}`
  }

  return null
}

/**
 * Get brand name
 * @return {String} returns the brand name in lower case
*/
const brandNameInLowerCase = () => {
  let brandName

  const brandCookie = getCookieValue("testBrand")

  if (process.env.REACT_APP_TESTING_ENV !== "production" && brandCookie) {
    brandName = brandCookie.toLowerCase()
  } else {
    brandName = process.env.REACT_APP_BRAND && process.env.REACT_APP_BRAND.toLowerCase()
  }

  return brandName
}

/**
 * Get campaign code from cookie, query string or set default
 * @return {String} returns campaign code
*/
const getCampaignCode = () => {
  const brand = brandNameInLowerCase()
  let defaultCode = 'BS01'
  if (brand === 'budget') {
    defaultCode = 'BL05'
  } else if (brand === 'virginmoney') {
    defaultCode = 'VM01'
  }
  const queryCampaignCode = validateCampaignCode(getQueryString('src'), brand) 
  const campaignCodeCookie = validateCampaignCode(getCookieValue('campaignCode'), brand)
  const campaignCode = queryCampaignCode || (campaignCodeCookie && campaignCodeCookie.toString().toUpperCase()) || defaultCode

  return campaignCode
}


const validateCampaignCode = (campaignCode, brand) => {
  
  if(!campaignCode) {
    return null
  }

  if(campaignCode.toUpperCase().startsWith('Z')) {
    return campaignCode
  }

  if(brand === "budget") {
    return campaignCode.toUpperCase().startsWith('BL') ? campaignCode : null
  }

  if (brand === "virginmoney") {
    return campaignCode.toUpperCase().startsWith('VM') ? campaignCode : null
  }

  if (brand === "beaglestreet") {
    return campaignCode.toUpperCase().startsWith('BS') ? campaignCode : null
  }

  return null
}

/**
 * Invert bool if Beagle or Virgin for use in marketing preferences opt in/out
 * @return {Bool} 
*/
const invertBooleanBSVM = (value) => {
  const brandName = brandNameInLowerCase()

  if(brandName === "virginmoney" || brandName === "beaglestreet"){
    return !value
  }

  return value
}

export {
  isUndefinedOrNull,
  isEmptyString,
  isObject,
  isEmptyObj,
  isArray,
  isEmptyArray,
  isStrPartlyInAnyItemFromArr,
  isFalsy,
  isNumber,
  formatCurrency,
  formatName,
  formatAddress,
  getYear,
  shallowObjectCompare,
  hasPropKey,
  replaceKeyInString,
  removeEmptyOrFalseValues,
  toLocalDate,
  stringToDate,
  toRequestDate,
  clearStorage,
  findNestedObj,
  removeWhitespace,
  mergeStyles,
  onlyAllowNumber,
  onlyAllowChar,
  compose,
  validateAge,
  getCookie,
  noOfOccurrences,
  getScrollTop,
  formatDateToYearFirst,
  inRange,
  retainedDataKey,
  mapMyData,
  toTitleCase,
  calculateCI,
  formatDate,
  decimalPlace,
  quoteValidDate,
  chooseComponent,
  moveToTop,
  areTheyEqual,
  truncateStr,
  validPostCode,
  joinArrayWithSpace,
  uniqueIdentifer,
  dashesToSlashes,
  validCI,
  reverseDate,
  validateDate,
  validateEmail,
  splitAtDecimal,
  scrollToBottom,
  createNewAddressArray,
  removeEmpty,
  getCookieValue,
  sanitizeString,
  updatePageAnalytics,
  updateInfoTabAnalytics,
  updateErrorAnalytics,
  numberWithCommas,
  removeUnderfinedNullFromObj,
  getQueryString,
  onlyAllowCharAndNumber,
  scrollToTop,
  emailCharsOnly,
  validatePhone,
  isEven,
  getDay,
  removeWhitespaceAndSpecial,
  childCIValue,
  removeEmptyObject,
  nameSantizer,
  updateProtectOptionsAnalytics,
  fieldLengthValidator,
  formatNumberInput,
  calculatePercentage,
  SummaryDLAccordion,
  SummaryDLTab,
  SummaryDLHelpIcon,
  DataLayerEventNameValue,
  getTrueOrFalseValue,
  summaryPageCalculations,
  getSingularOrPlural,
  validNumber,
  slugify,
  onlyAllowCharAndSpaces,
  brandNameInLowerCase,
  getCampaignCode,
  invertBooleanBSVM
}
