import styled from 'styled-components'

const FullOverlay = styled.div`
  background: rgba(0,0,0,0.8);
  transition: opacity 300ms ease-in-out, background 100ms ease-in;
  display: flex;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  overflow: auto;
`

export default FullOverlay
