import React from 'react'
import FooterContainer from './style'

const FooterComp = () => {

  return (
    <FooterContainer>
      <div className="footer-text">
        <p>Virgin Money Life Insurance is promoted by CYB Intermediaries Limited registered in England and Wales 04056283, Jubilee House Gosforth Newcastle upon Tyne NE3 4PL authorised and regulated by the Financial Conduct Authority. Policies underwritten and administered by Family Assurance Friendly Society Limited no. 939F, 16-17 West Street Brighton BN1 2RL authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Policies arranged before 19 September 2023 are administered by BISL Limited 3231094, Fusion House Katharine Way Bretton Peterborough PE3 8BG authorised and regulated by the Financial Conduct Authority.</p>
      </div>
    </FooterContainer>
  )
}

export default FooterComp
