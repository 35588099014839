import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Overlay from 'components/Overlay'

import { TransitionGroup, Transition } from 'react-transition-group'
import { TrustpilotRating } from '@bgllife/ui.componentlibrary'
import { joinArrayWithSpace } from 'common/utils'
import { interstitialModalText as Copy, sessioncamClassList } from 'common/consts'

import {
  Column,
  Container,
  MessageContainer,
  Title,
  Messages,
  Progress,
  SubTitle,
  BottomBar,
  TextContainer,
} from './style'

class InterstitialModal extends Component {
  state = {
    currentIndex: 0,
    message: Copy.messages[0],
  }

  componentDidMount() {
    const animationInterval = setInterval(this.animation, 3000)
    this.setState({ animationInterval })
  }

  componentWillUnmount() {
    const { animationInterval } = this.state
    clearInterval(animationInterval)
  }

  animation = () => {
    const { currentIndex } = this.state
    const { messages } = Copy
    const { length } = messages
    let newIndex = 0

    if (currentIndex >= (length - 1)) {
      newIndex = 0
    } else {
      newIndex = currentIndex + 1
    }

    this.setState({
      message: messages[newIndex],
      currentIndex: newIndex,
    })
  }

  render() {
    const { message, currentIndex } = this.state
    const {
      isVisible,
      onSubmit,
      name,
      dataUxId,
      brandName,
      trustpilotData,
      trustpilotLogoUrl,
      text,
      alternativeStyle,
      trustpilotId,
    } = this.props

    return (
      <Overlay state={{ isVisible, toggle: () => { }, submit: onSubmit }}>
        {({ getModalProps, status }) => (
          <Column className={status} xs={12} lg={4} md={6} sm={10}>
            <Container id={`Modal_${dataUxId}`} {...getModalProps()}>
              <TextContainer>
                <Title>
                  <span className={joinArrayWithSpace(sessioncamClassList)}>{name}</span>
                  <span className="title">{Copy.title}</span>
                </Title>
                <MessageContainer>
                  <TransitionGroup component={null}>
                    <Transition key={currentIndex} timeout={{ enter: 500, exit: 500 }}>
                      {transitionStatus => (
                        <Messages status={transitionStatus} className="u-clearfix">
                          <div>
                            {message.title}
                            <span>{message.subtitle}</span>
                          </div>
                        </Messages>
                      )}
                    </Transition>
                  </TransitionGroup>
                </MessageContainer>
                <Progress />
                <SubTitle>{Copy.subtitle}</SubTitle>
              </TextContainer>
              <BottomBar>
                {brandName.toLowerCase() === 'beaglestreet' && <TrustpilotRating
                  data={{
                    numberOfStars: trustpilotData.rating.stars.toString(),
                    ratingText: trustpilotData.rating.string,
                    starsImageUrl: trustpilotData.images.starSvg.url,
                    trustpilotLogoUrl,
                  }}
                  reviewsUrl="https://uk.trustpilot.com/review/beaglestreet.com"
                  text={text}
                  alternativeStyle={alternativeStyle}
                  id={trustpilotId}
                />}
              </BottomBar>
            </Container>
          </Column>
        )}
      </Overlay>
    )
  }
}

InterstitialModal.propTypes = {
  dataUxId: PropTypes.string,
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  brandName: PropTypes.string,
  trustpilotData: PropTypes.object,
  trustpilotLogoUrl: PropTypes.string,
  text: PropTypes.string,
  alternativeStyle: PropTypes.bool,
  trustpilotId: PropTypes.string,
}

export default InterstitialModal
