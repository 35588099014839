import { css } from 'styled-components'

const sizes = [575.98, 767.98, 991.98, 1199.98, 1400]

const extraSmall = (styles) => css`
  @media (max-width: ${sizes[0]}px) {
    ${styles}
  }
`

const small = (styles) => css`
 @media (max-width: ${sizes[1]}px) {
    ${styles}
  }
`

const medium = (styles) => css`
  @media (max-width: ${sizes[2]}px) {
    ${styles}
  }
`

const large = (styles) => css`
  @media (max-width: ${sizes[3]}px) {
    ${styles}
  }
`

const extraLarge = (styles) => css`
  @media (max-width: ${sizes[4]}px) {
    ${styles}
  }
`

const tabletPortrait = (styles) => css`
  @media (min-width: 768px)
    and (max-width: 1024px)
    and (orientation: portrait) {
      ${styles}
  }
`

const tabletLandscape = (styles) => css`
  @media (min-width: 768px)
    and (max-width: 1024px)
    and (orientation: landscape) {
      ${styles}
}
`

const mobilePortrait = (styles) => css`
  @media only screen
    and (min-width: 375px)
    and (max-width: 812px)
    and (orientation: portrait) {
      ${styles}
  }
`


const mobileLandscape = (styles) => css`
  @media only screen
    and (min-width: 375px)
    and (max-width: 812px)
    and (orientation: landscape) {
      ${styles}
  }
`

const query = {
  extraSmall,
  small,
  medium,
  large,
  extraLarge,
  tabletPortrait,
  tabletLandscape,
  mobilePortrait,
  mobileLandscape,
  xs: extraSmall,
  sm: small,
  md: medium,
  lg: large,
  xl: extraLarge,
}

const combine = (size, style) => size.map(i => query[i](style))

export {
  sizes,
  combine,
  extraSmall,
  small,
  medium,
  large,
  extraLarge,
  tabletPortrait,
  tabletLandscape,
  mobilePortrait,
  mobileLandscape,
}
