import {
  GET_REVIEW_REQUEST,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_FAILURE,
} from '../actions/trustpilot'


const initialState = {
  isFetching: false,
  data: undefined,
}

const term = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_REVIEW_REQUEST:
      return Object.assign({}, state, { isFetching: true })
    case GET_REVIEW_SUCCESS:
      return Object.assign({}, state, { data: { ...action.payload }, isFetching: false })
    case GET_REVIEW_FAILURE:
      return Object.assign({}, state, { isFetching: false, error: action.payload })
    default:
      return state
  }
}

export default term
