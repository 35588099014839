import React, { Fragment, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ProgressBar from 'components/ProgressBar'
import {
  brandNameInLowerCase,
} from '../../common/utils'
import Containter from './style'

const Layout = ({
  startAgainFunc,
  showStartAgain,
  closeFunc,
  children,
  ...props
}) => {

  const [height, setHeight] = useState(0)
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => setScroll(window.scrollY)
  const brandName = brandNameInLowerCase()
  useEffect(() => {
    const element = 'header'
    const el = document.getElementsByTagName(element)[0]
    setHeight(el.offsetHeight)
    if (isMobile) {
      window.addEventListener('scroll', handleScroll)
      document.body.style.marginTop = scroll > height ? `${height}px` : 0
    }
  }, [scroll, height])

  return (
    <Fragment>
      <div className={scroll > height ? 'header-fixed' : 'header-static'}>
        <Header
          startAgainFunc={startAgainFunc}
          showStartAgain={showStartAgain}
          closeFunc={closeFunc}
        />
      </div>
      <ProgressBar />
      <Containter {...props}>
        {children}
      </Containter>
      { brandName === 'virginmoney' && <Footer /> }
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  startAgainFunc: PropTypes.func,
  showStartAgain: PropTypes.bool,
  closeFunc: PropTypes.func,
}

export default Layout
