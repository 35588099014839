import styled from 'styled-components'
import { container, query } from 'theme'

const FooterContainer = styled.footer`
  ${container}
  ${query.combine(['large', 'extraLarge'], `
    min-height: 450px;
    position: relative;
    z-index: -1;
  `)}
  ${query.combine(['extraSmall'], `
    min-height: 550px;
    position: relative;
    z-index: -1;
  `)}
  .footer-text p {
    text-align: center;
    font-size: 14px;
    padding: 0 10px;
    ${query.combine(['extraSmall', 'large', 'extraLarge'], `
      position: absolute;
      bottom: 70px;
    `)}
   }
}
`
export default FooterContainer

