import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startAgain } from 'actions/startClose'

const Reset = CompComponent => {
  class WrappedComponent extends Component {
    resetApplication = () => {
      const { reset } = this.props
      reset()
    }

    render() {
      return (
        <div>
          <CompComponent {...this.props} onClick={this.resetApplication} />
        </div>
      )
    }
  }

  WrappedComponent.propTypes = {
    reset: PropTypes.func,
  }

  return WrappedComponent
}

const mapDispatchToProps = dispatch => ({
  reset: (e) => dispatch(startAgain(e)),
})

const composedFieldWrapper = compose(
  connect(null, mapDispatchToProps),
  Reset,
)

export default composedFieldWrapper
