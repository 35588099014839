import { toRequestDate } from 'common/utils'
import { CALL_API } from '../middleware/callAPIMiddleware'
import API_MAP from '../services/endpoint'

const AGE_RULE_REQUEST = 'AGE_RULE_REQUEST'
const AGE_RULE_SUCCESS = 'AGE_RULE_SUCCESS'
const AGE_RULE_ERROR = 'AGE_RULE_ERROR'

const AMOUNT_RULE_REQUEST = 'AMOUNT_RULE_REQUEST'
const AMOUNT_RULE_SUCCESS = 'AMOUNT_RULE_SUCCESS'
const AMOUNT_RULE_ERROR = 'AMOUNT_RULE_ERROR'
const SET_AMOUNTS = 'SET_AMOUNTS'

const GET_TERM_REQUEST = 'GET_TERM_REQUEST'
const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS'
const GET_TERM_FAILURE = 'GET_TERM_FAILURE'

const DEFAULT_CI_RULE_REQUEST = 'DEFAULT_CI_RULE_REQUEST'
const DEFAULT_CI_RULE_SUCCESS = 'DEFAULT_CI_RULE_SUCCESS'
const DEFAULT_CI_RULE_ERROR = 'DEFAULT_CI_RULE_ERROR'
const ADD_CI_SUCCESS = 'ADD_CI_SUCCESS'

const {
  businessRules: {
    ageRule,
    amountRule,
    defaultCIRule,
  },
} = API_MAP

export const getAgeRule = (rules, uniqueNumber) => ({
  [CALL_API]: {
    url: `${ageRule}/${rules.quoteEffectiveDate}`,
    method: 'GET',
    types: [AGE_RULE_REQUEST, AGE_RULE_SUCCESS, AGE_RULE_ERROR],
    headers: {
      'x-correlation-id': uniqueNumber,
    },
    rules,
  },
  type: AGE_RULE_REQUEST,
  rules,
})

export const getAmountRule = (quoteEffectiveDate, life1DateOfBirth, life2DateOfBirth, uniqueNumber) => ({
  [CALL_API]: {
    url: `${amountRule}/${quoteEffectiveDate}/${life1DateOfBirth}/${life2DateOfBirth}`,
    method: 'GET',
    types: [AMOUNT_RULE_REQUEST, AMOUNT_RULE_SUCCESS, AMOUNT_RULE_ERROR],
    headers: {
      'x-correlation-id': uniqueNumber,
    },
    quoteEffectiveDate,
    life1DateOfBirth,
    life2DateOfBirth,
  },
  type: AMOUNT_RULE_REQUEST,
  quoteEffectiveDate,
  life1DateOfBirth,
  life2DateOfBirth,
})

export const getTerm = (person1, person2, uniqueNumber) => ({
  [CALL_API]: {
    url: `${API_MAP.businessRules.termRule}/${toRequestDate(new Date())}/${person1}${person2 ? `/${person2}` : ''}`,
    method: 'GET',
    types: [GET_TERM_REQUEST, GET_TERM_SUCCESS, GET_TERM_FAILURE],
    headers: {
      'x-correlation-id': uniqueNumber,
    },
  },
})

export const getDefaultCIRule = (date, amount, uniqueNumber) => ({
  [CALL_API]: {
    url: `${defaultCIRule}/${date}`,
    method: 'GET',
    types: [DEFAULT_CI_RULE_REQUEST, DEFAULT_CI_RULE_SUCCESS, DEFAULT_CI_RULE_ERROR],
    headers: {
      'x-correlation-id': uniqueNumber,
    },
    amount,
  },
  type: DEFAULT_CI_RULE_REQUEST,
  amount,
})

export const addCIDefaultAmount = (value) => ({
  type: ADD_CI_SUCCESS,
  value,
})

// export const getDefaultCIRule = (rules) => ({
//   [CALL_API]: {
//     url: `${defaultCIRule}/${rules.quoteEffectiveDate}`,
//     method: 'GET',
//     types: [DEFAULT_CI_RULE_REQUEST, DEFAULT_CI_RULE_SUCCESS, DEFAULT_CI_RULE_ERROR],
//     rules,
//   },
//   type: DEFAULT_CI_RULE_REQUEST,
//   rules,
// })


export {
  AGE_RULE_REQUEST,
  AGE_RULE_SUCCESS,
  AGE_RULE_ERROR,
  AMOUNT_RULE_REQUEST,
  AMOUNT_RULE_SUCCESS,
  AMOUNT_RULE_ERROR,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
  GET_TERM_FAILURE,
  DEFAULT_CI_RULE_REQUEST,
  DEFAULT_CI_RULE_SUCCESS,
  DEFAULT_CI_RULE_ERROR,
  ADD_CI_SUCCESS,
  // TERM_RULE_REQUEST,
  // TERM_RULE_SUCCESS,
  // TERM_RULE_ERROR,
  SET_AMOUNTS,
}
