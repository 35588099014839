import styled from 'styled-components'
import { keyframes } from 'theme'

const SpiningLoader = styled.svg`
  width:100%;
`

const Circle = styled.circle`
  animation: 2s linear ${keyframes.rotator} infinite;
`

export {
  SpiningLoader,
  Circle,
}
