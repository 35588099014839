import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Pager from 'services/pager'
import Async from 'containers/HOC/Async'
import {
  isUndefinedOrNull,
  isEmptyString,
  getCampaignCode,
} from 'common/utils'

import { updatePageAnalyics, updateAnalyics } from 'actions/analytics'


class DynamicComponent extends Component {
  state = {
    component: undefined,
    storeName: undefined,
    Routes: Pager.getRoutes(),
  }

  /**
   * On inital load of app load the correct container
   */
  componentDidMount() {
    this.updateComponent()
    this.updateAnalytics()
  }

  /**
   * When a new route has been loaded, the new container needs to be updated
   */
  componentDidUpdate(prevProps) {
    const { props, updateComponent, updateAnalytics } = this
    const { match: { path } } = props
    if (path !== prevProps.match.path) {
      updateComponent()
      updateAnalytics()
    }
  }

  updateAnalytics = () => {
    const {
      match: { path }, updatePageAnalyicsFn, initialise: { uniqueNumber, loading }, updateAnalyicsFn, analytics,
    } = this.props

    /*
      Then the app is closed, RESET_APP action is called and sets the state to
      undefined. However, after reducer is executed, updateAnalytics function
      inside a dynamic container gets called. It updates analytics store values
      which when are hydrated by redux. Once the customer returns to the app,
      i.e. from  disco by clicking "get a quote", those  values are rehydrated
      again.
      To prevent this, before closing an app, we can check for any value inside
      our redux store to be undefined, because we know that if the store is
      undefined the app was closed and we don't need to push any analytics.
    */
    if (loading === undefined) return null

    const { Routes } = this.state
    const analyticsObj = Pager.getComponent(path)
    const { store } = analyticsObj
    const person = path.includes('/joint') ? 2 : 1
    const isFirstPage = window.location.pathname === Routes[0]
    const isAmountPage = store === 'amount'
    if ((analytics.store !== store && !isUndefinedOrNull(analytics.store)) || (isFirstPage && isUndefinedOrNull(analytics.store))) {
      if (!isAmountPage) {
        updatePageAnalyicsFn({
          id: store,
          person,
        })
      }
    }

    if (path && store === 'needs') {
      const campaignCode = getCampaignCode()
      if (!isEmptyString(campaignCode)
        && !isUndefinedOrNull(uniqueNumber)
        && isUndefinedOrNull(uniqueNumber)
        && isUndefinedOrNull(campaignCode)) {
        updateAnalyicsFn({ CampaignCode: campaignCode, qquoteID: uniqueNumber })
      }
    }

    return null
  }

  updateComponent = (path) => {
    const componentObj = Pager.getComponent(path)
    const { store, component } = componentObj
    this.setState({
      storeName: store,
      component: Async({
        loader: () => import(/* webpackChunkName: "[request]" */`containers/${component}`),
      }),
    })
  }

  /**
   * NextPage gets passed down to the container it allows the
   * component to just run function and get the next page
   */
  NextPage = () => {
    Pager.next()
  }

  createPager = () => {
    Pager.createPager(null)
  }

  /**
   *  is path a a joint one, used for seting store data to value or value2
   */
  isJoint = () => {
    const { match: { path } } = this.props
    return path.includes('joint')
  }

  /**
   * Is the current datq a single or joint policy
   */
  isJointPolicy = () => {
    const { who } = this.props
    return (!isUndefinedOrNull(who.value)) && who.value === 'joint'
  }

  /**
   * Preload gets passed down to the container it allows the
   * component to just run function and get the preload the next page
   */
  Preload = () => {
    const nextPage = Pager.getNext()
    if (!isUndefinedOrNull(nextPage)) {
      const nextComponent = Async({ loader: () => import(/* webpackChunkName: "[request]" */`containers/${nextPage}`) })
      nextComponent.preload().catch(e => e)
    }
  }

  PreloadNextFromNeeds = () => {
    Pager.getNextComponentFromNeeds()
  }

  /**
   * If there no error then load the container, if there is load null or error page,
   * to prevent the app from crashing
   */
  render() {
    const { NextPage, Preload, isJoint, createPager, PreloadNextFromNeeds } = this
    const { component, storeName } = this.state
    const { match: { path } } = this.props
    return component ? React.createElement(component, {
      NextPage,
      createPager,
      Preload,
      isJoint,
      isJointPolicy: this.isJointPolicy(),
      path,
      storeName,
      PreloadNextFromNeeds,
    }) : null
  }
}

DynamicComponent.propTypes = {
  match: PropTypes.object,
  who: PropTypes.object,
  initialise: PropTypes.object,
  updatePageAnalyicsFn: PropTypes.func,
  updateAnalyicsFn: PropTypes.func,
  analytics: PropTypes.object,
}

const mapPropsToState = ({ form: { who }, initialise, analytics }) => ({
  who,
  initialise,
  analytics,
})

const mapDispatchToProps = dispatch => ({
  updateAnalyicsFn: (e) => dispatch(updateAnalyics(e)),
  updatePageAnalyicsFn: (e) => dispatch(updatePageAnalyics(e)),
})


export default connect(mapPropsToState, mapDispatchToProps)(DynamicComponent)
