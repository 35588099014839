const IconList = [
  'arrow-r',
  'check',
  'chevron-u',
  'clock',
  'cutlery',
  'home',
  'lifestyle',
  'partner',
  'plus',
  'loader',
  'refresh',
  'sort-desc',
  'close',
  'close-modal',
  'user',
  'users',
  'arrow-round-forward',
  'timer',
  'policytick',
  'phone',
  'callback',
]

const IconObjects = [
  { key: 'ArrowR', value: 'arrow-r' },
  { key: 'Check', value: 'check' },
  { key: 'ChevronUp', value: 'chevron-u' },
  { key: 'Clock', value: 'clock' },
  { key: 'Cutlery', value: 'cutlery' },
  { key: 'Home', value: 'home' },
  { key: 'Lifestyle', value: 'lifestyle' },
  { key: 'Partner', value: 'partner' },
  { key: 'Plus', value: 'plus' },
  { key: 'Refresh', value: 'refresh' },
  { key: 'Loader', value: 'loader' },
  { key: 'Plus', value: 'plus' },
  { key: 'SortDesc', value: 'sort-desc' },
  { key: 'Close', value: 'close' },
  { key: 'CloseModal', value: 'close-modal' },
  { key: 'User', value: 'user' },
  { key: 'Users', value: 'users' },
  { key: 'ArrowRoundForward', value: 'arrow-round-forward' },
  { key: 'Timer', value: 'timer' },
  { key: 'PolicyTick', value: 'policytick' },
  { key: 'Phone', value: 'phone' },
  { key: 'Callback', value: 'callback' },
]

const LargeIconList = [
  'ci',
  'noci',
  'nocci',
  'cci',
  'decreasing',
  'level',
  'female',
  'male',
  'joint',
  'single',
  'non-smoker',
  'smoker',
  'mortgage',
  'children',
  'debts',
  'partner',
  'other',
  'not_sure',
  'genderMale',
  'genderFemale',
]

const LargeIconObjects = [
  { key: 'CI', value: 'ci' },
  { key: 'NoCI', value: 'noci' },
  { key: 'NoCCI', value: 'nocci' },
  { key: 'CCI', value: 'cci' },
  { key: 'Decreasing', value: 'decreasing' },
  { key: 'Level', value: 'level' },
  { key: 'Female', value: 'female' },
  { key: 'Male', value: 'male' },
  { key: 'Joint', value: 'joint' },
  { key: 'Single', value: 'single' },
  { key: 'Nonsmoking', value: 'non-smoker' },
  { key: 'Smoking', value: 'smoker' },
  { key: 'Mortgage', value: 'mortgage' },
  { key: 'Children', value: 'children' },
  { key: 'Debts', value: 'debts' },
  { key: 'Partner', value: 'partner' },
  { key: 'Other', value: 'other' },
  { key: 'NotSure', value: 'not_sure' },
  { key: 'GenderMale', value: 'genderMale' },
  { key: 'GenderFemale', value: 'genderFemale' },
]

const StepData = [
  {
    title: 'Personal',
    completedIcon: 'check',
  },
  {
    title: 'Details',
    completedIcon: 'check',
  },
  {
    title: 'Quote',
    completedIcon: 'check',
  },
  {
    title: 'Application',
    completedIcon: 'check',
  },
  {
    title: 'Covered',
    completedIcon: 'check',
  },
]

const Titles = [
  {
    title: 'Mr',
    value: 'mr',
  },
  {
    title: 'Mrs',
    value: 'mrs',
  },
  {
    title: 'Miss',
    value: 'miss',
  },
  {
    title: 'Ms',
    value: 'ms',
  },
  {
    title: 'Dr',
    value: 'dr',
  },
]

const quoteModalTitles = {
  smoker: 'Your smoking status',
  smokerPartner: 'Your Partner\'s smoking status',
  type: 'Type of cover',
  amount: 'Cover Amount',
  ci: 'Critical Illness cover',
  cci: 'Child Critical Illness cover',
  ciAmount: 'Critical Illness amount',
  term: 'Cover Length',
}

const quoteButtonText = 'Update price'

const yourQuoteText = {
  heading: 'Your quote',
  subheading: 'per month',
  coveredTime: 'Only 10 minutes away from being covered!',
}

const interstitialModalText = {
  title: 'Next step: application',
  messages: [
    {
      title: '99.4% payout',
      subtitle: 'to loved ones',
    },
    {
      title: 'Early payout',
      subtitle: 'for terminal illness',
    },
    {
      title: 'Free support service',
      subtitle: 'for terminal illness',
    },
  ],
  subtitle: 'preparing application',
}

const sessioncamClassList = [
  'sessioncamhidetext',
  'nls_protected',
]

const policyDocumentLinks = {
  termsAndConditions: "https://cdn.bgllife.co.uk/beaglestreet/downloads/beagle-street-policy-terms-and-conditions-V9.pdf",
  summary: "https://cdn.bgllife.co.uk/beaglestreet/downloads/beagle-street-policy-summary-V10.pdf"
}

const openingHours = {
  weekday: { open: 8, close: 20, human: { open: "8am", close: "8pm" } },
  saturday: { open: 9, close: 17, human: { open: "9am", close: "5pm" } },
  bankholiday: { open: 9, close: 17, human: { open: "9am", close: "5pm" } },
  sunday: { open: null, close: null, human: null }
}

const dataLayerKeys = [
  'store',
  'edit',
  'editType',
  'edited',
  'gtm.uniqueEventId',
  'pagecount',
  'previousState',
  'Count',
  'prevCount',
  'previousAnalytics',
  'errorMessage',
  'qqouteID',
  'campaignCode',
  'emailMarketing',
  'postMarketing',
  'phoneMarketing',
  'textMarketing',
  undefined,
  'undefined',
  'storeName',
  'CoverLength',
  'editClose',
]

export {
  IconList,
  IconObjects,
  LargeIconList,
  LargeIconObjects,
  StepData,
  Titles,
  quoteModalTitles,
  quoteButtonText,
  yourQuoteText,
  interstitialModalText,
  sessioncamClassList,
  policyDocumentLinks,
  openingHours,
  dataLayerKeys,
}
