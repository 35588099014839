/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {
  Row,
  Col,
  Button,
  Input,
  InputBox,
  ReplaceWithError,
  IndicativeQuote,
  TrustpilotRating
} from '@bgllife/ui.componentlibrary'
import Modal from 'components/Modal'
import Icon from 'components/Icon'
import InterstitialModal from 'components/InterstitialModal'
import trustpilotLogo from 'assets/images/trustpilot-logo.png'
import { formatCurrency, childCIValue, dashesToSlashes, quoteValidDate, calculateCI, DataLayerEventNameValue, validNumber, isUndefinedOrNull, brandNameInLowerCase } from 'common/utils'
import validateInput from 'common/validateIQ'

import {
  updateForm,
  submitForm,
} from 'actions/form'

import { updatePageAnalyics, updateAnalyics, updateEditAnalyics, updateErrorAnalytics, updatePriceEditAnalytics } from 'actions/analytics'

import {
  quoteModalTitles,
  quoteButtonText,
  sessioncamClassList,
} from 'common/consts'
import { getRating } from 'actions/trustpilot'
import { isMobile } from 'react-device-detect'
import liveChatScript from '../config/liveChatScript'

const QuoteNew = () => {
  // editedFields handles input onChange and updates redux.
  const [editedFields, setEditedFields] = useState({})
  const [fields, setFields] = useState({})
  const [pricing, setPricing] = useState({})
  const [modal, setModal] = useState({})
  const [isCiAmountValid, setIsCiAmountValid] = useState(true)
  const [error, setError] = useState({
    isError: false,
    errorMsg: ''
  })
  const [interstitialModalShow, setInterstitialModalShow] = useState(false)

  const brandName = brandNameInLowerCase()
  // grab everything from redux.
  const form = useSelector(state => state.form, shallowEqual)
  const loading = useSelector(state => state.form.loading, shallowEqual)
  const rules = useSelector(state => state.businessRules, shallowEqual)
  const term = useSelector(state => state.term, shallowEqual)
  const criticalIllness = useSelector(state => state.criticalIllness, shallowEqual)
  const analytics = useSelector(state => state.analytics, shallowEqual)

  const dispatch = useDispatch()

  const trustpilotData = useSelector(state => state.trustpilot)

  useEffect(() => {
    if (isUndefinedOrNull(trustpilotData.data)) {
      dispatch(getRating())
    }
    if(!isMobile){
      setTimeout(() => {
        const element = document.querySelector('header')
        if (element) element.scrollIntoView()
      }, 100)
    }
    window.scrollTo(0,0)
  }, [])

  // attach redux data to state passed to component.
  // editedFields updates redux -> change in redux updates local state fields.
  useEffect(() => {
    document.querySelector('body').classList.add('darker-bg')

    const isJoint = form.who.value === 'joint'
    if (form) {
      setFields({
        policy: form.who.value,
        genders: isJoint ? [form.gender.value, form.gender.value2] : [form.gender.value],
        dobs: [form.dob.value.dateOfbirth, isJoint && form.dob.value2.dateOfbirth],
        smoker: isJoint ? [form.smoker.value, form.smoker.value2] : [form.smoker.value],
        type: form.type.value,
        term: form.term.value,
        amount: form.amount.value,
        ciAmount: form.ci.ciAmount,
        cciAmount: form.ci.cciAmount ? form.ci.cciAmount : childCIValue(form.ci.ciAmount, rules.amounts.childCiPercentage, rules.amounts.childCiMaximumAmount), // needed as guidance populates field with ci and IQ doesn't.
        ci: form.ci.value,
      })
    }

    return () => {
      document.querySelector('body').classList.remove('darker-bg')
    }
  }, [form])

  useEffect(() => {
    if (form.data.price || form.data.price === 0) {
      setPricing({
        price: form.data.price,
        url: form.data.url ?? form.data.clickThroughURL // to handle guidance initial api url return and making a price call will return normal URL
      })

      dispatch(updatePageAnalyics({
        id: 'quote',
        person: 1,
        init: true,
      }))

      dispatch(updateAnalyics({
        storeName: 'quote',
        isJoint: fields.who === 'joint',
        key: analytics.IQPriceGiven ? 'quoteCompleteUpdate' : 'quoteCompleteInitial',
        finalPrice: form.data.price
      }))
    }
  }, [form.data])

  const handleClick = (e) => {
    const { currentTarget: { dataset: { type, person } } } = e

    const title = quoteModalTitles[person === '2' ? 'smokerPartner' : type]

    // edit open event DL
    dispatch(updateEditAnalyics({
      storeName: 'quote',
      editType: type,
      isOpen: !modal.visible,
      person,
      button: quoteButtonText[type]
    }))

    setModal({
      visible: true,
      type,
      person,
      title,
      button: quoteButtonText[type],
    })

    return true
  }

  const handleChange = (e, person) => {
    const { target: { value, name } } = e

    // reset error
    setError({ isError: false, errorMsg: '' })

    const numericFields = ["ciAmount", "amount", "term"]
    const isNumericField = numericFields.includes(name)
    let isInvalidNumber
    let isNumericFieldNotNumber

    if (isNumericField) {
      isInvalidNumber = !validNumber(value)
      isNumericFieldNotNumber = isNumericField && isInvalidNumber && value !== ''
    }

    if (isNumericFieldNotNumber) return false

    // update array states
    if (person) {
      const arr = [
        ...fields[name].slice(0, person - 1),
        value,
        ...fields[name].slice(person)
      ]
      setEditedFields({ [name]: arr })
    } else {
      // non-array fields
      setEditedFields({
        ...editedFields,
        [name]: value
      })
    }
    return true
  }

  const handleSubmit = () => {
    const { type, person } = modal
    const { ciAmount } = fields

    let value = ''
    const originalValue = fields[type]
    let key = person === '2' ? 'value2' : 'value'
    key = type === 'ciAmount' ? 'ciAmount' : key

    const modalType = type === 'cci' ? 'ci' : type
    const formType = type === 'ciAmount' || type === 'cci' ? 'ci' : type

    // if type is array (smoker), assign value state of index.
    if (type === 'smoker') value = isEmpty(editedFields) ? fields[modalType][person - 1] : editedFields[modalType][person - 1]
    else value = !isEmpty(editedFields[modalType]) || editedFields[modalType] === '' ? editedFields[modalType] : fields[modalType]

    // VALIDATE INPUTS on input amounts [term, ciAmount, amount]
    if (type === 'amount' || type === 'term' || type === 'ciAmount') {
      const { isError, errorMsg } = validateInput(type, value, { ...term, ...rules.amounts }, form)
      let validCi = true

      // if amount entered errors with CI
      if (type === 'amount' && !isError && fields.ci !== 'noci') {
        validCi =
          parseInt(fields.ciAmount, 10) <= parseInt(value, 10)
          && parseInt(fields.ciAmount, 10) >= rules.amounts.minimumCriticalIllness
      }

      setError(prevState => ({ ...prevState, isError, errorMsg }))

      // if validation returns correct, dispatch
      if (!isError) {
        dispatch(updateForm(formType, { [key]: value }))

        if (validCi) {
          submitUpdateForm()
          setModal({ visible: false })
          setIsCiAmountValid(true)
          DataLayerEventNameValue('editClose', 'editClose', modal.title)
          dispatch(updatePriceEditAnalytics({
            updateType: type,
            originalValue: (type === 'ciAmount' ? { originalValue, ciAmount } : originalValue),
          }))
        }
      }

      // error analytics
      if (isError) {
        dispatch(updateErrorAnalytics({
          person: false,
          errorType: type,
          message: errorMsg,
          amount: editedFields.amount,
          term: editedFields.term,
          ciAmount: editedFields.ciAmount,
        }))
      }
    } else {
      // dispatch non-input types.
      dispatch(updateForm(formType, { [key]: value }))
      setModal({ visible: false })
      setIsCiAmountValid(true)
      submitUpdateForm()
      DataLayerEventNameValue('editClose', 'editClose', modal.title)
      dispatch(updatePriceEditAnalytics({
        updateType: type,
        originalValue: (type === 'ciAmount' ? { originalValue, ciAmount } : originalValue),
      }))
    }

    return true
  }

  // to validate CI after updating life amount.
  useEffect(() => {
    if (!isEmpty(fields) && fields.ci !== 'noci' && rules.amounts.minimumCriticalIllness) validateCriticalIllness()
  }, [fields.amount, fields.ciAmount,])

  const validateCriticalIllness = () => {
    const isValid =
      parseInt(fields.ciAmount, 10) <= parseInt(fields.amount, 10)
      && parseInt(fields.ciAmount, 10) >= rules.amounts.minimumCriticalIllness

    if (!isValid) {
      setModal({
        type: 'ciAmount',
        visible: true,
        title: quoteModalTitles.ciAmount,
        button: quoteButtonText,
      })

      setIsCiAmountValid(false)

      setFields({
        ...fields,
        ciAmount: calculateCI(fields.amount, criticalIllness)
      })

      setError({
        isError: true,
        errorMsg: 'As the cover amount has changed, the original Critical Illness amount is now invalid, please enter a new Critical Illness cover amount'
      })

      dispatch(updateErrorAnalytics({
        person: false,
        errorType: 'ciAmount',
        message: 'As the cover amount has changed, the original Critical Illness amount is now invalid, please enter a new Critical Illness cover amount',
        amount: fields.amount,
        ciAmount: fields.ciAmount,
      }))
    }
  }

  const restorePreviousValidValue = (modalType) => {
    setEditedFields({
      ...editedFields,
      [modalType]: fields[modalType]
    })
  }

  const preventModalClosing = () => {
    if(error.isError) {
      restorePreviousValidValue(modal.type)
    }
  }

  const closeModal = () => {
    setModal({ visible: false })
    setError({ isError: false })
    DataLayerEventNameValue('editClose', 'editClose', modal.title)
    return true
  }

  // once form is updated, if guidance, we need to remove cciAmount field to calculate child correctly after Guidance call.
  const submitUpdateForm = () => {
    dispatch(submitForm())
    if (form.ci.cciAmount) dispatch(updateForm('ci', { cciAmount: undefined }))
  }

  const maxCi = parseInt(form.amount.value, 10) > rules.amounts.maximumCriticalIllness ? rules.amounts.maximumCriticalIllness : parseInt(form.amount.value, 10)

  useEffect(() => {
    const script = document.createElement('script')
    script.textContent = liveChatScript
    document.head.appendChild(script)
  
    return () => {
      document.head.removeChild(script)
      const scriptToRemove = document.querySelector('script[src*="https://lptag"]')
      if(scriptToRemove){
        document.head.removeChild(scriptToRemove)
      }
    }
  }, [])

  return (
    !isEmpty(fields) && (
      <>
        <IndicativeQuote
          heading='Edit your quote'
          description="We have used the information you provided to work out the cover you might need."
          fields={{ ...fields, dobs: [dashesToSlashes(form.dob.value.dateOfbirth), form.who.value === 'joint' && dashesToSlashes(form.dob.value2.dateOfbirth)] }}
          iconColor='red'
          handleClick={(e) => handleClick(e)}
          price={pricing.price}
          expiry={quoteValidDate(fields.dobs)}
          url={pricing.url}
          loading={loading}
          guidance={form.needs.value === 'calculator'}
          sessioncamHide
          sessioncamClassList={sessioncamClassList}
          showInterstitialModal={() => setInterstitialModalShow(true)}
        >
          <Col xs={12} style={{ padding: 0 }}>
            {brandName === 'beaglestreet' && !isUndefinedOrNull(trustpilotData.data) && <TrustpilotRating
              data={{
                numberOfStars: trustpilotData.data.rating.stars.toString(),
                ratingText: trustpilotData.data.rating.string,
                starsImageUrl: trustpilotData.data.images.starSvg.url,
                trustpilotLogoUrl: trustpilotLogo,
              }}
              reviewsUrl="https://uk.trustpilot.com/review/beaglestreet.com"
              text="Rated"
              id="mainTrustpilot"
            />}
          </Col >
        </IndicativeQuote >

        {interstitialModalShow && <InterstitialModal
          dataUxId='interstitialModal'
          isVisible
          name={form.name.value.firstName}
          trustpilotData={trustpilotData.data}
          trustpilotLogoUrl={trustpilotLogo}
          brandName={brandName}
          text="Our customers say we're"
          alternativeStyle
          trustpilotId="interstitialTrustpilot"
        />}

        <Modal
          isVisible={modal.visible}
          heading={modal.title}
          toggle={() => closeModal()}
          preventToggle={isCiAmountValid}
          submit={(e) => handleSubmit(e)}
          showCloseButton={isCiAmountValid}
          onError={() => preventModalClosing()}
        >
          {
            modal.type === 'smoker' && (
              <>
                <Input.RadioItem data-type="smoker" type="checkbox" id="smoker" group="smoker" value="smoker" onChange={(e) => handleChange(e, modal.person)} checked={editedFields.smoker ? (editedFields.smoker[modal.person - 1] === 'smoker') : (fields.smoker[modal.person - 1] === 'smoker')}>
                  <Icon kind="check" size="18" color="#fff" />
                  Smoker
                </Input.RadioItem >
                <Input.RadioItem data-type="smoker" type="checkbox" id="non-smoker" group="smoker" value="non-smoker" onChange={(e) => handleChange(e, modal.person)} checked={editedFields.smoker ? (editedFields.smoker[modal.person - 1] === 'non-smoker') : (fields.smoker[modal.person - 1] === 'non-smoker')}>
                  <Icon kind="check" size="18" color="#fff" />
                  Non-smoker
                </Input.RadioItem>
              </>
            )
          }
          {modal.type === 'type' && (
            <Input>
              <Input.RadioItem data-type="type" id="level" group="type" value="level" onChange={(e) => handleChange(e)} checked={editedFields.type ? (editedFields.type === 'level') : (fields.type === 'level')}>
                <Icon kind="check" size="18" color="#fff" />
                Level
              </Input.RadioItem>
              <Input.RadioItem data-type="type" id="decreasing" group="type" value="decreasing" onChange={(e) => handleChange(e)} checked={editedFields.type ? (editedFields.type === 'decreasing') : (fields.type === 'decreasing')}>
                <Icon kind="check" size="18" color="#fff" />
                Decreasing
              </Input.RadioItem>
            </Input>
          )}
          {modal.type === 'amount' && (
            <>
              <p>{`Choose between £${formatCurrency(rules.amounts.minimumLife)} and £${formatCurrency(rules.amounts.maximumLife)}`}</p>
              <InputBox
                type="tel"
                id="lifeAmount"
                name="amount"
                borderColor='#CCCCCC'
                hiddenLabelText="Enter your term amount"
                value={editedFields.amount || editedFields.amount === '' ? editedFields.amount : fields.amount}
                onChange={(e) => handleChange(e)}
                pound
              />
            </>
          )}
          {modal.type === 'ci' && (
            <Input>
              <Input.RadioItem id="ci" group="ci" value="ci" onChange={(e) => handleChange(e)} checked={editedFields.ci ? (editedFields.ci === 'cci' || editedFields.ci === 'ci') : (fields.ci === 'cci' || fields.ci === 'ci')}>
                <Icon kind="check" size="18" color="#fff" />
                Include
              </Input.RadioItem>
              <Input.RadioItem id="noci" group="ci" value="noci" onChange={(e) => handleChange(e)} checked={editedFields.ci ? (editedFields.ci === 'noci') : (fields.ci === 'noci')}>
                <Icon kind="check" size="18" color="#fff" />
                Exclude
              </Input.RadioItem>
            </Input>
          )}
          {modal.type === 'ciAmount' && (
            <>
              <p>{`Choose between £${formatCurrency(rules.amounts.minimumCriticalIllness)} and £${formatCurrency(maxCi)}`}</p>
              <InputBox
                type="tel"
                id="ciAmount"
                name="ciAmount"
                required={false}
                borderColor='#CCCCCC'
                hiddenLabelText="Enter your critical illness amount"
                value={editedFields.ciAmount || editedFields.ciAmount === '' ? editedFields.ciAmount : fields.ciAmount}
                onChange={(e) => handleChange(e)}
                pound
              />
            </>
          )}
          {modal.type === 'cci' && (
            <Input>
              <Input.RadioItem id="cci" group="ci" value="cci" onChange={(e) => handleChange(e)} checked={editedFields.ci ? (editedFields.ci === 'cci') : (fields.ci === 'cci')}>
                <Icon kind="check" size="18" color="#fff" />
                Include
              </Input.RadioItem>
              <Input.RadioItem id="ci" group="ci" value="ci" onChange={(e) => handleChange(e)} checked={editedFields.ci ? (editedFields.ci === 'ci') : (fields.ci === 'ci')}>
                <Icon kind="check" size="18" color="#fff" />
                Exclude
              </Input.RadioItem>
            </Input>
          )}
          {modal.type === 'term' && (
            <>
              <p>{`Choose between ${term.minimum} and ${term.maximum} years`}</p>
              <InputBox
                id="term"
                borderColor='#CCCCCC'
                hiddenLabelText="Enter your term amount"
                onChange={(e) => handleChange(e)}
                value={editedFields.term || editedFields.term === '' ? editedFields.term : fields.term}
                name="term"
              />
            </>
          )}
          <ReplaceWithError
            dataUxId='indicative-quote'
            isErrorVisible={error.isError}
            errorMessage={error.errorMsg}
            forceModalSubmit={error.isError}
            render={(
              <Row>
                <Col xs={{ span: 8, offset: 2 }} style={{ marginTop: 20 }}>
                  <Button dataUxId='123' kind="primary" type="submit" id="modalSubmit" style={{ fontSize: 16 }}>{quoteButtonText}</Button>
                </Col>
              </Row>
            )}
          />
        </Modal>
      </>

    )
  )
}

export default QuoteNew
