import API_MAP, { API_KEYS } from 'services/endpoint'
import axios from 'axios'

export const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST'
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS'
export const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE'

export const getRating = () => dispatch => {
  dispatch(({ type: GET_REVIEW_REQUEST }))
  return axios.get(API_MAP.trustPilot, { params: { apikey: API_KEYS.trustPilot } }).then((response) => {
    const { data: { links } } = response
    axios.get(links[2].href, { params: { apikey: API_KEYS.trustPilot } })
      .then((r) => {
        axios.get(links[3].href, { params: { apikey: API_KEYS.trustPilot } })
          .then((i) => {
            dispatch(({
              type: GET_REVIEW_SUCCESS,
              payload: {
                rating: { ...r.data },
                images: { ...i.data },
              },
            }))
          })
      })
  }).catch((error) => {
    dispatch({ type: GET_REVIEW_FAILURE, error })
  })
}
