import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Button } from '@bgllife/ui.componentlibrary'
import { brandHeaderValues } from 'common/brandData'

import {
  uniqueIdentifer,
  getOpeningHours,
  isUndefinedOrNull,
  brandNameInLowerCase,
} from '../../common/utils'

import { openingHours } from '../../common/consts'

import {
  Header,
  Icon,
  Logo,
} from './style'

const HeaderComp = ({
  startAgainFunc = () => {},
  showStartAgain,
  closeFunc = () => {},
}) => {
  const [openingTimes, setOpeningTimes] = useState("")
  const UKBankHolidaysStoreData = useSelector(state => state.bankHolidays)

  useEffect(() => {
    if(!isUndefinedOrNull(UKBankHolidaysStoreData.data)) {
      const dateObj = {
        dateOne: new Date(),
        dateTwo: new Date(),
      }
      setOpeningTimes(
        getOpeningHours(dateObj, openingHours, UKBankHolidaysStoreData.data["england-and-wales"].events.map(i => i.date) || null)
      )
    }
  }, [UKBankHolidaysStoreData])

  const isContactCentreOpen = () => {
    let isOpen = true
    if (openingTimes.includes('Open tomorrow at') || openingTimes.includes('Open at')) isOpen = false
    return isOpen
  }

  const brandName = brandNameInLowerCase()
  const { contactdetails: { phoneNumberFormatted, phoneNumberWithoutSpaces }, logoImgSrc, iconColor, callbackUrl, brandNameLogoAltText } = brandHeaderValues(brandName)

  return (
    <Header>
      <Row>
        <Col xs={isContactCentreOpen() ? 8 : 6} sm={6} md={6} lg={7} style={{ display: 'flex', alignItems: 'center' }} className="top_left_container">
          <Logo id="logo" src={logoImgSrc} alt={brandNameLogoAltText} />
          <a className={`main-phone-link ${isContactCentreOpen() ? 'open' : 'closed'}`} href={`tel:${phoneNumberWithoutSpaces}`}>
            {isContactCentreOpen() && (
              <span className="help">We are here to help</span>
            )}
            <span className={`phone-number ${isContactCentreOpen() ? 'open' : 'closed'}`}>{phoneNumberFormatted}</span>
            {isContactCentreOpen() && (
              <span className="open-until">{openingTimes}</span>
            )}
          </a>
        </Col>
        <Col xs={isContactCentreOpen() ? 4 : 6} sm={6} md={6} lg={5} className="top_button_container">
          {brandName === 'beaglestreet' &&
            <div className={`button_group callback_container ${isContactCentreOpen() ? 'open' : 'closed'}`}>
              <Button kind="clear" as="a" newWindow href={callbackUrl} dataUxId={uniqueIdentifer('callbackQuickQuote')} id="header-callback-btn">
                <Icon kind="callback" size="16" color={iconColor} />
                <span className="header-icon-label cb">Call back</span>
              </Button>
            </div>
          }

          {showStartAgain && (
            <div className="button_group">
              <Button kind="clear" onClick={() => startAgainFunc()} dataUxId={uniqueIdentifer('startAgain')} id="header-start-again">
                <Icon kind="refresh" size="14" color={iconColor} />
                <span className="header-icon-label">Restart</span>
              </Button>
            </div>
          )}

          <div className="button_group">
            <Button kind="clear" onClick={() => closeFunc()} dataUxId={uniqueIdentifer('closeQuickQuote')} id="header-close-btn">
              <Icon kind="close" size="15" color={iconColor} />
              <span className="header-icon-label">Close</span>
            </Button>
          </div>

        </Col>
      </Row>
    </Header>
  )
}

HeaderComp.propTypes = {
  startAgainFunc: PropTypes.func,
  showStartAgain: PropTypes.bool,
  closeFunc: PropTypes.func,
}

export default HeaderComp
