import styled, { css } from 'styled-components'
import { theme } from 'theme'
import { Col } from '@bgllife/ui.componentlibrary'

const StyledColumn = styled(Col)`
  overflow: auto;
  opacity: 0;
  transition: opacity ease-in 250ms;
  height: initial;
  align-self: flex-start;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;

  -ms-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;

  max-height: 80%;
  min-height: 80%;
  max-width: 40%;
  min-width: 40%;
  top:-500px;
  @supports (justify-content: center) {
    max-height: none;
    min-height: 0px;
    max-width: none;
    min-width: 0px;
  }

  &.entered {
    top:0;
    opacity: 1;
    transition: opacity ease-in 250ms;
  }
`

const ModalContainer = styled.div`
  color: ${theme.colors.primary};
  padding: 20px;
  border-radius: 3px;
  background: ${theme.colors.white};
  width: 100%;
  &:focus {
    outline: none;
  }
  margin-top:45px;
`

const Heading = styled.h4`
  ${({ theme: { components: { modal } } }) => css`
    color: ${modal.headingColor};
  `}
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 20px;
`

const CloseBtn = styled.button`
  margin-top: -9px;
  float: right;
  font-size: 2em;
  text-shadow: none;
  opacity: 1;
  color: rgba(0, 0, 0, 0.54);
  outline: 0;
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;

  svg {
    ${({ theme: { components: { modal: { closeIcon } } } }) => css`
      fill: ${closeIcon.fill};
      width: ${closeIcon.width};
      height: ${closeIcon.height};
      background-color: ${closeIcon.background};
      padding: ${closeIcon.padding};
      border-radius: ${closeIcon.radius};
    `}
  }
`

const Content = styled.form`
  width: 100%;
  p {
    color: #373737;
  }
`

export {
  ModalContainer,
  Heading,
  CloseBtn,
  Content,
  StyledColumn as Column,
}
