import axios from 'axios'
import { isEmptyArray, updateErrorAnalytics } from '../common/utils'
import history from '../internalHistory'
import {
  ADDRESS_REQUEST,
} from '../actions/address'

// Unique tag to make sure there's no overlap of tags
export const CALL_API = '@@CALL_API'


// Format multiple types into one type
function formatActionTypes(types) {
  let [requestType, successType, errorType] = types

  if (typeof requestType === 'string' || typeof requestType === 'symbol') {
    requestType = { type: requestType }
  }
  if (typeof successType === 'string' || typeof successType === 'symbol') {
    successType = { type: successType }
  }
  if (typeof errorType === 'string' || typeof errorType === 'symbol') {
    errorType = { type: errorType }
  }

  return [requestType, successType, errorType]
}

const notAllowedActions = [
  ADDRESS_REQUEST,
]

export function callAPIMiddleware({ dispatch }) {
  return next => (action) => {
    if (!Object.prototype.hasOwnProperty.call(action, CALL_API)) {
      return next(action)
    }
    /**
     *  TODO:
     *  - Handle action dispatches
     *  - Handle API Options
     *  - Handle API call
     *  - Validation fire all events
     */

    const callOption = action[CALL_API]
    const {
      url,
      method,
      headers,
      body,
      types,
      params,
    } = callOption
    const [requestType, successType, errorType] = formatActionTypes(types)
    dispatch(requestType) // Request
    return axios.request({
      method,
      url,
      headers,
      data: body,
      params,
    }).then(({ status, data }) => {
      if (status === 200 || status === 304) {
        if (!isEmptyArray(data)) {
          dispatch({ ...successType, payload: data, headers }) // Success
        } else {
          dispatch({ ...errorType, payload: data }) // Error
          updateErrorAnalytics({
            'Error Message Value': data,
          })
        }
      } else {
        dispatch({ ...errorType, payload: data }) // Error
        updateErrorAnalytics({
          'Error Message Value': data,
        })
      }
    }).catch(error => {
      const { type } = action

      if (!notAllowedActions.includes(type)) {
        history.push('/error500')
      }
      dispatch({ ...errorType, payload: error }) // Error
      const {
        message,
        stack,
      } = error

      updateErrorAnalytics({
        'Error Message Value': {
          message,
          stack,
        },
      })
      dispatch({ ...errorType, payload: error }) // Error
      // const errorText = (request !== undefined && request.status !== 0) ? request.status : message.toLowerCase().split(' ').join('-')
    })
  }
}
