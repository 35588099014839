import styled from 'styled-components'

const TelLink = styled.a`

  text-decoration: none;
  color: #8e5aad;

  &:hover,
  &:focus {
    color: #57356C;
  }
`
const Link = styled.a`
  color: #8e5aad;

  &:hover,
  &:focus {
    color: #57356C;
  }
`
export default TelLink

export {
  TelLink,
  Link,
}
