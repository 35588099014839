import styled from 'styled-components'
import { container, query } from 'theme'

const Containter = styled.main`
  ${container}
  min-height: calc(100vh - ${props => props.theme.components.layout.headerHeight} - ${props => props.theme.components.layout.footerHeight});
  ${query.combine(['extraSmall', 'small'], `
    padding: 0 0 15px 0;
  `)}
`

export default Containter
