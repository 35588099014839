import React from 'react'
import { isMobile } from 'react-device-detect'
import { ValidationTextStyled } from 'containers/styles/SummaryContainerStyles'
import { LargeIcon } from "@bgllife/ui.componentlibrary"
import { sessioncamClassList } from './consts'
import {
  joinArrayWithSpace,
  toTitleCase,
  formatCurrency,
  getSingularOrPlural,
  brandNameInLowerCase,
} from './utils'
import { brandDetails } from './brandData'

const {
  contactdetails: {
    phoneNumberFormatted,
    phoneNumberWithoutSpaces
  },
  links: {
    privacyLink,
    policyTermsAndConditionsLink,
  },
  brandNames: {
    brandName: fullBrandName,
    toolTipBrandName, 
  },
} = brandDetails(brandNameInLowerCase())

const ProtectCopy = {
  heading: 'Why are you looking for life insurance today?',
  subheading: 'Select all that apply',
  icons: {
    mortgage: 'Cover my mortgage',
    debts: (<>Cover my<br /> debts</>),
    partner: 'Leave money for my partner',
    children: 'Leave money for my children',
    other: 'Other',
    not_sure: 'Not sure',
  },
  helpText: '<strong>Cover my mortgage or cover my debts</strong> – Life insurance could help relieve the financial burden on your loved ones if the worst ever happened to you. The payout could cover things like daily living costs or remaining debts like a mortgage.<br /><br /><strong>Leave money for your partner or your children</strong> – If you would like to leave money for your partner, you might need to consider placing your policy in trust or writing a will, to guarantee your partner will receive this money. Don\'t worry, we offer a free will writing service, and handy Trust Tool to help put your policy in trust, if you need to do this.',
}

const PolarQuestions = {
  gender: {
    options: ['Female', 'Male'],
    icons: ['female', 'male'],
    values: ['female', 'male'],
    heading: 'What was your assigned sex at birth?',
    jointHeading: '',
    tooltip: 'Why do we need to know this?',
    tooltipText: 'Some of the questions in our medical journey are based on your anatomy.',
  },
  smoker: {
    options: ['Yes', 'No'],
    icons: ['smoker', 'non-smoker'],
    values: ['smoker', 'non-smoker'],
    heading: 'Have you used any tobacco products or nicotine replacement products (including e-cigarettes) in the last 12 months?',
    tooltip: 'Why do we need to know this?',
    tooltipText: 'We need to know if you have smoked to provide an accurate price.',
  },
  partner: {
    options: ['Just me', 'Me and my partner'],
    values: ['single', 'joint'],
    icons: ['single', 'joint'],
    heading: 'Who would you like to cover?',
    tooltip: 'What does this mean?',
    tooltipText: '<strong>Single</strong> life insurance covers one person, paying out a cash lump sum if they die.<br /><br /><strong>Joint</strong> life insurance covers two people on one policy and only means paying one monthly premium. If one of you dies during the policy term, the policy will no longer exist for the surviving partner.',
  },
  existingMortgage: {
    options: ['Yes', 'No'],
    icons: ['has-mortgage', 'no-mortgage'],
    values: ['yes', 'no'],
    heading: 'Do you currently have a mortgage or an accepted offer on a property?',
    tooltip: 'What does this mean?',
    tooltipText: 'An <strong>accepted offer</strong> means you know roughly when you’re expecting to move in, and how much you’ll be borrowing to buy the property',
  },
}

const CalculatorQuestions = [
  {
    heading: 'How much is left to pay on your mortgage?',
    text: 'If you’re not sure on the exact amount, use the amount last time you remortgaged or purchased your home.',
    field: 'outstandingMortgage',
  },
  {
    heading: 'How much in outstanding loans or credit agreements do you have?',
    // eslint-disable-next-line react/no-unescaped-entities
    text: (<>This would include any personal loans or credit agreements you have for things like a car, electronics or household items. It <strong>doesn't</strong> include UK student loans.</>),
    field: 'outstandingDebts',
  },
  {
    heading: 'How much would you like to leave as a lump sum to a partner?',
    text: 'This could be to cover other household expenses, such as utility bills and rent.',
    field: 'partnerLumpsum',
  },
  {
    heading: 'How much would you like to leave as a lump sum to your children?',
    text: null,
    field: 'childrenLumpsum',
  },
  {
    heading: 'How much existing life insurance cover do you have in place?',
    text: null,
    field: 'existingCover',
  },
]

const PolarContent = ({ name }) => ({
  coverfor: {
    heading: 'Who would you like to cover?',
    iconL: 'single',
    iconLCopy: 'Just Me',
    iconR: 'joint',
    iconRCopy: 'Me and my partner',
    footerInfo: (<>We will use your data for the purpose of providing your quote which may include sharing data with our insurers. Our <a target="_blank" rel="noopener noreferrer" href="https://www.beaglestreet.com/privacy-policy/" tabIndex="0">Privacy Policy</a> provides information about how your data is used and stored, and who we share information with as well as information about your rights.</>),
  },
  gender: {
    heading: (<>Hi <b className={joinArrayWithSpace(sessioncamClassList)}>{name}</b>, what is your gender?</>),
    iconL: 'male',
    iconLCopy: 'Male',
    iconR: 'female',
    iconRCopy: 'Female',
  },
  smoker: {
    heading: 'Have you used any tobacco products or nicotine replacement products (including e-cigarettes) in the last 12 months?',
    iconL: 'smoker',
    iconLCopy: 'Yes',
    iconR: 'non-smoker',
    iconRCopy: 'No',
  },
  jointgender: {
    heading: (<>What is <b className={joinArrayWithSpace(sessioncamClassList)}>{`${name}'s`}</b> gender?</>),
    iconL: 'male',
    iconLCopy: 'Male',
    iconR: 'female',
    iconRCopy: 'Female',
  },
  jointsmoker: {
    heading: 'Has your partner used any tobacco products or nicotine replacement products (including e-cigarettes) in the last 12 months?',
    iconL: 'smoker',
    iconLCopy: 'Yes',
    iconR: 'non-smoker',
    iconRCopy: 'No',
  },
  criticalillness: {
    heading: (<>Would you like to include {isMobile && (<br />)} Critical Illness cover?</>),
    subheading: 'You can edit your amount in your quote summary',
    iconL: 'ci',
    iconLCopy: 'Yes',
    iconR: 'noci',
    iconRCopy: 'No',
    linkText: 'What is Critical Illness cover?',
    infoPanelComponent: 'CriticalIllnessInfoPanel',
  },
  childcriticalillness: {
    heading: (<>Would you like to include {isMobile && (<br />)}<b>Child</b> Critical Illness cover?</>),
    subheading: (<>This covers current and future children {isMobile && (<br />)} against critical illnesses</>),
    iconL: 'cci',
    iconLCopy: 'Yes',
    iconR: 'nocci',
    iconRCopy: 'No',
    linkText: 'What is Child Critical Illness cover?',
    infoPanelComponent: 'ChildCriticalIllnessInfoPanel',
  },
  covertype: {
    heading: 'What type of cover?',
    iconL: 'level',
    iconLCopy: 'Level Term',
    iconR: 'decreasing',
    iconRCopy: 'Decreasing Term',
    linkText: 'What\'s right for me?',
    infoPanelComponent: 'CoverTypeInfoPanel',
  },
  yourneeds: {
    heading: 'Which option best describes you?',
    iconL: 'popular',
    iconLCopy: 'I know what cover I need',
    iconR: 'calculator',
    iconRCopy: 'I need help deciding my cover',
  },
})

const QuoteSummary = {
  heading: 'Your Quote',
  subHeading: 'per month',
  timeCovered: 'Only 10 minutes away from being covered!',
  quoteValid: 'Quote valid until:',
  lossAversion: 'This price is based on a few assumptions. Continuing with your application could help reduce your price.',
}

const Address = {
  helpText: {
    title: 'Why do we ask this?',
    tip: 'Currently Beagle Street only provides cover for residents of the UK.',
  },
}

const DateOfBirth = {
  heading: 'What is your date of birth?',
  headingJoint: 'What is your partner\'s date of birth?',
  subHeading: 'DD-MM-YYYY',
  errorMsg: {
    invalidDate: 'Please enter a valid date',
    ageRange: 'We can only offer cover if you are between 18 and 64 years old',
    incomplete: 'All fields must be completed',
    invalidDay: 'Invalid number of days for that month',
  },
}

const CriticalIllnessInfoPanelContent = {
  list: [
    'Cancer',
    'Kidney Failure',
    'Heart Attack',
    'Blindness & Deafness',
    'Stroke',
    'Multiple Sclerosis',
    'Parkinson\'s Disease',
    'Paralysis',
  ],
  what: {
    title: 'What is Critical Illness Cover?',
    body: 'With Critical Illness Cover, you can add a one-off lump sum that could be paid out if you develop a serious condition. If you develop a Critical Illness, you might find that you’re unable to work or have increased living costs for treatments, care or equipment.',
  },
  whatcci: {
    title: 'What is Child Critical Illness Cover?',
    body: <>Child Critical Illness cover means that if your child becomes ill with a condition listed by us, then we will pay a lump sum to you to help support your family during that time. This amount is calculated depending on the level of critical illness cover you have taken out for yourself with your life insurance policy.
      <br /> <br />
      Contact one of our expert team on <b><a style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }} href={`tel:${phoneNumberWithoutSpaces}`} id="contactNumber">{phoneNumberFormatted}</a>.</b> Unfortunately, we cannot offer advice but can chat through the options to help you choose cover that’s right for you.
    </>,
  },
  quote: 'Our cover protects you from over 98% of the conditions which are claimed for',
  which: {
    title: 'Which critical illnesses are covered?',
    body: 'We cover a wide range of specific critical illness including',
    footer: (<p>The full definitions of illnesses covered, limitations and the circumstances you can claim are listed in the <a target="_blank" rel="noopener noreferrer" href={policyTermsAndConditionsLink}>Policy Terms and Conditions.</a></p>),
  },
}

const ChildCriticalIllnessInfoPanelContent = {
  what: {
    title: 'What is Child Critical Illness Cover?',
    body: 'Child Critical Illness cover means that if your child becomes ill with a condition listed by us, then we will pay a lump sum to you to help support your family during that time. This amount is calculated depending on the level of critical illness cover you have taken out for yourself with your life insurance policy.',
  },
}

const PolicyTypeInfoPanelContent = {
  list: [
    'Cancer',
    'Kidney Failure',
    'Heart Attack',
    'Blindness & Deafness',
    'Stroke',
    'Multiple Sclerosis',
    'Parkinson\'s Disease',
    'Paralysis',
  ],
  what: {
    title: 'What is Critical Illness Cover?',
    body: 'With Critical Illness Cover, you can add a one-off lump sum that could be paid out if you develop a serious condition. If you develop a Critical Illness, you might find that you’re unable to work or have increased living costs for treatments, care or equipment.',
  },
  whatCCI: {
    title: 'What is Child Critical Illness cover?',
    body: 'Child Critical Illness cover means that if your child becomes ill with a condition listed by us, then we will pay a lump sum to you to help support your family during that time. This amount is calculated depending on the level of critical illness cover you have taken out for yourself with your life insurance policy.',
  },
  quote: 'Our cover protects you from over 98% of the conditions which are claimed for',
  which: {
    title: 'Which critical illnesses are covered?',
    body: 'We cover a wide range of specific critical illness including',
    footer: (<p>The full definitions of illnesses covered, limitations and the circumstances you can claim are listed in the <a target="_blank" rel="noopener noreferrer" href="https://cdn.bgllife.co.uk/beaglestreet/downloads/beagle-street-policy-terms-and-conditions-V9.pdf">Policy Terms and Conditions.</a></p>),
  },
}

const CoverTypeInfoPanelContent = {
  level: {
    heading: 'Level Term',
    items: [
      'The amount of money paid out if you die remains the same for the duration of your policy.',
      'Your premium is the same every month, but could cost more than a Decreasing Term policy.',
      'Level Term Life Insurance could be a good choice if you want to know exactly how much your loved ones will receive if you die.',
    ],
    cardTitle: 'Cover amount over time',
  },
  decreasing: {
    heading: 'Decreasing Term',
    youtube: 'ub2ojozaJMw',
    items: [
      'The amount of money paid out if you die reduces over the duration of the policy.',
      'Your premium is the same every month, but might cost less than a Level Term policy.',
      'Decreasing Term Life Insurance could be a good choice if you’re taking out cover for a major outgoing such as a mortgage. As the amount you owe on a mortgage reduces over time, you’re likely to need less cover.',
    ],
    cardTitle: 'Level Term vs Decreasing Term',
  },
  footer: 'We model our Decreasing Term policy on an interest charge of 6%. If your mortgage interest rate is more than 6% and you make a claim, the pay-out might not be large enough to cover any outstanding mortgage debt you may have planned to clear.',
}

const ContactContent = (email, post, phone, text) => {
  let footerText = 'When you choose to click continue, you agree to Beagle Street or our partners, Reassured, using your phone number to contact you about your quote.'
  let optIn = 'Want to keep informed on our latest offers, products and information? Your details will be kept safe and secure, and will not be shared with anyone else. You can opt-out at anytime. Please contact me by:'

  const brandName = brandNameInLowerCase()

  if (brandName === 'budget') {
    footerText = 'When you choose to click continue, you agree to Budget Life Insurance or our partners, Reassured, using your phone number to contact you about your quote.'
  }
  
  if( brandName === "beaglestreet"){
    optIn = "Want to keep informed on our latest offers, products and information? Your details will be kept safe and secure, and will not be shared with anyone else.  If there are some ways you don’t want to receive communication from us, please tick the boxes below:"
  }
  
  if(brandName === "virginmoney"){
    optIn = "Want to keep informed on our latest offers, products and information? Your details will be kept safe and secure, and will not be shared with anyone else.  If there are some ways you don’t want to receive communication from us, please tick the boxes below:"
    footerText = 'When you choose to click continue, you agree to Virgin Money or our partners, Reassured, using your phone number to contact you about your quote.'
  }

  const content = {
    title: 'What are your contact details?',
    label: 'Let\'s keep in touch',
    optIn,
    footer: (<>{footerText} We will also use your email address (anonymised to protect your identity) to help us to target our advertising on social media and other websites so we show customers adverts which may be more relevant to them. Please see our <a target="_blank" rel="noopener noreferrer" href={privacyLink} tabIndex="0">Privacy Policy</a> for more information and details of how to opt out.</>),
    fields: [
      { title: 'Email', value: 'emailMarketing', checked: email },
      { title: 'Post', value: 'postMarketing', checked: post },
      { title: 'Phone', value: 'phoneMarketing', checked: phone },
      { title: 'Text', value: 'textMarketing', checked: text },
    ],
  }
  return { ...content }
}

const CoverAmountContent = {
  heading: 'How much cover would you like?',
  splitTestHeading: (<>How much life cover would you like?</>),
  accordionData: [
    {
      title: 'Mortgage',
      icon: 'home',
      content: 'You could consider an amount that could pay off your mortgage or other debts in the event of your death.',
    },
    {
      title: 'Partner',
      icon: 'partner',
      content: 'The lump sum could help your partner to cover day-to-day living costs, especially if they rely on you for a regular income.',
    },
    {
      title: 'Children',
      icon: 'users',
      content: 'Consider the age of your children and how much it could cost to raise them to 18. You might also think about costs like tuition fees or leaving them an inheritance if you were to die.',
    },
    {
      title: 'Lifestyle',
      icon: 'lifestyle',
      content: 'Think about the amount you’d like to leave behind so your loved ones could maintain a comfortable standard of living.',
    },
  ],

  accordionText: {
    heading: ['How much cover is right for me?', 'Financial commitments to consider'],
    content: <>Lots of things could influence how much cover you need, all depending on your personal circumstances. Consider the financial commitments you would want to pay off or loved ones you would like to help in the event of your death. It may be wise to think about your financial commitments right now, but also how they could change in the future.
      <br /> <br />
      Contact one of our expert team on <b><a style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }} href={`tel:${phoneNumberWithoutSpaces}`} id="contactNumber">{phoneNumberFormatted}</a>.</b> Unfortunately, we cannot offer advice but can chat through the options to help you choose cover that’s right for you.
    </>,
  },
}

const LengthCopy = (min, max) => ({
  heading: (<>How long do you want {isMobile && (<br />)} cover for?</>),
  subheading: `Because of your age(s) you can choose between ${min} and ${max} years.`,
  linkText: 'How long do I need cover for?',
})

const LengthInfoPanelContent = {
  heading: 'How long do I need cover for?',
  content: <>When deciding on how long you would like cover to last, it is worth considering the original motives for taking out Life Insurance, as this is likely to have influenced your decision. For example, if you have a mortgage which is 25 years in term, then perhaps a 25 year Life Insurance policy could be right for you.
    <br /> <br />
    Here at {fullBrandName}, we can cover you for a policy up to 40 years in term, or up until you reach the age of 79.
    <br /> <br />
    Contact one of our expert team on <b><a style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }} href={`tel:${phoneNumberWithoutSpaces}`} id="contactNumber">{phoneNumberFormatted}</a>.</b> Unfortunately, we cannot offer advice but can chat through the options to help you choose cover that’s right for you.
  </>,
}

const AddressContent = {
  helpTextTitle: 'Why do we ask this?',
  helptextCopy: 'Currently Beagle Street only provides cover for residents of the UK.',
}

const DataLayerContent = {
  dataLayerPageArray: ['name', 'gender', 'smoker', 'dob'],
  personPageArray: ['name', 'gender', 'smoker', 'dob'],
  protect: {
    url: 'protect',
    title: 'Protect',
  },
  payoutType: {
    url: 'payout-type',
    title: 'Payout Type',
  },
  who: {
    url: 'who',
    title: 'Who',
  },
  name: {
    url: 'name',
    title: 'Name',
  },
  partner: {
    url: 'partner',
    title: 'Partner',
  },
  gender: {
    url: 'gender',
    title: 'Gender',
  },
  dob: {
    url: 'dob',
    title: 'dob',
  },
  smoker: {
    url: 'smoking',
    title: 'Smoking',
  },
  type: {
    url: 'covertype',
    title: 'Cover Type',
  },
  ci: {
    url: 'criticalillness',
    title: 'Critical Illness',
  },
  cci: {
    url: 'childcriticalillness',
    title: 'Child Critical Illness',
  },
  popular: {
    url: 'coveramount',
    title: 'Cover Amount',
  },
  other: {
    url: 'coveramountmanual',
    title: 'Cover Amount Manual',
  },
  calculator: {
    url: 'coveramountcalculator',
    title: 'Cover Calculator Page',
  },
  term: {
    url: 'coverlength',
    title: 'Cover Length',
  },
  contact: {
    url: 'contactdetails ',
    title: 'Contact Details',
  },
  quote: {
    url: 'indicativequote ',
    title: 'Indicative Quote',
  },
  find: {
    url: 'addresslookup',
    title: 'Address Lookup',
  },
  manual: {
    url: 'addressmanual',
    title: 'Address Manual',
  },
  choose: {
    url: 'addressselect',
    title: 'Address Select',
  },
  manualB: {
    url: 'addressmanual',
    title: 'Address Manual',
  },
  confirm: {
    url: 'addressconfirmation',
    title: 'Address Confirmation',
  },
  // guidance
  children: {
    url: 'children',
    title: 'Children',
  },
  mortgage: {
    url: 'mortgage',
    title: 'Mortgage',
  },
  bills: {
    url: 'bills',
    title: 'Bills',
  },
  debts: {
    url: 'debts',
    title: 'Debts',
  },
  savings: {
    url: 'savings',
    title: 'Savings',
  },
  needs: {
    url: 'your-needs',
    title: 'Your needs',
  },
  summary: {
    url: 'summary',
    title: 'Summary'
  }
}

const PolicyTypeContent = [
  {
    title: 'Life cover only',
    list: ['Payout upon death claim'],
  },
  {
    title: 'Life & Critical Illness cover',
    list: ['Payout upon death claim', 'Payout for cancer, heart attacks, strokes and other conditions'],
  },
  {
    title: 'Life, Critical Illness & Child cover',
    list: ['Payout upon death claim', 'Payout for cancer, heart attacks, strokes and other conditions', (<>Cover current <span style={{ fontStyle: 'italic' }}>and</span> future children against critical illnesses</>)],
  },
]

const DropDownMessageBoxCopy = {
  messageHeader: 'Cover your children, too',
  messageBody: (<>You can add extra protection to your Critical Illness policy at the next step, to cover current <span style={{ fontStyle: 'italic' }}>and</span> future children.</>),
}

const IncentiveLink = 'http://www.beaglestreet.com/campaigns/terms/100-amazon-gift-card-terms-and-conditions-2020/'

const IncentiveBannerCopy = {
  text: (<>You could get an Amazon.co.uk <b>Gift Card worth up to £100</b> (T&amp;C’s apply*)</>),
  image: 'https://cdn.bgllife.co.uk/beaglestreet/images/amazon-gift-card-no-circle.jpg',
  terms: (<>*Minimum premium £10 and gift card value varies depending on monthly premium. You can claim this gift after your 6th monthly payment. Full <a target="_blank" rel="noopener noreferrer" href={IncentiveLink} tabIndex="0">Terms and Conditions</a></>),
}

const disclaimerText = ' We may carry out checks with external databases including credit reference agencies as part of this process for fraud prevention, to verify the information you provide and to assist us in providing the quote. If you have previously obtained a quote in the past, we may carry out checks against the data we already hold on you.'

const answerPageCopy = (address, mortgage, bills, debts, savings, name, dob, contact, gender, who, smoker, children) => {
  let financesData = {}
  const hasMortgage = address.existingMortgage === 'yes'
  const youSectionHeading = who.value === 'joint' || children.value.hasChildren !== null ? 'You and your family' : 'You'

  if (hasMortgage) {
    financesData = [
      {
        headingText: 'House/number',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{address.value.Line1}</span></>),
      },
      {
        headingText: 'How much do you pay for your mortgage each month?',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>£{mortgage.value.perMonth}</span></>),
      },
      {
        headingText: 'Postcode',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{address.value.addressPostCode}</span></>),
      },
      {
        headingText: 'How long is left on your mortgage?',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{mortgage.value.yearsLeft}</span></>),
      },
      {
        headingText: 'Do you currently have a mortgage or an accepted offer on a property?',
        buttonText: (address.existingMortgage === 'yes' ? 'Yes' : 'No'),
      },
      {
        headingText: 'What type of mortgage do you have?',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{mortgage.value.polar.charAt(0).toUpperCase() + mortgage.value.polar.slice(1)}</span></>),
        className: 'noUnderlineMobile'
      },
      {
        headingText: 'How much is left to pay on your mortgage?',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>£{formatCurrency(mortgage.value.toPay)}</span></>),
        className: 'noUnderline'
      },
    ]

  } else {
    financesData = [
      {
        headingText: 'House/number',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{address.value.Line1}</span></>),
      },
      {
        headingText: 'Postcode',
        buttonText: (<><span className={sessioncamClassList.join(' ')}>{address.value.addressPostCode}</span></>),
      },
      {
        headingText: 'Do you currently have a mortgage or an accepted offer on a property?',
        buttonText: (address.existingMortgage === 'yes' ? 'Yes' : 'No'),
        className: 'noUnderline noUnderlineMobile'
      },
    ]

  }

  const expensesDebtData = [
    {
      headingText: `How much would you need to cover your essential costs each month (${address.existingMortgage === 'yes' ? 'after your mortgage payments' : 'including your rent'})?`,
      buttonText: (<><span className={sessioncamClassList.join(' ')}>£{formatCurrency(bills.value.total)}</span></>),
    },
    {
      headingText: 'Do you have any outstanding loans or credit agreements?',
      buttonText: (debts.value.hasDebt === true ? 'Yes' : 'No'),
    },
    {
      headingText: 'How much do you have saved for an emergency?',
      buttonText: (<><span className={sessioncamClassList.join(' ')}>£{formatCurrency(savings.value.total)}</span></>),
      className: 'noUnderline noUnderlineMobile',
    },
    {
      headingText: 'In total, how much do you have outstanding?',
      buttonText: (<><span className={sessioncamClassList.join(' ')}>£{formatCurrency(debts.value.outstandingDebt)}</span></>),
      className: 'noUnderline',
    },
  ]

  const data = {
    [youSectionHeading]: {
      id: 'you_and_your_family',
      iconKind: 'you-and-your-family',
      data: {
        'Your details': [
          {
            headingText: 'Title',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value.title.charAt(0).toUpperCase() + name.value.title.slice(1)}</span></>),
          },
          {
            headingText: 'First name',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value.firstName}</span></>),
          },
          {
            headingText: 'Last name',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value.lastName}</span></>),
          },
          {
            headingText: 'Date of birth',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{dob.value.dateOfbirth.split('-').join('/')}</span></>),
          },
          {
            headingText: 'Email address',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{contact.value.email}</span></>),
          },
          {
            headingText: 'Phone number',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{contact.value.phone}</span></>),
          },
          {
            headingText: 'What was your assigned sex at birth?',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{gender.value.charAt(0).toUpperCase() + gender.value.slice(1)}</span></>),
          },
          {
            headingText: 'Who would you like to cover?',
            buttonText: (who.value === 'single' ? 'Just me' : 'Me and my partner'),
          },
          {
            headingText: 'Have you used any tobacco products or nicotine replacement products (including e-cigarettes) in the last 12 months?',
            buttonText: (smoker.value === 'non-smoker' ? 'No' : 'Yes'),
            className: 'noUnderline',
          },
          {
            headingText: 'Do you have any children under the age of 18?',
            buttonText: (<><span className={sessioncamClassList.join(' ')}>{(children.value.hasChildren === null ? 'No' : 'Yes')}</span></>),
            className: 'noUnderline noUnderlineMobile',
          },
        ],
        'Your partner’s details':
          (who.value === 'joint' ?
            [
              {
                headingText: 'Title',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value2.title.charAt(0).toUpperCase() + name.value2.title.slice(1)}</span></>),
              },
              {
                headingText: 'First name',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value2.firstName}</span></>),
              },
              {
                headingText: 'Last name',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{name.value2.lastName}</span></>),
              },
              {
                headingText: 'Date of birth',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{dob.value2.dateOfbirth.split('-').join('/')}</span></>),
              },
              {
                headingText: 'What was your assigned sex at birth?',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{gender.value2.charAt(0).toUpperCase() + gender.value2.slice(1)}</span></>),
                className: 'noUnderline',
              },
              {
                headingText: 'Have you used any tobacco products or nicotine replacement products (including e-cigarettes) in the last 12 months?',
                buttonText: (smoker.value2 === 'non-smoker' ? 'No' : 'Yes'),
                className: 'noUnderline noUnderlineMobile',
              },
            ]
            : []),
        'Your children’s details':
          (children.value.hasChildren !== null ?
            [
              {
                headingText: 'How many children under the age of 18 do you have?',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{children.value.noOfChildren}</span></>),
                className: 'noUnderline mb8',
              },
              {
                headingText: 'How old is your youngest child (to the nearest year)?',
                buttonText: (<><span className={sessioncamClassList.join(' ')}>{children.value.ageOfYoungest}</span></>),
                className: 'noUnderline noUnderlineMobile mb8',
              },
            ]
            : []),
      }
    },
    'Your finances': {
      id: 'your_finances',
      iconKind: 'your-finances',
      data: {
        'Mortgage / Rent': [...financesData],
        'Expenses, debts and protection': [...expensesDebtData],
      }
    }
  }

  if (who.value !== 'joint') delete data[youSectionHeading].data['Your partner’s details']
  if (children.value.hasChildren === null) delete data[youSectionHeading].data['Your children’s details']

  return data
}

const ToolTipText = {
  coverType: <>With <b>Stay the same</b>, the amount of money paid out if you die remains the same for the duration of your policy. This is also called level cover. <br /> <br />With <b>Decrease over time</b>, the amount of money paid out if you die reduces over the duration of the policy. This is also called decreasing cover, it is often cheaper and typically used to cover a mortgage.
  </>,
  what:
    <>
      <strong>Pay out if I die</strong> – this option pays out a lump sum of cash to your loved ones in the event of your death, helping them with financial security. <br /> <br /><strong>Pay out if I&apos;m critically ill and/or die</strong> –  in addition to paying a lump sum to your loved ones in the event of your death, this option also pays out a cash lump sum if you’re diagnosed with one of {toolTipBrandName} recognised critical illness conditions.

      <br /> <br />

      Contact one of our expert team on  <b><a style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }} href={`tel:${phoneNumberWithoutSpaces}`} id="contactNumber">{phoneNumberFormatted}.</a></b>  Unfortunately, we cannot offer advice but can chat through the options to help you choose cover that’s right for you.
    </>
}

const summaryPageCollapseData = (reduxStoreFormData, loadingSelector, AmountWrapper, Button, Icon, Paragraph, Anchor, additionalAmountInfo, toggleModal) => {

  const {
    form: {
      data: {
        lifeGuidance: { maxSumAssured, minSumAssured },
        termGuidance: { maxTerm, minTerm },
        ciGuidance: { maxCI, minCI },
      },
      dob: { value: { age } },
      payoutType: { value: payoutTypeValue },
      ci: { ciAmount, value: ciValue, cciAmount, cciCovered, customEntered: ciCustomEntered },
      who: { value: policyType },
      amount: { value: liAmount, customEntered: liCustomEntered },
      mortgage: { value: { toPay, perMonth, yearsLeft } },
      bills: { value: { total: billsTotal } },
      debts: { value: { outstandingDebt } },
      children: { value: { noOfChildren, ageOfYoungest, hasChildren } },
      savings: { value: { total: savingsTotal } },
      term: { value: termAmount, customEntered: termCustomEntered },
      type: { value: policyTerm },
    }
  } = reduxStoreFormData

  // Values
  const clRetirement = 67 - Number(age)

  const values = {
    lifeInsuranceMortgage: formatCurrency(toPay),
    lifeInsuranceBills: formatCurrency(billsTotal * 60),
    lifeInsuranceBillsPerMonth: formatCurrency(billsTotal),
    lifeInsuranceDebts: formatCurrency(outstandingDebt),
    lifeInsuranceChildren: noOfChildren ? formatCurrency(noOfChildren * 10000) : 0,
    lifeInsuranceNumberOfChildren: noOfChildren || 0,
    lifeInsuranceFuneral: formatCurrency(4000),
    lifeInsuranceSavings: formatCurrency(savingsTotal),
    criticalIllnessMortgageTotal: formatCurrency(perMonth * 18),
    criticalIllnessMortgagePerMonth: formatCurrency(perMonth),
    criticalIllnessBillsTotal: formatCurrency(billsTotal * 18),
    criticalIllnessBillsPerMonth: formatCurrency(billsTotal),
    criticalIllnessSavings: formatCurrency(savingsTotal),
    coverLengthMortgageYears: yearsLeft,
    coverLengthMorgageYearsText: getSingularOrPlural(yearsLeft, 'year', 'years'),
    criticalIllnessChildren: 18 - ageOfYoungest,
    criticalIllnessChildrenYearsText: getSingularOrPlural(18 - ageOfYoungest, 'year', 'years'),
    criticalIllnessRetirement: clRetirement,
    criticalIllnessRetirementYearText: getSingularOrPlural(clRetirement, 'year', 'years'),
  }

  const lifeInsuranceExceededMax = liAmount >= maxSumAssured
  const coverLengthExceededMax = termAmount >= maxTerm
  const payoutTypeText = payoutTypeValue === 'poci' && ciValue !== 'noci' ? 'pay out if you are critically ill and/or die' : 'pay out if you die'
  const lifeInsuranceTotalAmount = `£${formatCurrency(liAmount)}${(liAmount === maxSumAssured || liAmount === minSumAssured) ? `*` : ''}`
  const criticalIllnessTotalAmount = `£${formatCurrency(ciAmount)}${(((liAmount / 100) * 10) === ciAmount) || (ciAmount <= Number(minCI)) || (ciAmount >= Number(maxCI)) || (ciAmount === Number(liAmount)) ? `*` : ''}`
  const childCriticalIllnessAmount = (cciAmount === 0 || !cciCovered) ? 'None' : `£${formatCurrency(cciAmount.toFixed(0))}`
  const coverLengthAmount = `${termAmount} year${termAmount > 1 ? 's' : ''}${termAmount <= minTerm || termAmount >= maxTerm ? '*' : ''}`
  const collapseValueLi = loadingSelector ? <><Icon className="priceSpinner" kind="loader" style={{ width: 'auto' }} size={30} /></> : lifeInsuranceTotalAmount
  const collapseValueCi = loadingSelector ? <><Icon className="priceSpinner" kind="loader" style={{ width: 'auto' }} size={30} /></> : criticalIllnessTotalAmount
  const collapseValueCCI = loadingSelector ? <><Icon className="priceSpinner" kind="loader" style={{ width: 'auto' }} size={30} /></> : childCriticalIllnessAmount
  const collapseValueLength = loadingSelector ? <><Icon className="priceSpinner" kind="loader" style={{ width: 'auto' }} size={30} /></> : coverLengthAmount

  const generateUniqueId = (prefix, postfix) => `${prefix}${postfix ? `-${postfix}` : ''}`

  const showMaxAmountText = (type) => {
    switch (type) {
      case 'lifeInsurance':
        return lifeInsuranceExceededMax && `The maximum amount of cover we can provide is £${formatCurrency(maxSumAssured)}.`
      case 'coverLength':
        return coverLengthExceededMax && `The maximum term we can cover you for is ${maxTerm} years.`
      default:
        return ''
    }
  }

  let ciIntroText = 'Your critical illness cover has been calculated based on your mortgage, bills and savings.'
  let ciPayoutExplanationText = 'This amount could pay those bills for 18 months.'
  let ciEditAmountText = (<><Button type="button" id="ciEditCoverButton" onClick={(e) => toggleModal('ciModal', false, e)}>Edit your critical illness cover</Button> to get the right cover for you.</>)
  let ciBody = (idPostFix) => (
    <>
      {toPay !== undefined &&
        <AmountWrapper>
          <Paragraph className="section-heading" id={generateUniqueId("critical-illness-mortgage-heading", idPostFix)}>Mortgage - £{values.criticalIllnessMortgageTotal}</Paragraph>
          <Paragraph className="section-content" id={generateUniqueId("critical-illness-mortgage-content", idPostFix)}>You told us that you pay £{values.criticalIllnessMortgagePerMonth} per month on your mortgage. This money could allow you to pay your mortgage for 18 months.</Paragraph>
        </AmountWrapper>
      }
      <AmountWrapper>
        <Paragraph className="section-heading" id={generateUniqueId("critical-illness-bills-heading", idPostFix)}>Bills - £{values.criticalIllnessBillsTotal}</Paragraph>
        <Paragraph className="section-content" id={generateUniqueId("critical-illness-bills-content", idPostFix)}>You told us that you spend £{values.criticalIllnessBillsPerMonth} per month on essential costs. This amount could pay those bills for 18 months.</Paragraph>
      </AmountWrapper>
      {savingsTotal !== 0 &&
        <AmountWrapper>
          <Paragraph className="section-heading" id={generateUniqueId("critical-illness-savings-heading", idPostFix)}>Savings - £{values.criticalIllnessSavings}</Paragraph>
          <Paragraph className="section-content" id={generateUniqueId("critical-illness-savings-content", idPostFix)}>You told us that you have emergency savings of £{values.criticalIllnessSavings}. This has been taken off your overall critical illness cover.</Paragraph>
        </AmountWrapper>
      }
    </>
  )

  if (ciValue === 'noci') {
    ciIntroText = (<>This will pay out in the event if you are diagnosed with a <Button type="button" id="ciHelpModalButton" onClick={(e) => toggleModal('ciHelpModal', false, e)}>covered critical illness</Button>.</>)
    ciPayoutExplanationText = null
    ciEditAmountText = null
    ciBody = () => null
  }

  let cciIntroText = (<>This will pay out in the event any current or future children are diagnosed with a <Button type="button" id="cciHelModalButton" onClick={(e) => toggleModal('cciHelpModal', false, e)}>covered critical illness</Button>. This is set at 25% of your critical illness cover.</>)
  let cciBody = () => null

  if ((ciValue === 'ci') || (ciValue === 'noci')) {
    cciIntroText = null
    cciBody = (idPostFix) => (
      <>
        <Paragraph className="collapse-intro mb-20" id={generateUniqueId("child-critical-illness-text1", idPostFix)}>
          This will pay out in the event any current or future children are diagnosed with a <Button type="button" id={generateUniqueId("cciHelpModalButton", idPostFix)} onClick={(e) => toggleModal('cciHelpModal', false, e)}>covered critical illness</Button>. This is set at 25% of your critical illness cover.
        </Paragraph>
        <Paragraph className="collapse-intro mb-0" id={generateUniqueId("child-critical-illness-text2", idPostFix)}>
          Child critical illness cover is not a standalone product.  This means that you need to add it on to your critical illness cover in order to get your children covered.
        </Paragraph>
      </>
    )
  }

  const getModalTitle = (modal) => {
    if (modal === 'criticalIllness') {
      if (ciValue === 'noci') return 'What is Critical illness cover?'
      if (ciCustomEntered) return 'Custom amount'
      if (ciValue !== 'noci' || !ciCustomEntered) return 'How has this been calculated?'
    }

    if (modal === 'childCriticalIllness') {
      if (ciValue === 'ci' || ciValue === 'noci') {
        return 'What is Child Critical illness cover?'
      }
      return 'How has this been calculated?'
    }

    if (modal === 'coverLength') {
      if (termCustomEntered) return 'Custom amount'
      return 'How has this been calculated?'
    }

    return ''
  }

  const data = {
    lifeInsurance: {
      modalContentId: 'liModal',
      modalContentHeading: 'Enter custom life insurance amount',
      title: 'Life insurance',
      amount: collapseValueLi,
      mainHeading: "How has this been calculated?",
      introText: 'We’ve used the details you’ve provided to personalise your quote.',
      payoutExplanationText: `Life insurance will ${payoutTypeText} while you have the policy.`,
      editAmountText: (<><Button type="button" id="liEditCoverButton" onClick={(e) => toggleModal('liModal', false, e)}>Edit your cover amount</Button> to get the right cover for you.</>),
      className: 'collapse-intro',
      amendValueTriggerText: 'Edit cover amount',
      showValidation: (liAmount >= maxSumAssured || liAmount <= minSumAssured),
      validationText: <><ValidationTextStyled id="lifeInsurance_validation_text">{additionalAmountInfo.lifeInsurance.infoMessage}</ValidationTextStyled></>,
      showMaxAmountText: showMaxAmountText('lifeInsurance'),
      modalName: 'moreInfoLi',
      modalTitle: liCustomEntered ? 'Custom amount' : 'How has this been calculated?',
      dlId: 'lifeinsurance',
      body: (idPostFix) => {
        return (
          <>
            {toPay !== undefined &&
              <AmountWrapper>
                <Paragraph className="section-heading" id={generateUniqueId('life-insurance-mortgage-heading', idPostFix)}>Mortgage - £{values.lifeInsuranceMortgage}</Paragraph>
                <Paragraph className="section-content" id={generateUniqueId("life-insurance-mortgage-content", idPostFix)}>You told us that you have £{values.lifeInsuranceMortgage} remaining on your mortgage. {!lifeInsuranceExceededMax && 'This amount could cover your mortgage in full.'}</Paragraph>
              </AmountWrapper>
            }

            <AmountWrapper>
              <Paragraph className="section-heading" id={generateUniqueId("life-insurance-bills-heading", idPostFix)}>Bills - £{values.lifeInsuranceBills}</Paragraph>
              <Paragraph className="section-content" id={generateUniqueId("life-insurance-bills-content", idPostFix)}>You told us that you spend £{values.lifeInsuranceBillsPerMonth} per month on essential costs. This amount could pay those bills for 5 years.</Paragraph>
            </AmountWrapper>

            {outstandingDebt !== 0 &&
              <AmountWrapper>
                <Paragraph className="section-heading" id={generateUniqueId("life-insurance-debt-heading", idPostFix)}>Debts - £{values.lifeInsuranceDebts}</Paragraph>
                <Paragraph className="section-content" id={generateUniqueId("life-insurance-debt-content", idPostFix)}>You told us that you have £{values.lifeInsuranceDebts} in outstanding debts or loans. {!lifeInsuranceExceededMax && 'This amount could pay those off in full.'}</Paragraph>
              </AmountWrapper>
            }

            {hasChildren !== null &&
              <AmountWrapper>
                <Paragraph className="section-heading" id={generateUniqueId("life-insurance-children-heading", idPostFix)}>Children - £{values.lifeInsuranceChildren}</Paragraph>
                <Paragraph className="section-content" id={generateUniqueId("life-insurance-children-content", idPostFix)}>You told us that you have {values.lifeInsuranceNumberOfChildren} {getSingularOrPlural(values.lifeInsuranceNumberOfChildren, 'child', 'children')} under the age of 18. This would leave a lump sum of £10,000 per child.</Paragraph>
              </AmountWrapper>
            }

            {savingsTotal !== 0 &&
              <AmountWrapper>
                <Paragraph className="section-heading" id={generateUniqueId("life-insurance-savings-heading", idPostFix)}>Savings - £{values.lifeInsuranceSavings}</Paragraph>
                <Paragraph className="section-content" id={generateUniqueId("life-insurance-savings-content", idPostFix)}>You told us that you have emergency savings of £{values.lifeInsuranceSavings}. This has been taken off your overall life insurance cover.</Paragraph>
              </AmountWrapper>
            }

            <AmountWrapper>
              <Paragraph className="section-heading" id={generateUniqueId("life-insurance-funeral-heading", idPostFix)}>Funeral - £{values.lifeInsuranceFuneral}</Paragraph>
              <div>
                <Paragraph className="section-content" id={generateUniqueId("life-insurance-funeral-content", idPostFix)}>This amount covers the average cost of the funeral in the UK*.</Paragraph>
                <Paragraph className="section-content-small" id={generateUniqueId("life-insurance-funeral-content-small", idPostFix)}>
                  <span>*Source:</span> <Anchor style={{ paddingTop: '10px', paddingBottom: '11px' }} href="https://www.moneyadviceservice.org.uk/en/articles/how-much-does-a-funeral-cost">Money Advice Service</Anchor>, Oct 2020
                </Paragraph>
              </div>
            </AmountWrapper>
          </>
        )
      },
    },
    criticalIllness: {
      modalContentId: 'ciModal',
      modalContentHeading: 'Critical illness cover',
      title: 'Critical illness',
      amount: (ciValue !== 'noci' ? collapseValueCi : 'None'),
      mainHeading: `${(ciValue === 'noci' ? 'What is Critical illness cover?' : 'How has this been calculated?')}`,
      introText: ciIntroText,
      payoutExplanationText: ciPayoutExplanationText,
      editAmountText: ciEditAmountText,
      headingClassName: `${(ciValue === 'noci' ? 'disabled' : '')}`,
      className: `${(ciValue === 'noci' ? 'collapse-intro mb-0' : 'collapse-intro')}`,
      amendValueTriggerText: `${(ciValue === 'noci' ? 'Add cover' : 'Edit cover amount')}`,
      body: (idPostFix) => ciBody(idPostFix),
      showValidation: (ciAmount >= maxCI || ciAmount <= minCI) && ciValue !== 'noci',
      validationText: <><ValidationTextStyled id="criticalIllness_validation_text">{additionalAmountInfo.criticalIllness.infoMessage}</ValidationTextStyled></>,
      dlId: 'criticalillness',
      modalName: 'moreInfoCi',
      modalTitle: getModalTitle('criticalIllness'),
    },
    childCriticalIllness: {
      modalContentId: 'cciModal',
      modalContentHeading: 'Child critical illness cover',
      title: 'Child critical illness',
      amount: (ciValue === 'cci' ? collapseValueCCI : 'None'),
      mainHeading: `${((ciValue === 'ci') || (ciValue === 'noci') ? 'What is Child Critical illness cover?' : 'How has this been calculated?')}`,
      introText: cciIntroText,
      headingClassName: `${((ciValue === 'ci') || (ciValue === 'noci') || !cciCovered ? 'disabled' : '')}`,
      className: 'collapse-intro-single',
      amendValueTriggerText: `${((ciValue === 'ci') || (ciValue === 'noci') ? 'Add cover' : 'Edit cover amount')}`,
      body: (idPostFix) => cciBody(idPostFix),
      hideAmendValueButton: (ciValue === 'noci'),
      dlId: 'childcriticalillness',
      modalName: 'moreInfoCci',
      modalTitle: getModalTitle('childCriticalIllness'),
    },
    coverLength: {
      modalContentId: 'clModal',
      modalContentHeading: 'Enter custom cover length',
      title: 'Cover length',
      amount: collapseValueLength,
      mainHeading: "How has this been calculated?",
      introText: toPay !== undefined ? "This is how long your policy will cover you for. It is calculated using the number of years left to pay your mortgage." : `This is how long your policy will cover you for.  It is calculated using your retirement age. Based on your date of birth, you could retire in ${values.criticalIllnessRetirement} ${values.criticalIllnessRetirementYearText}.`,
      editAmountText: (<><Button type="button" id={generateUniqueId("clEditCoverButton", 'mobile')} onClick={(e) => toggleModal('clModal', false, e)}>Edit your cover length</Button> to get the right cover for you.</>),
      className: 'collapse-intro-single',
      amendValueTriggerText: 'Edit cover length',
      showValidation: (termAmount >= maxTerm || termAmount <= minTerm),
      validationText: <><ValidationTextStyled id="coverLength_validation_text">{additionalAmountInfo.coverLength.infoMessage}</ValidationTextStyled></>,
      showMaxAmountText: showMaxAmountText('coverLength'),
      dlId: 'length',
      modalName: 'moreInfoCl',
      modalTitle: getModalTitle('coverLength'),
      body: () => { return null }
    },
    typeOfCover: {
      modalContentId: 'tocModal',
      modalContentHeading: 'Type of cover',
      title: 'Type of cover',
      amount: toTitleCase(policyTerm),
      mainHeading: "What does this mean?",
      amendValueTriggerText: 'Edit cover type',
      dlId: 'term',
      modalName: 'moreInfoToc',
      modalTitle: 'What does this mean?',
      body: (idPostFix) => {
        return (
          <>
            <Paragraph className="help-body-bold" id={generateUniqueId("type-of-cover-level-term-title", idPostFix)}>Level term</Paragraph>
            <Paragraph className="help-body" id={generateUniqueId("type-of-cover-level-term-text", idPostFix)}>The amount we will pay out and your monthly payment remain the same over the length of your policy.</Paragraph>
            <Paragraph className="help-body-bold mt-20" id={generateUniqueId("type-of-cover-decreasing-term-title", idPostFix)}>Decreasing term</Paragraph>
            <Paragraph className="help-body" id={generateUniqueId("type-of-cover-decreasing-term-text", idPostFix)}>The amount we pay out decreases over time (usually to cover a repayment mortgage). Your monthly payment remains the same over the length of your policy. This is usually cheaper than level term.</Paragraph>
            <Paragraph className="help-body mt-20" id={generateUniqueId("type-of-cover-edit-text", idPostFix)}><Button type="button" id={generateUniqueId("tocEditCoverButton", idPostFix)} onClick={(e) => toggleModal('tocModal', false, e)}>Edit your type of cover</Button> to get the right cover for you.</Paragraph>
          </>
        )
      },
    },
    policyType: {
      title: 'Policy type',
      amount: toTitleCase(policyType),
      mainHeading: "What does this mean?",
      dlId: 'policytype',
      modalName: 'moreInfoPt',
      modalTitle: 'What does this mean?',
      body: (idPostFix) => {
        return (
          <>
            <Paragraph className="help-body-bold" id={generateUniqueId("policy-type-collapse-body-title", idPostFix)}>{toTitleCase(policyType)} policy</Paragraph>
            {policyType !== 'joint' ?
              <Paragraph className="help-body" id={generateUniqueId("policy-type-collapse-body-text1", idPostFix)}>You told us you wouldn’t like to add a partner to the policy.</Paragraph> :
              <Paragraph className="help-body" id={generateUniqueId("policy-type-collapse-body-text1", idPostFix)}>You told us you would like to add your partner to the policy.</Paragraph>
            }
            {policyType !== 'joint' ?
              <Paragraph className="help-body mt-20" id={generateUniqueId("policy-type-collapse-body-text2", idPostFix)}>Single policy life insurance covers one person, paying out a cash lump sum if they die.</Paragraph> :
              <Paragraph className="help-body mt-20" id={generateUniqueId("policy-type-collapse-body-text2", idPostFix)}>Joint life insurance covers two people on one policy and only means paying one monthly premium. If one of you dies during the policy term, the policy will no longer exist for the surviving partner.</Paragraph>
            }
          </>
        )
      },
    }
  }

  return data
}

const TrustPilotReviews = [
  {
    id: 1,
    review: 'Very helpful customer service. We took out life cover after being with another insurer for 13 years and saved a fortune! Also offered a free will service. Fantastic!',
    img: <><LargeIcon kind="trust-pilot" size="110" /></>,
    name: 'Sarah Gladstone',
    company: 'Beagle street customer',
  },
  {
    id: 2,
    review: 'The website was very easy to use. I got a call a few days after signing up to check details and if I needed anything else. Excellent customer service.',
    img: <><LargeIcon kind="trust-pilot" size="110" /></>,
    name: 'Karen',
    company: 'Beagle street customer',
  },
  {
    id: 3,
    review: 'It\'s a great experience to buy a policy, very easy and user friendly website with clear information. Definitely recommended...',
    img: <><LargeIcon kind="trust-pilot" size="110" /></>,
    name: 'Ravindra Pathak',
    company: 'Beagle street customer',
  },
  {
    id: 4,
    review: 'Good website. My account was easy to use and set up. Called customer service today. It was quick simple and easy and was sorted in minutes.',
    img: <><LargeIcon kind="trust-pilot" size="110" /></>,
    name: 'Lee white',
    company: 'Beagle street customer',
  },
]

export {
  ProtectCopy,
  PolarContent,
  QuoteSummary,
  Address,
  DateOfBirth,
  CriticalIllnessInfoPanelContent,
  ChildCriticalIllnessInfoPanelContent,
  CoverTypeInfoPanelContent,
  ContactContent,
  CoverAmountContent,
  LengthCopy,
  LengthInfoPanelContent,
  AddressContent,
  DataLayerContent,
  PolicyTypeContent,
  PolicyTypeInfoPanelContent,
  DropDownMessageBoxCopy,
  IncentiveBannerCopy,
  PolarQuestions,
  CalculatorQuestions,
  disclaimerText,
  ToolTipText,
  answerPageCopy,
  summaryPageCollapseData,
  TrustPilotReviews,
}
