import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import AnimateSwitch from 'components/AnimateSwitch'
import Focus from 'containers/HOC/Focus'
import Reset from 'containers/HOC/ResetStore'

import DynamicContainerNew from 'containers/DynamicContainer'
import QuoteNew from 'containers/Quote'
import Error404 from 'components/Error404'
import Error500 from 'components/Error500'

const Router = ({ DefaultRoute, Routes }) => {
  //  Prevent the router from loading an error page on page load if routes prop
  //  is empty.
  if (Routes.length === 0) return null

  return (
    <AnimateSwitch defaultRoute={DefaultRoute}>
      {Routes.map(r => (
        <Route key={r} path={r} component={(Focus(DynamicContainerNew))} />
      ))}
      <Route path="/indicative-quote" component={QuoteNew} />
      <Route path="/summary" component={QuoteNew} />
      {/* <Route path="/answers" component={Answers} /> */}
      <Route path="/error500" component={Focus(Reset(Error500))} />
      <Route component={Focus(Reset(Error404))} />
    </AnimateSwitch>
  )
}

Router.propTypes = ({
  DefaultRoute: PropTypes.string,
  Routes: PropTypes.array,
})

export default Router
