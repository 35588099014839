const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS'
const UPDATE_PAGE_ANALYTICS = 'UPDATE_PAGE_ANALYTICS'
const UPDATE_EDIT_ANALYTICS = 'UPDATE_EDIT_ANALYTICS'
const UPDATE_STORE_ANALYTICS = 'UPDATE_STORE_ANALYTICS'
const UPDATE_ANALYTICS_AGE = 'UPDATE_ANALYTICS_AGE'
const UPDATE_ANALYTICS_SMOKER = 'UPDATE_ANALYTICS_SMOKER'
const UPDATE_ANALYTICS_SUCCESS = 'UPDATE_ANALYTICS_SUCCESS'
const UPDATE_ANALYTICS_ERROR = 'UPDATE_ANALYTICS_ERROR'
const UPDATE_PRICE_EDIT_ANALYTICS = 'UPDATE_PRICE_EDIT_ANALYTICS'

const updateAnalyics = (payload) => ({
  type: UPDATE_ANALYTICS,
  payload,
})

const updatePageAnalyics = (payload) => ({
  type: UPDATE_PAGE_ANALYTICS,
  payload,
})


const updateEditAnalyics = (payload) => ({
  type: UPDATE_EDIT_ANALYTICS,
  payload,
})

const updateErrorAnalytics = (payload) => ({
  type: UPDATE_ANALYTICS_ERROR,
  payload,
})

const updateStoreAnalytics = (payload) => ({
  type: UPDATE_STORE_ANALYTICS,
  payload
})

const updatePriceEditAnalytics = (payload) => ({
  type: UPDATE_PRICE_EDIT_ANALYTICS,
  payload
})

export {
  updatePageAnalyics,
  updateAnalyics,
  updateEditAnalyics,
  updateErrorAnalytics,
  updateStoreAnalytics,
  updatePriceEditAnalytics,
  UPDATE_ANALYTICS,
  UPDATE_ANALYTICS_SUCCESS,
  UPDATE_ANALYTICS_ERROR,
  UPDATE_ANALYTICS_AGE,
  UPDATE_ANALYTICS_SMOKER,
  UPDATE_PAGE_ANALYTICS,
  UPDATE_EDIT_ANALYTICS,
  UPDATE_STORE_ANALYTICS,
  UPDATE_PRICE_EDIT_ANALYTICS,
}
