import { CALL_API } from '../middleware/callAPIMiddleware'
import API_MAP from '../services/endpoint'

const ADDRESS_REQUEST = 'ADDRESS_REQUEST'
const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS'
const ADDRESS_ERROR = 'ADDRESS_ERROR'

const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
const CHANGE_ADDRESS = 'CHANGE_ADDRESS'
const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS'
const GET_ADDRESS = 'GET_ADDRESS'
const RESET_ADDRESS = 'RESET_ADDRESS'

const FIND_ADDRESS = 'FIND_ADDRESS'
const MANUAL_ADDRESS = 'MANUAL_ADDRESS'
const MANUAL_ADDRESS_SUCCESS = 'MANUAL_ADDRESS_SUCCESS'
const CHOOSE_ADDRESS = 'CHOOSE_ADDRESS'
const CONFIRM_ADDRESS = 'CONFIRM_ADDRESS'

const trimStrimOrretunEmptyString = (str) => str ? str.replace(/ /g, '').toLowerCase() : ''

export const getAddress = (address) => ({
  [CALL_API]: {
    url: `${API_MAP.address}/${address.addressPostCode.trim()}/${trimStrimOrretunEmptyString(address.addressNo)}`,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      'x-correlation-id': address.uniqueNumber,
      'Content-Type': 'application/json',
    },
    types: [ADDRESS_REQUEST, ADDRESS_SUCCESS, ADDRESS_ERROR],
    address,
  },
  type: ADDRESS_REQUEST,
  address,
})

export const resetAddress = () => ({
  type: 'RESET_ADDRESS',
})

export const changeAddressScreen = (payload) => ({
  type: 'CHANGE_ADDRESS_SUCCESS',
  payload,
})

export const updateManualAddress = (payload) => {
  return ({
    type: 'MANUAL_ADDRESS_SUCCESS',
    payload,
  })
}


export {
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  ADDRESS_ERROR,
  UPDATE_ADDRESS,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  GET_ADDRESS,
  RESET_ADDRESS,
  FIND_ADDRESS,
  MANUAL_ADDRESS,
  MANUAL_ADDRESS_SUCCESS,
  CHOOSE_ADDRESS,
  CONFIRM_ADDRESS,
}
