import axios from 'axios'
import {
  toRequestDate,
  isEmptyArray,
  removeEmptyOrFalseValues,
  reverseDate,
  removeEmptyObject,
  childCIValue,
  getCampaignCode,
  invertBooleanBSVM,
} from 'common/utils'
import API_MAP from 'services/endpoint'
import offlineGuidance from './offlineGuindance'

export const FORM_SUBMIT = '@@FORM_SUBMIT'

const dataLayer = window.dataLayer || []

// Format multiple types into one type
function formatActionTypes(types) {
  let [requestType, successType, errorType] = types

  if (typeof requestType === 'string' || typeof requestType === 'symbol') {
    requestType = { type: requestType }
  }
  if (typeof successType === 'string' || typeof successType === 'symbol') {
    successType = { type: successType }
  }
  if (typeof errorType === 'string' || typeof errorType === 'symbol') {
    errorType = { type: errorType }
  }

  return [requestType, successType, errorType]
}

export const formSubmit = ({ dispatch, getState }) => next => action => {
  if (!Object.prototype.hasOwnProperty.call(action, FORM_SUBMIT)) {
    return next(action)
  }
  const { form, initialise, businessRules } = getState()
  const {
    who,
    name,
    term,
    contact,
    type,
    amount,
    smoker,
    gender,
    dob,
    ci,
    address,
  } = form

  const { types } = action[FORM_SUBMIT]
  const [requestType, successType, errorType] = formatActionTypes(types)
  dispatch(requestType) // Request


  const peopleObj = [
    {
      ...name.value,
      isSmoker: smoker.value === 'smoker',
      gender: gender.value,
      dateOfBirth: reverseDate(dob.value.dateOfbirth),
      emailAddress: contact.value.email,
      telephoneNumber: contact.value.phone,
    },
    (who.value !== 'single' ? {
      ...name.value2,
      isSmoker: smoker.value2 === 'smoker',
      dateOfBirth: reverseDate(dob.value2.dateOfbirth),
      gender: gender.value2,
      emailAddress: contact.value.email,
      telephoneNumber: contact.value.phone,
    } : {}),
  ]

  const productsObj = [
    {
      code: type.value === 'level' ? 1 : 2,
      sumAssured: parseInt(amount.value, 0),
    },
    (ci.value === 'ci' ? {
      code: 8,
      sumAssured: parseInt(ci.ciAmount, 0),
    } : {}),
    (ci.value === 'cci' ? {
      code: 7,
      sumAssured: parseInt(ci.ciAmount, 0),
    } : {}),
  ]

  const {
    addressPostCode,
    Line1,
    Line2,
    Line3,
    Line4,
    Line5,
    Line6,
    TownCity,
  } = address.value


  let AddressObj
  const obj = {
    addressLine1: Line1,
    addressLine2: Line2,
    addressLine3: Line3,
    addressLine4: Line4,
    addressLine5: Line5,
    addressLine6: Line6,
    TownCity,
    postcode: addressPostCode,

  }

  Object.assign(obj, AddressObj)
  const tempObject = {}
  const newObj = (Object.keys(obj).filter(k => obj[k] !== null && obj[k] !== undefined && obj[k] !== '').reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {}))

  Object.keys(newObj).filter(k => k !== 'postcode').map((k, i) => (Object.assign(tempObject, { [`addressLine${i + 1}`]: newObj[k] }, {})))

  const { amounts: { childCiPercentage, childCiMaximumAmount } } = businessRules

  const bodyObject = {
    people: removeEmptyOrFalseValues(peopleObj),
    ChildCiSumAssured: ci.value === 'cci' ? childCIValue(ci.ciAmount, childCiPercentage, childCiMaximumAmount) : 0,
    products: removeEmptyObject(productsObj),
    coverTerm: term.value,
    campaignCode: initialise.campaignCode || getCampaignCode(),
    effectivedate: toRequestDate(new Date()),
    address: {
      ...tempObject,
      postcode: newObj.postcode,
    },
    marketingPreferences: {
      email: invertBooleanBSVM(contact.value.emailMarketing || false),
      telephone: invertBooleanBSVM(contact.value.phoneMarketing || false),
      post: invertBooleanBSVM(contact.value.postMarketing || false),
      sms: invertBooleanBSVM(contact.value.textMarketing || false),
    },
  }

  return axios.post(API_MAP.getQuoteUrl, bodyObject, {
    headers: {
      'x-correlation-id': initialise.uniqueNumber,
    },
  }).then(({ status, data }) => {
    if (status === 200 || status === 304) {
      if (!isEmptyArray(data)) {
        dispatch({ ...successType, payload: data }) // Success
      } else {
        dispatch({ ...errorType, payload: data }) // Error
      }
    } else {
      dispatch({ ...errorType, payload: data }) // Error
    }

    if ((data.price === 0 || data.price === '0.00' || data.price === '0.0') && data.price !== 'An error occured') {
      dataLayer.push({ event: 'quoteCompleteInitialNoPrice', NoPrice: 'Price not greater than 0' })
    }

    offlineGuidance({
      who,
      name,
      dob,
      campaingCode: initialise.campaignCode,
      quoteID: initialise.uniqueNumber,
      quoteReference: data.url,
      type: type.value,
      amount: amount.value,
      term: term.value,
      contact,
      price: data.price,
      requestSent: form?.called?.value,
      dispatch
    })
  }).catch(error => {
    const data = 'An error occurred'
    dispatch({ ...errorType, payload: data, error }) // Error
    // Log datalayer error for IQ page
    if (window.location.pathname === '/indicative-quote') {
      dataLayer.push({ event: 'quoteCompleteInitialNoPrice', NoPrice: 'An error occured within the service call' })
    }
  })
}
