import styled, { css } from 'styled-components'
import { container, query } from 'theme'
import Icon from 'components/Icon'

const Header = styled.header`
  width: 100%;
  color: ${props => props.theme.components.header.color};
  background: ${props => props.theme.components.header.background};
  border-bottom: ${props => props.theme.components.header.borderBottom};

  ${query.combine(['extraSmall', 'small'], `
       text-align: center;
  `)}
  > div {
    ${container}
    padding: 10px 0;
    ${query.combine(['extraSmall'], `
      padding: 0
    `)}
  }

  .top_left_container {
    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .phone-icon {
    margin-right: 5px;
    position: relative;
    top: -2px;
    ${query.combine(['extraSmall'], `
      display: none;
    `)}
    @media screen and (max-width: 356px) {
      display: block;
      width: 15px;
      height: 15px;
      position: relative;
      top: -4px;
      margin-left: 15px;
    }
  }

  .main-phone-link {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: ${props => props.theme.components.header.helpLinkColor};
    .help, .open-until {
      font-size: 12px;
    }
    .help {
      padding-right: 8px;
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    @media only screen and (max-width: 991px) {
      display: block;
      line-height: 1.1;
      text-align: center;
      margin-top: -3px;
      .open-until {
        display: block;
        padding-left: 0;
        font-size: 9px;
      }
      .help {
        padding-left: 0;
      }
    }

    &.open {
      // move number down to align middle
      @media screen and (max-width: 767px) {
        margin-top: 3px;
      }
    }

  }

  span {
    padding-left: 8px;
    ${query.combine(['extraSmall', 'small'], `
      font-size: 16px;
      padding-bottom: 10px;
      padding-left: 0;
  `)}
  }

  .phone-number {
    padding-left: 0;
    padding-bottom: 0;
    position: relative;
    top: -1px;
    ${query.combine(['medium'], `
      font-size: 14px;
    `)}
    ${query.combine(['extraSmall'], `
      font-size: 12px;
    `)}
    &.closed {
      ${query.combine(['extraSmall', 'small'], `
        display: none;
      `)}
    }
  }

  .top_button_container {

    display: flex;
    align-items: center;
    justify-content: end;

    @media only screen and (max-width: 767px) {
        padding-left: 0;
    }

    .button_group {

      position: relative;
      margin: 0 15px;

      &:last-child {
        margin-right: 0;
      }

      &.callback_container {
        &.open {
          display: block;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
        &.closed {
          display: block;
        }
      }

      @media only screen and (max-width: 767px) {
        margin: 0 10px;
      }

      @media only screen and (max-width: 575px) {
        margin: 0 14px;
      }
      @media only screen and (max-width: 535px) {
        margin: 0 12px;
      }

      &:after {
        width: 1px;
        height: 60%;
        background: ${props => props.theme.components.header.seperatorColor};
        content: '';
        position: absolute;
        right: -15px;
        top: 5px;
        @media only screen and (max-width: 767px) {

          height: 60%;
          top: 4px;
          right: -10px;

        }
        @media only screen and (max-width: 575px) {
          display: none;
        }
      }

      &:last-child {
        &:after {
          display: none;
          margin-right: 0;
        }
      }

      svg {
        @media only screen and (max-width: 767px) {
          margin-right: 0;
          height: 20px;
        }
      }

      .header-icon-label {
        padding-bottom: 0;
        padding-left: 0;

        @media only screen and (max-width: 767px) {
          vertical-align: middle;
          font-size: 12px;
          text-align: center;
          line-height: 1.1;
          position: relative;
          top: -3px;
          position: relative;
          margin-left: 5px;
        }

        @media only screen and (max-width: 575px) {
          display: block;
          top: 0;
          margin-left: 0;
        }

        @media only screen and (max-width: 535px) {
          display: block;
          text-align: center;
          font-size: 9px;
          line-height: 1.2;
        }

        &.cb {
          @media only screen and (max-width: 767px) {
            top: -4px;
          }
          @media only screen and (max-width: 575px) {
            top: -2px!important;
          }
          @media only screen and (max-width: 535px) {
            top: -1px!important;
          }
        }


      }
      button {
        color: ${props => props.theme.components.header.closeButtonColor};
        padding: 0;
      }

      @media only screen and (max-width: 349px) {
        margin-right: 2px;
      }
    }

  }

  a {
    color: ${props => props.theme.components.header.callbackLink};
    text-decoration: none;
  }
`

const StyledIcon = styled(Icon)`
  margin-right:5px;
  transform: translateY(2px);
`

const StyledLogo = styled.img`
  margin-right: 10px;
  margin-top: -2px;
  width: ${props => props.theme.components.header.logoWidthDesktop};
  height: ${props => props.theme.components.header.logoHeightDesktop};

  ${query.combine(['extraSmall', 'small', 'medium'], css`
    margin-right: ${props => props.theme.components.header.marginRightMobile};
    width: ${props => props.theme.components.header.logoWidthTablet};
    height: ${props => props.theme.components.header.logoHeightTablet};
  `)}
  ${query.combine(['extraSmall'], css`
    padding: ${props => props.theme.components.header.logoPaddingSmall};
    width: ${props => props.theme.components.header.logoWidthMobile};
    height: ${props => props.theme.components.header.logoHeightMobile};
  `)}

  @media only screen and (max-width: 349px) {
    margin-right: 10px;
  }
`

export {
  Header,
  StyledIcon as Icon,
  StyledLogo as Logo,
}
