import axios from 'axios'
import { brandNameInLowerCase, getCampaignCode, getCookieValue, reverseDate, toTitleCase } from 'common/utils'
import {
  updateForm,
} from 'actions/form'

const COOKIE_NAME = 'qq_poc'
const COMPETITION_COOKIE_NAME = 'qq_comp'

const offlineGuidance = ({ who, name, dob, campaignCode, quoteReference, quoteID, type, amount, term, contact, price, requestSent, dispatch }) => {
  const isBeagleStreet = brandNameInLowerCase() === "beaglestreet"
  const buildPeopleArray = () => {
    const people = [
      {
        "id": 0,
        "title": toTitleCase(name.value.title),
        "firstName": name.value.firstName,
        "lastName": name.value.lastName,
        "dateOfBirth": reverseDate(dob.value.dateOfbirth)
      }
    ]

    if(who.value !== 'single') {
       people.push({
          "id": 1,
          "title": toTitleCase(name.value2.title),
          "firstName": name.value2.firstName,
          "lastName": name.value2.lastName,
          "dateOfBirth": reverseDate(dob.value2.dateOfbirth)
        })
    }

    return people
  }

  const cookieExists = getCookieValue(COOKIE_NAME)
  const compCookieExists = !!getCookieValue(COMPETITION_COOKIE_NAME)

  const quoteReferenceArray = quoteReference.split('/')
  const quoteReferenceId = quoteReferenceArray[quoteReferenceArray.length - 1]

  if ((cookieExists || compCookieExists) && isBeagleStreet && !requestSent && Number(price) > 0 ) {
    const guidanceCallPayload = {
      "campaignCode": campaignCode || getCampaignCode(),
      "quoteReference": quoteReferenceId.toString(),
      "coverType": toTitleCase(type),
      "coverAmount": Number(amount),
      "coverTerm": Number(term),
      "telephoneNumber":  contact.value.phone,
      "email": contact.value.email,
      "people": buildPeopleArray(),
      "isCompetition": compCookieExists
    }

    dispatch(updateForm('called', { value: true }))
    axios.post(process.env.REACT_APP_GUIDANCECALL_URL, guidanceCallPayload, {
      headers: {
        'x-correlation-id': quoteID,
      },
    })
  }
}

export default offlineGuidance
