const START_AGAIN = 'START_AGAIN'
const CLOSE_APP = 'CLOSE_APP'
const RESET_APP = 'RESET_APP'
const SET_REFERRER = 'SET_REFERRER'

export const startAgain = () => ({
  type: START_AGAIN,
})

export const setReference = (referrer) => ({
  type: SET_REFERRER,
  referrer,
})

export const closeApp = () => ({
  type: CLOSE_APP,
})

export {
  START_AGAIN,
  CLOSE_APP,
  RESET_APP,
  SET_REFERRER,
}
