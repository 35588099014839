
// const environment = process.env.REACT_APP_ENDPOINT
const aggregatorQuoteService = process.env.REACT_APP_QUOTE_URL
const gatewayAPI = process.env.REACT_APP_BUSINESSRULES_URL
const addressService = process.env.REACT_APP_ADDRESS_URL
const BRAND = process.env.REACT_APP_BRAND.toLowerCase()

export const API_KEYS = {
  trustPilot: 'K82ZfWBEyNuR22MPIjeShgKQ5ijpHPaK',
}
const API_MAP = {
  initialise: `${gatewayAPI}/qqw/api/initialise`,
  businessRules: {
    ageRule: `${gatewayAPI}/api/businessrule/age/${BRAND}`,
    amountRule: `${gatewayAPI}/api/businessrule/amount/${BRAND}`,
    defaultCIRule: `${gatewayAPI}/api/businessrule/defaultcriticalillness/${BRAND}`,
    termRule: `${gatewayAPI}/api/businessrule/term/${BRAND}`,
  },
  trustPilot: 'https://api.trustpilot.com/v1/business-units/51755ae50000640005275317',
  getQuoteUrl: `${aggregatorQuoteService}/api/quickquote`,
  getGuidanceCalcUrl: `${gatewayAPI}/qqw/api/guidance`,
  address: `${addressService}/api/Address/Find`,
}

export default API_MAP
