import { callAPIMiddleware } from './callAPIMiddleware'
import { formSubmit } from './formSubmit'
import startCloseMiddleware from './startCloseMiddleware'
import businessRulesMiddleware from './businessRulesMiddleware'
import formMiddleware from './formMiddleware'
import addressMiddleware from './addressMiddleware'
import analyticsMiddleware from './analyticsMiddleware'
import { guidanceSubmit } from './guidanceSubmit'

export default callAPIMiddleware

export {
  callAPIMiddleware,
  formSubmit,
  startCloseMiddleware,
  businessRulesMiddleware,
  formMiddleware,
  addressMiddleware,
  analyticsMiddleware,
  guidanceSubmit,
}
