import Pager from 'services/pager'
import { brandHeaderValues } from 'common/brandData'
import { brandNameInLowerCase } from 'common/utils'
import {
  START_AGAIN,
  CLOSE_APP,
  RESET_APP,
} from '../actions/startClose'
import {
  UPDATE_FORM,
} from '../actions/form'

import history from '../internalHistory'

const allowedActions = [START_AGAIN, CLOSE_APP]

const startCloseMiddleware = ({ dispatch }) =>
  next => (action) => {
    const { type } = action
    
    if (!type || !allowedActions.includes(type)) {
      return next(action)
    }

    if (type === START_AGAIN) {
      const key = 'quote'
      const value = {}
      dispatch({
        type: UPDATE_FORM,
        key,
        value,
      })
      const key2 = 'data'
      dispatch({
        type: UPDATE_FORM,
        key2,
        value,
      })
      history.push(Pager.getFirstCompent())
    }

    if (type === CLOSE_APP) {
      const brandName = brandNameInLowerCase()
      const { homepageLink } = brandHeaderValues(brandName)
      dispatch({ type: RESET_APP })
      window.location = homepageLink
    }
    return null
  }

export default startCloseMiddleware
