import Loadable from 'react-loadable'

const Async = (opts) => {
  const objAss = Loadable(Object.assign({
    loading: () => null,
    delay: 60,
    timeout: 10000,
  }, opts))

  return objAss
}

export default Async
