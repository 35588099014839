import React, { Component } from 'react'
import 'url-search-params-polyfill'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import GlobalStyle from 'theme/_global'
import { getInitialise } from 'actions/initialise'
import { updateAnalyics } from 'actions/analytics'
import { getUKBankHolidays } from 'actions/bankholidays'
import Pager from 'services/pager'
import { isEmpty } from 'lodash'
import {
  setReference,
  startAgain,
  closeApp,
} from 'actions/startClose'
import {
  isEmptyString,
  isUndefinedOrNull,
  getCampaignCode,
} from 'common/utils'
import { isMobile } from 'react-device-detect'
import Router from './Router'

// imported for grid styles (col, row)
import '@bgllife/ui.componentlibrary/dist/index.css'

const campaignCode = getCampaignCode()

class App extends Component {
  state = {
    Routes: [],
    DefaultRoute: '',
  }

  static getDerivedStateFromProps(prevProps, state) {
    const { Routes } = state
    const { form: { needs: { value }} } = prevProps

    if (!isEmpty(value)) {
      const obj = {
        Routes: Pager.getRoutes()
      }

      Object.assign(state, obj)
    }

    return Routes
  }

  componentDidMount() {
    const {
      assignRefURL,
      referrer,
      getUniqueNumber,
      getBankHolidays,
    } = this.props

    const location = window.location.href
    getUniqueNumber(campaignCode)
    getBankHolidays()
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    if(!isMobile){
      setTimeout(() => {
        const element = document.querySelector('header')
        if (element) element.scrollIntoView()
      }, 100)
    }
    
    if (isUndefinedOrNull(referrer) || isEmptyString(referrer)) {
      const siteReferrer = location !== document.referrer ? document.referrer : 'https://www.beaglestreet.com'
      assignRefURL(siteReferrer)
    }

    Pager.createPager(null)
    this.setState({
      Routes: Pager.getRoutes(),
      DefaultRoute: Pager.getFirstCompent(),
    })
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps) {
    const { uniqueNumber, updateAnalyicsFn } = this.props
    if (uniqueNumber !== prevProps.uniqueNumber && !isUndefinedOrNull(uniqueNumber)) {
      updateAnalyicsFn({ CampaignCode: campaignCode, qquoteID: uniqueNumber })
    }
  }

  render() {
    const { Routes, DefaultRoute } = this.state
    const { restartApp, navigateToReferrer } = this.props
    const isFirstPage = window.location.pathname === Routes[0]

    return (
      <Layout
        startAgainFunc={restartApp}
        showStartAgain={!isFirstPage}
        closeFunc={navigateToReferrer}
      >
        <Router Routes={Routes} DefaultRoute={DefaultRoute} />
        <GlobalStyle />
      </Layout>
    )
  }
}

App.propTypes = {
  getUniqueNumber: PropTypes.func,
  updateAnalyicsFn: PropTypes.func,
  assignRefURL: PropTypes.func,
  navigateToReferrer: PropTypes.func,
  restartApp: PropTypes.func,
  uniqueNumber: PropTypes.number,
  referrer: PropTypes.string,
  form: PropTypes.object,
  getBankHolidays: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  getUniqueNumber: (e) => dispatch(getInitialise(e)),
  assignRefURL: (e) => dispatch(setReference(e)),
  navigateToReferrer: (e) => dispatch(closeApp(e)),
  restartApp: (e) => dispatch(startAgain(e)),
  updateAnalyicsFn: (e) => dispatch(updateAnalyics(e)),
  getBankHolidays: () => dispatch(getUKBankHolidays()),
})

const mapStateToProps = state => ({
  uniqueNumber: state.initialise.uniqueNumber,
  referrer: state.startClose.referrer,
  form: state.form,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
