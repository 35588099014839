/**
 * Fn returns object of error and error message if there is an error within the amounts.
 * @param {string} name - name of the input validating.
 * @param {string} value - inputted value of input.
 * @param {object} rules - object that holds business rules from redux.
 * @param {object} form - redux form.
 * @returns {object} - boolean if validated, message for the error.
 */
function validateInput(name, inputValue, rules, form) {
  const { minimum, maximum, minimumLife, maximumLife, minimumCriticalIllness, maximumCriticalIllness } = rules
  const { amount: { value } } = form

  const allowedNames = ['amount', 'ciAmount', 'term']

  let min
  let max

  switch (name) {
    case "term":
      min = minimum
      max = maximum
      break
    case "ciAmount":
      min = minimumCriticalIllness
      max = value > maximumCriticalIllness ? maximumCriticalIllness : value
      break
    default:
      min = minimumLife
      max = maximumLife
  }

  // throw error if incorrect name give.
  if (!allowedNames.includes(name)) throw Error('Incorrect input name given to validateInput function')

  // check within bounds of min/max.
  const { isError, errorMsg } = validateMinMax(name, inputValue, min, max)

  return {
    isError,
    errorMsg,
  }
}

/**
 * Function will test value against min/max values and return true or false.
 * @param {string} amount - edited amount entered into input
 * @param {number} min - minimum from business rules
 * @param {number} max - maximum from business rules
 * @returns object - error and error message object.
 */
function validateMinMax(name, amount, min, max) {
  const value = parseInt(amount, 10)
  let msg = {}

  if (value > max || value < min || Number.isNaN(value)) msg = { isError: true, errorMsg: `Choose between ${name !== 'term' ? `£${min}` : min} and ${name !== 'term' ? `£${max}` : max}` }

  return msg
}

export default validateInput