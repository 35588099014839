import { themes } from '@bgllife/ui.componentlibrary'
import borders from './border'
import { theme as colors, color, text, background } from './colors'
import { typeScale } from './typography'

const qqwComponents = {
  components: {
    layout: {
      headerHeight: '76px',
      footerHeight: '250px',
    },
    error: {
      color: color.virginMoney.bostonUniversityRed,
      background: color.virginMoney.isabelline,
    },
    header: {
      helpLinkColor: color.white,
      background: background.virginMoneyHeader,
      callbackLink: color.white,
      borderBottom: borders.none,
      closeButtonColor: color.white,
      seperatorColor: color.white,
      logoWidthDesktop: "118px",
      logoHeightDesktop: "58px",
      logoWidthTablet: "118px",
      logoHeightTablet: "58px",
      logoWidthMobile: "40%",
      logoHeightMobile: "auto",
      logoPaddingSmall: "10px 0",
      marginRightMobile: "20px",
    },
    heading: {
      headingOne: {
        color: color.virginMoney.red,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    paragraph: {
      color: color.onyx,
    },
    checkboxSelector: {
      checkbox: {
        color: color.onyx,
        marketingOptions: {
          _input: {
            fill: color.onyx,
            borderColor: color.onyx,
          },
        },
      },
      labelBox: {
        color: color.onyx,
      },
      styledInputHidden: {
        checkedLabel: {
          background: color.virginMoney.red,
        },
      },
    },
    polarQuestion: {
      paragraph: {
        color: color.onyx,
        fontWeight: typeScale.fontWeight.bold,
      },
      subParagraph: {
        color: color.onyx,
      },
      iconText: {
        color: color.sonicSilver,
        width: '120px',
      },
      questionWrapper: {
        labelCheckedColor: color.virginMoney.red,
        svgSelectedCircleFill: color.white,
        svgSelectedFill: color.virginMoney.red,
        svgSecondFill: color.virginMoney.red,
        checkedCircleStroke: color.virginMoney.red, // circle outline when input is checked
        uncheckedCircleStroke: color.sonicSilver, // circle outline when input is not checked
        svgUncheckedDiagonalFill: color.sonicSilver,
        svgCheckedDiagonalFill: color.virginMoney.red,
        selectedOutlineFill: color.sonicSilver,
        svgTickGrow: color.white,
      }
    },
    DOB: {
      title: {
        color: color.onyx,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    radioInput: {
      activeLabel: {
        background: color.virginMoney.red,
        color: color.white,
        borderColor: color.virginMoney.red,
      },
      item: {
        color: color.onyx,
        _hover: {
          background: color.virginMoney.red,
          color: color.white,
          borderColor: color.virginMoney.red,
        },
      },
      legend: {
        color: color.onyx,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    input: {
      bar: {
        background: color.virginMoney.red,
      }
    },
    largeIcon: {
      primaryPathFill: color.sonicSilver,
      circleFill: color.white,
      circleStroke: color.sonicSilver,
      circleLineColor: color.sonicSilver,
      shadowFill: color.sonicSilver,
      growPathFill: color.sonicSilver,
      growPathStroke: color.onyx,
      strokeColor: color.onyx,
      _hover: {
        fill: color.virginMoney.red,
        primaryFill: color.virginMoney.red,
      },
      svgFill: color.sonicSilver,
    },
    button: {
      variant: {
        primary: {
          background: color.virginMoney.red,
        },
        underline: {
          color: color.onyx
        },
      },
    },
    modal: {
      headingColor: color.virginMoney.onyx,
      closeIcon: {
        fill: color.white,
        width: '26px',
        height: '26px',
        background: color.virginMoney.red,
        padding: '3px',
        radius: '50%',
      },
    },
    inputWithLabelTransition: {
      input: {
        color: color.virginMoney.red,
      },
      label: {
        color: color.onyx,
      },
      text: {
        color: color.onyx,
      },
    },
    tooltip: {
      tooltipLink: {
        color: color.onyx,
      }
    },
    inputWithLabelAbove: {
      label: {
        color: color.onyx,
      },
    },
    inputPound: {
      regularPound: {
        signColor: color.virginMoney.red,
      },
      largePound: {
        signColor: color.virginMoney.red,
      },
      input: {
        color: color.virginMoney.onyx,
      },
    },
    infoPanel: {
      borderTop: borders.none,
      helpTextColor: color.virginMoney.red,
      backgroundColor: color.virginMoney.cultured,
      headingColor: color.virginMoney.red,
      textContentColor: color.virginMoney.onyx,
      buttonTextColor: color.virginMoney.onyx,
      footerTextColor: color.virginMoney.onyx,
      chartShadow: 'none',
      chartBorderRadius: borders.radius.r0,
      quoteColor: color.virginMoney.red,
      quoteFontWeight: typeScale.fontWeight.regular,
      quoteBorder: borders.none,
      linkUnderline: typeScale.textDecoration.underline,
      linkColor: color.virginMoney.onyx,
      accordion: {
        iconColor: color.virginMoney.red,
        labelColor: color.virginMoney.red,
        toggleIconColor: color.virginMoney.red,
        contentColor: color.virginMoney.onyx,
      }
    },
    coverAmounts: {
      color: color.virginMoney.onyx,
      hover: {
        background: color.virginMoney.red,
      }
    },
    coverCalculator: {
      headingColor: color.virginMoney.onyx,
      textColor: color.virginMoney.onyx,
      coverAmountTextColor: color.budget.darkGrey,
    },
    progressBar: {
      barColor: '#B9C4D3',
      activeColor: '#793670',
      textColor: '#793670',
      inactiveTextColor: '#333',
      containerBorder: '1px solid #f8f8f8',
      containerBackgroundColor: '#f8f8f8',
      fontSize: '12px',
    },
    iconWithLabel: {
      color: color.sonicSilver,
    },
    interstitialModal: {
      titleColor: color.virginMoney.red,
      textColor: color.virginMoney.onyx,
      progressBarColor: color.virginMoney.red,
      footerPadding: '0',
      footerBackgroundColor: 'none',
    },
  },
  pages: {
    amount: {
      calculatorOptions: {
        color: color.virginMoney.onyx,
        active: {
          background: color.virginMoney.red,
          borderColor: color.virginMoney.red,
          boxShadow: `0 1px 0 0 ${color.virginMoney.red}`
        }
      },
      otherAmount: {
        color: color.virginMoney.onyx,
      },
    }
  },
  misc: {
    toolTipLabelText: {
      color: color.onyx,
    },
  },
  global: {
    pageBackgroundColor: color.white,
  },
  colors,
  text,
}

const virginMoneyTheme = themes.virginmoney.merge(qqwComponents).getTheme()

export default virginMoneyTheme
