
import axios from 'axios'
import {
  toRequestDate,
  reverseDate,
  formatDate,
  invertBooleanBSVM
} from 'common/utils'
import { isEmpty } from 'lodash'
import API_MAP from 'services/endpoint'
import { getAmountRule, getDefaultCIRule, getTerm } from 'actions/businessRules'
import offlineGuidance from './offlineGuindance'

export const GUIDANCE_SUBMIT = '@@GUIDANCE_SUBMIT'

// request function separated to test modularly.
export const sendRequest = async (url, reqObj, uniqueNumber, dispatch, offlineGuidanceData) => {
  try {
    return await axios.post(url, reqObj, { headers: { 'x-correlation-id': uniqueNumber } })
      .then(({ status, data }) => {
        if (status === 200 || status === 300) {
          if (!isEmpty(data)) {
            dispatch({ type: 'GUIDANCE_SUCCESS', payload: data })

            const { who, name, dob,  initialise, type, contact, form } = offlineGuidanceData

            offlineGuidance({
              who,
              name,
              dob,
              campaingCode: initialise.campaignCode,
              quoteReference: data.clickThroughURL,
              quoteID: initialise.uniqueNumber,
              type: type.value,
              amount: data.lifeGuidance.recommendedSumAssured,
              term: data.termGuidance.recommendedTerm,
              contact,
              price: data.price,
              requestSent: form?.called?.value,
              dispatch
            })

            const { quote: { People } } = reqObj

            // retrieve amount business rules.
            try {
              dispatch(getAmountRule(
                formatDate(new Date()),
                People[0] ? People[0].DateOfBirth : '',
                People[1] ? People[1].DateOfBirth : '',
                uniqueNumber,
              ))
            } catch (e) {
              throw new Error('Problem getting business rules in guidance request')
            }

            // retrieve default CI business rules.
            try {
              dispatch(getDefaultCIRule(
                formatDate(new Date()),
                null,
                uniqueNumber
              ))
            } catch (e) {
              throw new Error('Problem getting default ci rules in guidance request')
            }

            // retrieve term business rules.
            try {
              dispatch(getTerm(People[0].DateOfBirth, People[1] ? People[1].DateOfBirth : null, uniqueNumber))
            } catch (e) {
              throw new Error('Problem getting term rules in guidance request')
            }

          }
          else {
            dispatch({ type: 'GUIDANCE_ERROR', payload: data })
          }
        }
        else {
          dispatch({ type: 'GUIDANCE_ERROR', payload: data })
        }
      })
      .catch(err => {
        dispatch({ type: 'GUIDANCE_ERROR', payload: `An error occurred - ${err}` })
        const dataLayer = window.dataLayer || []
        dataLayer.push({ event: 'quoteCompleteInitialNoPrice', NoPrice: 'An error occured within the guidance service call' })
      })
  } catch (e) {
    throw new Error(e)
  }
}

export const guidanceSubmit = ({
  dispatch,
  getState,
}) => next => action => {
  if (!Object.prototype.hasOwnProperty.call(action, GUIDANCE_SUBMIT)) {
    return next(action)
  }

  // destructure form
  const { form, initialise, analytics } = getState()
  const {
    who,
    type,
    name,
    gender,
    dob,
    amount,
    smoker,
    contact,
    address,
    protect,
    mortgage,
    ci,
    data,
    bills,
    debts,
    savings,
    children,
    term,
  } = form

  // dispatch the request action.
  dispatch({ type: 'GUIDANCE_REQUEST' })

  const { campaignCode, uniqueNumber } = initialise
  const { qquoteID } = analytics

  const peopleValue1Array = [
    {
      Title: name.value.title,
      FirstName: name.value.firstName,
      LastName: name.value.lastName,
      Gender: gender.value,
      DateOfBirth: reverseDate(dob.value.dateOfbirth),
      isSmoker: (smoker.value === 'smoker'),
      TelephoneNumber: contact.value.phone,
      EmailAddress: contact.value.email,
    }
  ]

  const peopleValue2Obj = {
    Title: name.value2?.title,
    FirstName: name.value2?.firstName,
    LastName: name.value2?.lastName,
    Gender: gender?.value2,
    DateOfBirth: dob.value2?.dateOfbirth && reverseDate(dob.value2.dateOfbirth),
    isSmoker: (smoker?.value2 === 'smoker'),
    TelephoneNumber: contact.value.phone,
    EmailAddress: contact.value.email,
  }

  const peopleArray = who.value === 'joint' ? peopleValue1Array.concat(peopleValue2Obj) : peopleValue1Array

  const quoteObj = {
    quote: {
      People: peopleArray,
      EffectiveDate: toRequestDate(new Date()),
      CampaignCode: campaignCode,
      Address: {
        AddressLine1: address.value.Line1,
        AddressLine2: address.value.Line2 || null,
        AddressLine3: address.value.Line3 || null,
        AddressLine4: address.value.Line4 || null,
        AddressLine5: address.value.Line5 || null,
        AddressLine6: address.value.Line6 || null,
        Postcode: address.value.addressPostCode,
      },
      MarketingPreferences: {
        Email: invertBooleanBSVM(contact.value.emailMarketing || false),
        Telephone: invertBooleanBSVM(contact.value.phoneMarketing || false),
        Post: invertBooleanBSVM(contact.value.postMarketing || false),
        Sms: invertBooleanBSVM(contact.value.textMarketing || false),
      },
    }
  }

  const mortgageConditional = {
    remaining: Number(mortgage.value.toPay),
    MonthlyCost: Number(mortgage.value.perMonth),
    remainingTerm: Number(mortgage.value.yearsLeft),
    MortgageType: mortgage.value.polar,
  }

  const childrenConditional = {
    noOfChildren: children.value.noOfChildren,
    AgeOfYoungest: children.value.ageOfYoungest,
  }

  // filter out any empty values and convert the string amount to Number for endpoint.
  // returns empty object if all values weren't entered on journey.

  const billsFiltered = !isEmpty(bills.value.calc) &&
    Object.keys(bills.value.calc)
      .filter(k => bills.value.calc[k] !== '' && bills.value.calc[k] !== 0)
      .reduce((res, key) => Object.assign(res, { [key]: Number(bills.value.calc[key]) }), {})

  const ciMortgage = mortgage.ciCovered
  const ciBills = bills.ciCovered
  const ciSavings = savings.ciCovered

  let lengthDuration
  if (term.covered === 'mortgage') lengthDuration = Number(mortgage.value.yearsLeft)
  else if (term.covered === 'retirement') lengthDuration = dob.value.retirementAge
  else lengthDuration = 18 - Number(children.value.ageOfYoungest)

  const guidanceObj = {
    guidance: {
      QuoteId: qquoteID,
      customLife: amount.customEntered ? amount.value : undefined,
      customCi: ci.customEntered ? ci.ciAmount : undefined,
      childCi: ci.cciCovered,
      why: [...protect.value.options],
      Cover: {
        Mortgage: address.existingMortgage === 'yes' ? mortgageConditional : {},
        BillsTotal: bills.value.total > 0 ? bills.value.total : 0,
        Bills: billsFiltered,
        Debts: debts.covered ? debts.value.outstandingDebt : 0,
        Savings: savings.value.total ? savings.value.total : 0,
        Funeral: data.funeralCovered ? 4000 : 0,
      },
      life: {
        Mortgage: mortgage.covered,
        Bills: bills.covered,
        Savings: savings.covered,
      },
      ci: {
        Mortgage: ciMortgage,
        Bills: ciBills,
        Savings: ciSavings,
      },
      IncludeCi: ci.value !== 'noci',
      length: {
        Reason: [],
        Duration: term.customEntered ? term.value : lengthDuration,
      },
      Term: type.value,
      children: children.covered ? childrenConditional : {}
    }
  }

  // assign both objects and the final object for REQUEST
  const requestObject = Object.assign(quoteObj, guidanceObj)
  const guidanceCallObject = {
    who,
    name,
    dob,initialise,
    type,
    amount,
    term,
    contact,
    data,
    form
  }

  return sendRequest(
    API_MAP.getGuidanceCalcUrl,
    requestObject,
    uniqueNumber,
    dispatch,
    guidanceCallObject
  )
}
