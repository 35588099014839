import styled, { createGlobalStyle } from 'styled-components'
import { Wrapper, ButtonNew } from '@bgllife/ui.componentlibrary'

const GlobalStyled = createGlobalStyle`
  #root {
    background-color: #F3F5F8;
  }

  .mb-0 {
    margin-bottom: 0!important;
  }

  .mb-20 {
    margin-bottom: 20px!important;
  }

  .mt-20 {
    margin-top: 20px!important;
  }

  .fixed {
    display: none!important;
    z-index: 2;

    @media only screen and (max-width: 991px) {
      display: none!important;

      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      background: #fff;
      padding: 10px;
      bottom: 0;
      margin: 0 !important;
      box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 15%);

      a {
        width: 100%;
        margin: 0 !important;
      }
    }
  }

  #overlay {
    input {
      &::placeholder {
        color: #B9C4D3;
      }
    }

    .modal {
      margin: 10px;
      .collaps-body-heading {
        display: none;
      }
    }

    .entered {
      padding-left: 0!important;
      padding-right: 0!important;
      flex: none!important;
      max-width: none!important;
      width: auto!important;

      @media screen and (max-width: 689px) {
        width: 100%!important;
      }

      @media screen and (max-width: 560px) {
        max-width: 560px!important;
      }
      &.wide {
        max-width: 1200px!important;
        /* width: auto!important; */
        .modal {
          width: 100%!important;
          max-width: 990px!important;

          @media screen and (max-width: 992px) {
            max-width: 680px!important;
            /* width: auto!important; */
          }
        }
      }

      #Modal_interstitialModal {
        max-width: 560px!important;
        width: 560px!important;
      }
    }

    @media screen and (max-width: 992px) {
      .modal {
        padding-left: 16px;
        padding-right: 16px;

        div {
          button {
            height: 50px;

            &#modalResetAmountBtn {
              height: auto;
            }
          }
        }
      }
    }
  }

  .sticky-cta {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
      max-width: 212px;
      height: 46px;
      font-size: 18px;
    }
  }

  .uspHeading {
    padding: 0px 0px 10px 29px;
    text-align: center;
    color: #28334A;
    font-size: 25px;
    text-transform: uppercase;
    margin-top: 25px;

    @media only screen and (max-width: 767px){
      padding: 0;
      font-size: 19px;
      margin-top: 0;
    }
  }

  #trustPilotSlider {
    @media only screen and (min-width: 992px) {
      max-width: 900px;
      margin: 70px auto 0 auto;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      max-width: 660px;
      margin: 0 auto;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      max-width: 510px;
      margin: 0 auto;
    }

    @media only screen and (min-width: 320px) and (max-width: 575px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 36px;
    }

    .glider-slide {
      @media only screen and (min-width: 1200px) {
        min-height: 219px;
      }
    }
  }
`

const MobileQuoteWrapperStyled = styled(Wrapper)`
  box-shadow: none;
  padding: 11px 30px 11px 30px;
  border-radius: 0;
  background: #ffffff;
  border: 1px solid #D6DAE1;
  .row {
    align-items: center;
    flex-direction: row-reverse;
  }
  @media only screen and (max-width: 991px) {
    background: none;
    border: 0;
    padding: 0;

    .row {
      display: block;
    }
  }
`

const AmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  &:nth-child(odd) {
    border-bottom: 0.5px solid #DFDFDF;
    border-top: 0.5px solid #DFDFDF;
    padding-bottom: 20px;
    padding-top: 20px;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &:nth-child(even) {
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 991px) {
    display: block;
    padding-left: 0;
    padding-right: 0;

    &:nth-child(odd) {
      border-bottom: none;
      border-top: none;
      padding-bottom: 0;
      padding-top: 0;
    }

    &:nth-child(even) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  p {
    &:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: #28334A;
      width: 34%;
      margin-right: 21px;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &:last-child {
      width: 100%;
    }
  }

  #life-insurance-funeral-heading {
    width: 28%;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }
  }

  #life-insurance-funeral-content {
    width: 100%;
  }

  div {
    max-width: 651px;
    width: 651px;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .section-content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #373737;
    }

    .section-content-small {
      font-style: normal;
      font-size: 12px;
      line-height: 150%;
      color: #373737;
      padding-top: 5px;

      span {
        font-weight: bold;
      }
    }
  }

  .section-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #28334A;

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      flex-shrink: 0;
      margin-right: 20px;

      + div {
        max-width: 500px!important;
      }
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: 7px!important;
    }
  }
  .section-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #373737;
    width: 100%;

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      max-width: 500px!important;
    }
  }

`

const EditButtonStyled = styled(ButtonNew)`
  background: none;
  display: inline-block;
  box-shadow: none;
  margin: 0;
  padding: 0;
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #8043B3;
  text-decoration: underline;

  &:hover {
    transform: none;
  }
`

const ValidationTextStyled = styled.p`
  margin: 10px 0 0 0;
  text-align: right;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    margin-top: 10px;
  }
`

const continueButtonStyles = `
  display: block;
  max-width: 100%;
  text-decoration: none;
  height: 44.12px;
  background: linear-gradient(180deg, #8A4CB0 0%, #8038A9 100%);
  width: 212px;
  margin-left: 0;
  padding: 9px 0;
  margin-bottom: 34px;
  margin-top: 51px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  color: #FFFFFF;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Footnote = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  color: #28334A;
  margin-top: 25px;
  text-align: center;

  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 0;
  padding-top: 24px;

  p {
    max-width: 40%;
    display:block;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #373737;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media(max-width: 991px) {
    padding: 0% 15%;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    p {
      max-width: 75%;
    }
  }

  @media(max-width: 768px) {
    padding: 5px 0 50px 0;
    margin-top: 0;
    background-color: white;
    p {
      max-width: 75%;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    margin-bottom: 56px;
  }
`

  const QuoteValidUntil = styled.div`
    font-size: 14px;
    color: #373737;
    line-height: 1.4;
    max-width: 752px;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 769px) {
      max-width: none;
    }
`

  const BottomContainer = styled.div`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #fff;
    padding-top: 30px;

    @media only screen and (max-width: 769px) {
      padding-left: 10px;
      padding-right: 10px;
    }
`

export {
  GlobalStyled,
  MobileQuoteWrapperStyled,
  AmountWrapper,
  EditButtonStyled,
  ValidationTextStyled,
  continueButtonStyles,
  Footnote,
  QuoteValidUntil,
  BottomContainer,
}
