import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

function Focus(CompComponent) {
  class WrappedComponent extends Component {
    componentDidMount() {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual'
      }
      
      if(!isMobile){
        setTimeout(() => {
          const element = document.querySelector('header')
          if (element) element.scrollIntoView()
        }, 100)
      }
      window.scrollTo(0,0)
    }

    render() {
      return (
        <div aria-live="assertive">
          <CompComponent {...this.props} />
        </div>
      )
    }
  }
  WrappedComponent.displayName = `Focus(${CompComponent.displayName || CompComponent.name || 'Component'})`
  WrappedComponent.propTypes = {
    match: PropTypes.object,
  }
  return WrappedComponent
}

export default Focus
