import {
  UPDATE_ANALYTICS_SUCCESS,
  UPDATE_ANALYTICS_ERROR,
  UPDATE_STORE_ANALYTICS,
} from 'actions/analytics'

const initState = {
  edited: false,
  store: undefined,
}

const analytics = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case UPDATE_ANALYTICS_SUCCESS:
      return Object.assign({}, state, action.payload)

    case UPDATE_ANALYTICS_ERROR:
      return Object.assign({}, state, { error: action.payload })

    case UPDATE_STORE_ANALYTICS:
      return Object.assign({}, state, action.payload)

    default:
      return state
  }
}

export default analytics
