
import {
  ADDRESS_SUCCESS,
  UPDATE_ADDRESS,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  MANUAL_ADDRESS,
  MANUAL_ADDRESS_SUCCESS,
} from '../actions/address'


const allowedActions = [
  ADDRESS_SUCCESS,
  CHANGE_ADDRESS_SUCCESS,
  MANUAL_ADDRESS,
  MANUAL_ADDRESS_SUCCESS,
]
const notAllowedAddressKey = ['County', 'uniqueNumber']
const reduceArr = (arr) => arr.map(obj => {
  const newObj = Object
    .keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined && obj[k] !== '' && !notAllowedAddressKey.includes(k))
  return ({
    value: newObj.reduce((r, key) => (r.concat(obj[key])), []).join(', '),
  })
})
const addressMiddleware = ({ dispatch }) =>
  next => (action) => {
    const { type, payload } = action

    const addressObj = {
      addressNo: undefined,
      Line1: undefined,
      Line2: undefined,
      Line3: undefined,
      Line4: undefined,
      Line5: undefined,
      Line6: undefined,
      Locality: undefined,
      TownCity: undefined,
      PostCode: undefined,
    }

    if (!type || !allowedActions.includes(type)) {
      return next(action)
    }

    if (type === CHANGE_ADDRESS_SUCCESS) {
      let address = {
      }
      const mappedAddress = {

        error: undefined,
        addressNo: undefined,
        Addresses: undefined,
        ...addressObj,
        value: '',

      }

      if (payload === 'find') {
        address = Object.assign({}, mappedAddress)
        address.nextScreen = payload
      } else {
        address = {
          error: undefined,
          nextScreen: payload,
        }
      }

      dispatch({
        type: CHANGE_ADDRESS,
        address,
      })
    }

    if (type === MANUAL_ADDRESS_SUCCESS) {
      const { Addresses } = payload
      const mappedAddress = {
        0: undefined,
        ...addressObj,
      }
      let address = {}
      address = Object.assign({}, mappedAddress, Addresses, reduceArr([Addresses])[0])
      address.nextScreen = 'confirm'

      dispatch({
        type: UPDATE_ADDRESS,
        address,
      })
    }


    if (type === ADDRESS_SUCCESS) {
      const { Addresses } = payload
      const mappedAddress = {
        ...addressObj,
        error: undefined,
      }

      let address = {}

      if (Addresses.length <= 1) {
        const addressValue = reduceArr(Addresses)[0].value
        const valueSplit = addressValue.split(',')
        const removeItem = (items, i) =>
          items.slice(0, i - 1).concat(items.slice(i, items.length))
        const tempObj = { ...removeItem(valueSplit, valueSplit.length) }
        const renameKeys = (obj) => (Object.keys(tempObj).map((k) => ({ [`Line${parseInt(k, 0) + 1}`]: obj[k].trim() })))
        const newObj = (Object
          .keys(mappedAddress)
          .filter(k => address[k] !== null && address[k] !== undefined && address[k] !== '' && !notAllowedAddressKey.includes(k))
          .reduce((res, key) => Object.assign(res, { [key]: address[key] }), {}))
        address = Object.assign({}, newObj, reduceArr(Addresses)[0], ...renameKeys(tempObj), { PostCode: Addresses[0].PostCode.toUpperCase() })
        address.nextScreen = 'confirm'
      } else {
        address = {
          Addresses,
          nextScreen: 'choose',
        }
      }

      const newObj = (Object
        .keys(address)
        .filter(k => address[k] !== null && address[k] !== undefined && address[k] !== '' && !notAllowedAddressKey.includes(k))
        .reduce((res, key) => Object.assign(res, { [key]: address[key] }), {}))

      dispatch({
        type: UPDATE_ADDRESS,
        address: newObj,
      })
    }

    return null
  }

export default addressMiddleware
