
import {
  AMOUNT_RULE_SUCCESS,
  AMOUNT_RULE_ERROR,
  SET_AMOUNTS,
} from '../actions/businessRules'

const allowedActions = [AMOUNT_RULE_SUCCESS, AMOUNT_RULE_ERROR]

const businessRulesMiddleware = ({ dispatch }) => next => (action) => {
  const { type, payload } = action

  if (!type || !allowedActions.includes(type)) {
    return next(action)
  }

  if (type === AMOUNT_RULE_SUCCESS) {
    const {
      maximumCriticalIllness,
      maximumLife,
      minimumCriticalIllness,
      minimumCriticalIllnessPercentageOfLife,
      minimumLife,
      popularAmounts,
      childCiMaximumAmount,
      childCiPercentage,
    } = payload

    dispatch({
      type: SET_AMOUNTS,
      amounts: {
        maximumCriticalIllness,
        maximumLife,
        minimumCriticalIllness,
        minimumCriticalIllnessPercentageOfLife,
        minimumLife,
        childCiMaximumAmount,
        childCiPercentage,
        popularAmounts,
      },
    })
  }
  return true
}

export default businessRulesMiddleware
