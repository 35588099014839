import styled, { css } from 'styled-components'
import { container } from 'theme'

const animtion = (state) => {
  switch (state) {
    case 'entering':
      return css`
        display: block;
      `
    case 'entered':
      return css`
        opacity: 1;
        display: block;
      `
    case 'exiting':
      return css`
        opacity: 0;
      `
    case 'exited':
      return css`
        display: none;
      `
    default:
      return ''
  }
}

const TransitionElement = styled.div`
  opacity: 0;
  display: none;
  transition: opacity 500ms ease-in;
  height: 100%;
  ${props => animtion(props.status)}
  ${container}
`

export default TransitionElement
