import {
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  ADDRESS_ERROR,
  UPDATE_ADDRESS,
  RESET_ADDRESS,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  MANUAL_ADDRESS,
  MANUAL_ADDRESS_SUCCESS,
} from '../actions/address'

const initState = {
  nextScreen: 'find',
  Line1: undefined,
  Line2: undefined,
  Line3: undefined,
  Line4: undefined,
  Line5: undefined,
  Locality: undefined,
  PostCode: undefined,
  TownCity: undefined,
  value: undefined,
  error: undefined,
  Items: undefined,
  existingMortgage: undefined,
}

const address = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case ADDRESS_REQUEST: {
      return Object.assign({}, state, action.payload)
    }

    case ADDRESS_SUCCESS:
      return Object.assign({}, state, action.payload)

    case MANUAL_ADDRESS:
      return Object.assign({}, state, action.address)

    case MANUAL_ADDRESS_SUCCESS:
      return Object.assign({}, state, action.payload)

    case CHANGE_ADDRESS_SUCCESS:
      return Object.assign({}, state, action.payload)

    case CHANGE_ADDRESS:
      return Object.assign({}, state, action.address)

    case ADDRESS_ERROR: {
      return Object.assign({}, state, { error: action.payload })
    }


    case UPDATE_ADDRESS:
      return Object.assign({}, state, action.address)

    case RESET_ADDRESS:
      return Object.assign({}, state, initState)

    default:
      return state
  }
}

export default address
