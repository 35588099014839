const color = {
  amazonite: '#00C2A2',
  observatory: '#008A73',
  pearlAqua: '#87d8c9',
  azureishWhite: '#DDF0EE',
  aliceBlue: '#EFF8F7',
  vividGamboge: '#FF9800',
  fulvous: '#E97707',
  raisinBlack: '#222222',
  sonicSilver: '#757575',
  pastelGrey: '#CCCCCC',
  whiteSmoke: '#F5F5F5',
  white: '#FFFFFF',
  independence: '#39496B',
  japaneseIndigo: '#28334a',
  cadetGrey: '#89A5AB',
  purpureus: '#925EAE',
  sunsetOrange: '#ff4f4f',
  purpleDark: '#8F38C3',
  purpleLight: '#7F3DA8',
  black: '#000000',
  greyBlue: '#A7CCBE',
  link: '#8038A9',
  iconLightGreen: '#DDF7F4',
  highRiseOrchid: '#8043B3',
  matterhorn: '#4d4d4d',
  onyx: '#373737',
  transparent: 'transparent',
  vitaminC: '#ff9800',
  pleasantPurple: '#8535B4',
  athensGray: '#F3F5F8',
  virginMoney: {
    red: '#CE0000',
    onyx: '#373737',
    cultured: '#F6F4F5',
    bostonUniversityRed: '#CC0000',
    isabelline: '#F0EDED',
  },
  budget: {
    honoluluBlue: '#006BAD',
    spanishGray: '#949494',
    purple: '#6859A3',
    purple70: 'rgba(104, 89, 163, .7)',
    darkGrey: '#4A4A4A',
    mordantRed: '#B30909',
    cultured: '#F8F6F3',
    alabaster: '#FAFAFA',
  }
}

const theme = {
  primary: color.amazonite,
  altPrimary: color.observatory,
  lightPrimary: color.azureishWhite,
  divider: color.pastelGrey,
  accent: color.vividGamboge,
  accentDark: color.fulvous,
  white: color.white,
  tintedWhite: color.aliceBlue,
  error: color.sunsetOrange,
  header: color.japaneseIndigo,
  validation: color.purpureus,
  purpleDark: color.purpleDark,
  purpleLight: color.purpleLight,
  spacer: color.greyBlue,
  iconFocus: color.japaneseIndigo,
  iconCircle: color.iconLightGreen,
  iconGreen: color.amazonite,
  summaryHeading: color.highRiseOrchid,
}

const text = {
  primary: color.japaneseIndigo,
  normal: color.raisinBlack,
  light: color.sonicSilver,
  blue: color.japaneseIndigo,
  link: color.amazonite,
  icon: color.pastelGrey,
  iconGreen: color.pearlAqua,
  iconHover: color.independence,
  iconHoverAlt: color.cadetGrey,
  black: color.black,
  textLink: color.link,
  grey: color.matterhorn,
}

const background = {
  virginMoneyHeader: 'linear-gradient(to right,#c00 50%,#bd1d65 80%,#8e2d71 100%)'
}

export {
  color,
  theme,
  text,
  background,
}
