import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group'

import TransitionElement from './style'

const AnimateSwitch = ({
  children,
  defaultRoute,
}) => (
  <Route
    render={({ location }) => (
      <>
        {/*
          Parent switch required because we cannot have a redirect route
          outside a switch. Route outside a switch runs first and then it
          runs a route from an inside a switch.
        */}
        <Switch>
          {/*
            Couldn't put this route inside a Router.jsx file as
            react-transition-group triggers an infinite loop on redirect.
          */}
          <Route
            exact
            path="/"
            render={() => <Redirect to={defaultRoute} />}
          />
          <TransitionGroup component={null}>
            <Transition key={location.key} timeout={0}>
              {(status) => (
                <TransitionElement status={status} className={status}>
                  <Switch location={location}>
                    {children}
                  </Switch>
                </TransitionElement>
              )}
            </Transition>
          </TransitionGroup>
        </Switch>
      </>
    )}
  />
)

AnimateSwitch.propTypes = {
  children: PropTypes.node,
  defaultRoute: PropTypes.string.isRequired,
}

export default AnimateSwitch
