import { primaryFont, typeScale } from './typography'
import { theme as colors, text } from './colors'

const theme = {
  name: 'DEFAULT',
  primaryFont,
  typeScale,
  colors,
  text,
}
export default theme
