import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import FullOverlay from './style'

/* eslint-disable react/no-find-dom-node */
class Overlay extends Component {
  state = {
    lastFocused: undefined,
    toggle: () => { },
    isVisible: false,
    initialTarget: undefined,
  }

  modalRef = React.createRef();

  componentDidMount() {
    const { state: { isVisible } } = this.props
    this.updateState(() => {
      if (isVisible) {
        this.mountChildren()
        this.trapFocus()
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { state: { isVisible } } = this.props
    if (prevState.isVisible !== isVisible) {
      this.updateState(() => {
        this.trapFocus()
        this.mountChildren()
      })
    }
  }

  // Uppdate the internal state, this is mainly to merge the toggle functions
  updateState = (fn) => {
    const { isVisible, lastFocused } = this.state
    const { state } = this.props
    const newToggle = () => {
      state.toggle()
      if (!isVisible) {
        this.unMountChildren()
      } else {
        this.mountChildren()
      }
    }
    this.setState({
      lastFocused: !isVisible ? document.activeElement : lastFocused,
      toggle: newToggle,
      isVisible: state.isVisible,
    }, fn())
  }

  mountChildren = () => {
    document.addEventListener('keyup', this.escFunction, false)
    document.addEventListener('keyup', this.checkFocus, false)
    document.addEventListener('keydown', this.escFunction, false)
    document.addEventListener('keydown', this.checkFocus, false)
    this.trapFocus()
  }

  unMountChildren = () => {
    const { lastFocused } = this.state
    document.removeEventListener('keyup', this.escFunction, false)
    document.removeEventListener('keyup', this.checkFocus, false)
    document.removeEventListener('keydown', this.escFunction, false)
    document.removeEventListener('keydown', this.checkFocus, false)
    lastFocused.focus()
  }

  // Ig the user hits escape
  escFunction = e => {
    const { toggle } = this.state
    if (e.keyCode === 27) {
      toggle()
    }
  }

  // If the user clicks the close icon
  closeModal = e => {
    const { toggle, initialTarget } = this.state
    const { preventToggle, onError } = this.props
    if (!preventToggle) return true
    onError()
    if (e.target === e.currentTarget && initialTarget === e.currentTarget) {
      toggle()
      this.unMountChildren()
    }
    return true
  }

  // Every time the use tabs update the focus
  checkFocus = e => {
    if (e.keyCode === 9 || e.keyCode === 13) {
      this.trapFocus()
    }
  }

  // Force the focus to only be inside of the modal
  trapFocus = () => {
    const { isVisible } = this.state
    if (
      isVisible && this.modalRef.current &&
      !this.modalRef.current.contains(document.activeElement)
    ) {
      this.modalRef.current.focus()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { state: { submit } } = this.props
    const userSubmit = submit(e)
    // If userSubmit want to fail out of submitting then prevent modal from unmounting
    if (userSubmit !== false) {
      this.unMountChildren()
    }
  }

  // Pass the required props to the modal
  getModalProps = () => ({
    ref: this.modalRef,
    'aria-live': 'assertive',
    tabIndex: 0,
  })

  // Prevent closing, when release dragging on overlay
  setInitialTarget = e => {
    this.setState({
      initialTarget: e.target,
    })
  }

  render() {
    const { isVisible } = this.state
    const {
      className,
      children,
      style,
      Backdrop = FullOverlay,
    } = this.props
    return ReactDOM.createPortal(
      <Transition mountOnEnter unmountOnExit in={isVisible} timeout={{ enter: 0, exit: 250 }}>
        {(status) => (
          <Backdrop
            className={className}
            onClick={this.closeModal}
            onMouseDown={this.setInitialTarget}
            style={style}
            data-testid="overlay"
          >
            {children({
              getModalProps: this.getModalProps,
              handleSubmit: this.handleSubmit,
              status,
            })}
          </Backdrop>
        )}
      </Transition>,
      document.getElementById('overlay'),
    )
  }
}

Overlay.propTypes = {
  state: PropTypes.object,
  Backdrop: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.func,
  style: PropTypes.object,
  preventToggle: PropTypes.bool,
  onError: PropTypes.func,
}

Overlay.defaultProps = {
  state: false,
}

export default Overlay
