
import { DataLayerContent } from 'common/copy'
import {
  isUndefinedOrNull,
  quoteValidDate,
  removeUnderfinedNullFromObj,
  formatCurrency,
  isEven,
  getDay,
  invertBooleanBSVM,
} from 'common/utils'
import {
  UPDATE_ANALYTICS,
  UPDATE_PAGE_ANALYTICS,
  UPDATE_ANALYTICS_SUCCESS,
  UPDATE_EDIT_ANALYTICS,
  UPDATE_ANALYTICS_ERROR,
  UPDATE_PRICE_EDIT_ANALYTICS,
} from '../actions/analytics'

import { errorTypeDatalayer } from './utils/analyticsHelpers'

const trimString = (str) => str.trim()

const allowedActions = [
  UPDATE_ANALYTICS,
  UPDATE_PAGE_ANALYTICS,
  UPDATE_EDIT_ANALYTICS,
  UPDATE_ANALYTICS_ERROR,
  UPDATE_PRICE_EDIT_ANALYTICS,
]

const lossAversionEnabled = JSON.parse(process.env.REACT_APP_LOSS_AVERSION)

const analyticsMiddleware = ({ dispatch, getState }) =>
  next => (action) => {
    const { type, payload } = action

    if (!type || !allowedActions.includes(type)) {
      return next(action)
    }

    const dataLayer = window.dataLayer || []

    /**
     * Error Analytics Datalayer functionality.
     */
    if (type === UPDATE_ANALYTICS_ERROR) {
      const {
        message,
        errorType,
        term,
        amount,
        location,
        CIValue,
        ciAmount
      } = payload

      // let function sort object, return object and datalayer push event inside function.
      const errorObject = errorTypeDatalayer(errorType, message, amount, term, CIValue, ciAmount, location)

      // push analytics to state (state.analytics)
      dispatch({
        type: UPDATE_ANALYTICS_SUCCESS,
        payload: { ...errorObject },
      })
    }

    if (type === UPDATE_PRICE_EDIT_ANALYTICS) {
      const { updateType, originalValue } = payload

      const obj = {
        updateType,
        original: originalValue
      }

      dispatch({
        type: UPDATE_ANALYTICS_SUCCESS,
        payload: { ...obj },
      })
    }


    if (type === UPDATE_ANALYTICS) {
      const { form, analytics } = getState()
      const {
        storeName, isJoint, key, calculatorFields,
      } = payload
      const { data, gender, smoker, who, dob } = form

      let dlObj = {}
      let edit = false
      let CIStatus
      let ciValue
      let ciProp
      const previousAnalytics = analytics
      const { updateType, original } = analytics

      if (!isUndefinedOrNull(storeName) && !isUndefinedOrNull(isJoint)) {
        const person1value = storeName !== 'partner' && storeName !== 'quote' ? form[storeName].value : null
        const person2value = storeName !== 'partner' && storeName !== 'quote' ? form[storeName].value2 : null
        const dlValue = !isJoint ? person1value : `${person1value} :: ${person2value} `
        if (storeName === 'who') {
          dlObj = {
            quoteWho: dlValue,
          }
        }

        if (storeName === 'needs') {
          dlObj = {
            event: 'helpOption',
            needs: form.needs.value
          }
        }

        if (storeName === 'name') {
          dlObj = {
            age: dob.value.age,
            gender: gender.value,
            smoker: smoker.value,
            quoteWho: who.value,
          }
        }

        if (storeName === 'partner') {
          dlObj = {
            smoker: `${smoker.value} :: ${smoker.value2}`,
            gender: `${gender.value} :: ${gender.value2}`
          }
        }

        if (storeName === 'amount') {
          dlObj = {
            coverAmount: `£${formatCurrency(form[storeName].value)}`,
            ...calculatorFields,
          }
        }

        if (storeName === 'term') {
          dlObj = {
            coverLength: form[storeName].value,
          }
        }

        if (storeName === 'type') {
          dlObj = {
            coverType: form.type.value,
          }
        }

        if (storeName === 'quote' || storeName === 'contact' || storeName === 'ci') {
          const { amount, ci } = form
          const { value } = amount
          const { ciAmount } = ci
          const isCIorCCI = ['ci', 'cci'].indexOf(ci.value) > -1
          const yesCiMessage = isCIorCCI && `yes ${ci.value === 'cci' ? 'plus CCI' : ''}`
          CIStatus = `${isCIorCCI ? yesCiMessage.trim() : 'no'}`
          ciValue = isCIorCCI ? formatCurrency(ciAmount) : 'none'
          ciProp = isCIorCCI ? `${Math.round(((ciAmount / value) * 100))}%` : 'none'
          if (!isUndefinedOrNull(ciAmount)) {
            dlObj = {
              ci: ciValue,
              ciProp,
              CIStatus,
            }
          }
        }

        if (storeName === 'quote') {
          const { person } = payload
          const {
            amount,
            term,
            ci,
            contact,
          } = form
          const {
            value: {
              emailMarketing,
              phoneMarketing,
              postMarketing,
              textMarketing,
            },
          } = contact
          const { value } = amount
          const dobValue = [dob.value.dateOfbirth, isJoint && dob.value2?.dateOfbirth]
          const {
            quoteWho,
            campaignCode,
            age,
          } = analytics

          edit = true

          const obj = {
            event: key,
            IQPriceGiven: `£${data.price}`,
            CIStatus,
            CoverLength: term.value,
            ci: ci.value !== 'noci' ? `£${formatCurrency(ci.ciAmount)}` : 'none',
            ciProp,
            coverType: form.type.value,
            gender: who.value === 'joint' ? `${gender.value} :: ${gender.value2}` : gender.value,
            quoteWho,
            smoker: who.value === 'joint' ? `${smoker.value} :: ${smoker.value2}` : smoker.value,
            campaignCode,
            coverAmount: `£${formatCurrency(value)}`,
            age,
            QuoteExpiry: String(quoteValidDate(dobValue)),
          }

          if (lossAversionEnabled) {
            const lossAversionValue = isEven(getDay(dob.value.dateOfbirth)) ? 'Test' : 'Control'
            obj.lossAversion = lossAversionValue
          }

          dlObj = removeUnderfinedNullFromObj(obj)

          const newpreviousAnalytics = (Object
            .keys(previousAnalytics)
            .filter(k => previousAnalytics[k] !== null && previousAnalytics[k] !== undefined && previousAnalytics[k] !== '' && k !== 'previousAnalytics' && k !== 'undefined' && k !== 'storeName')
            .reduce((res, q) => Object.assign(res, { [q]: previousAnalytics[q] }), {}))

          Object.assign(dlObj, {
            emailMarketing: invertBooleanBSVM(emailMarketing) ? 'preference on' : undefined,
            phoneMarketing: invertBooleanBSVM(phoneMarketing) ? 'preference on' : undefined,
            postMarketing: invertBooleanBSVM(postMarketing) ? 'preference on' : undefined,
            textMarketing: invertBooleanBSVM(textMarketing) ? 'preference on' : undefined,
            IQDataEdit: undefined,
            previousAnalytics: newpreviousAnalytics,
          })

          if (updateType === 'smoker') {
            dlObj.IQDataEdit = person === 2 && !isUndefinedOrNull(smoker.value2) ? `smoker2IQ ${smoker.value} ` : `smoker1IQ ${smoker.value}`
          }

          if (updateType === 'type') {
            dlObj.IQDataEdit = `coverTypeIQ ${form.type.value}`
          }

          if (updateType === 'amount') {
            dlObj.IQDataEdit = `coverAmountIQ ${!isUndefinedOrNull(original) ? `from £${formatCurrency(original)} to` : ''} £${formatCurrency(value)}` // numberWithCommas
          }

          if (updateType === 'term') {
            dlObj.IQDataEdit = `coverLengthIQ ${!isUndefinedOrNull(original) ? `from ${original
              } to` : ''} ${term.value}`
          }

          if (updateType === 'ci') {
            dlObj.IQDataEdit = `CIIQ ${ci.value === 'noci' ? 'no' : 'yes'}`
          }

          if (updateType === 'cci') dlObj.IQDataEdit = `CCI IQ ${ci.value === 'cci' ? 'yes' : 'no'}`

          if (updateType === 'ciAmount') {
            dlObj.IQDataEdit = `CIIQAmount ${!isUndefinedOrNull(original.ciAmount) ? `from ${original.ciAmount
              } to` : ''} ${ci.ciAmount}`
          }

          // ci amount datalayer change on summary page
          // if (editType === 'ciAmount') dlObj.IQDataEdit = `CIIQAmount ${!isUndefinedOrNull(previous) ? `from £${formatCurrency(previous)} to` : ''} £${formatCurrency(ci.ciAmount)}`
          // if (editType === 'ciEdit') dlObj.IQDataEdit = `CIIQ ${ci.value === 'noci' ? 'no' : 'yes'}`
        }

        if (!isUndefinedOrNull(dlObj)) {

          // remove undefined
          const updateObject = (Object
            .keys(dlObj)
            .filter(k => dlObj[k] !== null && dlObj[k] !== undefined)
            .reduce((res, q) => Object.assign(res, { [q]: dlObj[q] }), {}))

          dataLayer.push(updateObject)

          // if (journey === 'guidance' && !initial) {
          //   dlObj = Object.keys(dlObj).filter(q => dlObj[q] !== undefined && q !== 'previousAnalytics')
          //     .reduce((res, ind) => Object.assign(res, { [ind]: dlObj[ind] }), {})
          //   dataLayer.push(dlObj)
          // }

          // push to datalayer from summary page.
          dispatch({
            type: UPDATE_ANALYTICS_SUCCESS,
            payload: { ...dlObj, edit, updateType, 'Error Message': undefined },
          })

          // quoteCompleteInitial on summary
          // quote added to dispatch payload to pass price in
          // if (storeName === 'quote') {
          //   const { initialise: { campaignCode, uniqueNumber } } = getState()
          //   const initialLoadAnalytics = Object
          //     .keys(dlObj)
          //     .filter(k =>
          //       dlObj[k] !== null &&
          //       dlObj[k] !== undefined &&
          //       k !== 'previousAnalytics' &&
          //       k !== 'store' &&
          //       k !== 'storeName' &&
          //       k !== 'smoker' &&
          //       k !== 'pagecount' &&
          //       k !== 'lossAversion' &&
          //       k !== 'isJoint' &&
          //       k !== 'edited' &&
          //       k !== 'edit' &&
          //       k !== 'QuoteExpiry'
          //     )
          //     .reduce((res, ind) => Object.assign(res, { [ind]: dlObj[ind] }), {})
          //   const newb = Object.assign(initialLoadAnalytics, {
          //     event,
          //     IQPriceGiven: `£${finalPrice}`,
          //     CampaignCode: campaignCode,
          //     qquoteID: uniqueNumber,
          //     coverLength: String(initialLoadAnalytics.CoverLength),
          //     coverType: form.type.value,
          //     needs: form.needs.value,
          //     smoker: who.value === 'joint' ? `${smoker.value} :: ${smoker.value2}` : smoker.value,
          //   })

          //   // remove upper cases which have been replaced above (coverType and coverLength)
          //   const filteredNewb = Object
          //     .keys(newb)
          //     .filter(q =>
          //       q !== 'CoverLength'
          //     )
          //     .reduce((res, ind) => Object.assign(res, { [ind]: newb[ind] }), {})

          //   dataLayer.push(filteredNewb)
          // }
        }
      } else if (!isUndefinedOrNull(payload)) {
        dataLayer.push(payload)
        dispatch({
          type: UPDATE_ANALYTICS_SUCCESS,
          payload,
        })
      }
    }

    if (type === UPDATE_EDIT_ANALYTICS) {
      const {
        editType,
        isOpen,
        person,
        button,
      } = payload

      const closeName = (button === 'Close' || button === 'close') ? 'editClose' : undefined
      const editName = isOpen ? 'editOpen' : closeName
      const smokerTitle = person === 2 ? 'Partner' : 'Your'
      let editValue

      // for default journey
      if (editType === 'smoker') editValue = `${smokerTitle} Smoking Status`
      if (editType === 'type') editValue = 'Cover Type'
      if (editType === 'term') editValue = 'Cover Length'
      if (editType === 'amount') editValue = 'Cover Amount'
      if (editType === 'ci') editValue = 'Critical Illness Cover'
      if (editType === 'ciAmount') editValue = 'Critical Illness Amount'
      if (editType === 'cci') editValue = 'Child CI'

      const editData = {
        event: editName,
        [editName]: editValue,
      }

      if ((!isUndefinedOrNull(editType) && !isUndefinedOrNull(editData.event))) {

        // returns event and editOpen/editClose obj
        const newObj = (Object
          .keys(editData)
          .filter(k => k !== undefined && editData[k] !== null && editData[k] !== undefined)
          .reduce((res, key) => Object.assign(res, { [key]: editData[key] }), {}))
        dataLayer.push({
          ...newObj,
        })

        dispatch({
          type: UPDATE_ANALYTICS_SUCCESS,
          payload: { ...editData, [isOpen ? 'EditClose' : 'editOpen']: undefined },
        })
      }
    }

    if (type === UPDATE_PAGE_ANALYTICS) {
      const { id, person } = payload
      const { personPageArray } = DataLayerContent
      const isJointPage = personPageArray.includes(id)
      const { analytics } = getState()
      let pagecount = 0
      if (analytics.store === id) {
        pagecount = isUndefinedOrNull(analytics.pagecount) ? 0 : analytics.pagecount + 1
        if (pagecount > 2) {
          pagecount = 0
          dispatch({
            type: UPDATE_ANALYTICS_SUCCESS,
            payload: { pagecount },
          })
          return false
        }
      }


      if (!isUndefinedOrNull(DataLayerContent[id])) {
        const { url, title } = DataLayerContent[id]
        const joint = isJointPage && person > 1 ? person : ''

        if (isUndefinedOrNull(url) || isUndefinedOrNull(title)) return false
        const pageUrlString = trimString(`${url} ${joint} `)
        const pageTitleString = trimString(`${title} ${joint} `)

        const analyticsObj = {
          event: 'pageName',
          PageUrl: `/virtual/qqw/${pageUrlString}`,
          PageTitle: `Widget ${pageTitleString} page`,
        }

        dataLayer.push({ ...analyticsObj })

        dispatch({
          type: UPDATE_ANALYTICS_SUCCESS,
          payload: { ...analytics, store: id, pagecount },
        })
      }
    }


    return null
  }

export default analyticsMiddleware
