import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import { brandContactDetails, brandLinks, brandName } from 'common/brandData'
import {
  Button,
  Row,
  Col,
  Heading,
} from '@bgllife/ui.componentlibrary'
import { updateStoreAnalytics } from 'actions/analytics'
import { updatePageAnalytics, brandNameInLowerCase } from '../../common/utils'
import {
  TelLink,
  Link,
} from './style'

const ErrorPage = ({
  onClick = () => {},
}) => {
  const dispatch = useDispatch()
  const reduxStoreData = useSelector(state => state, shallowEqual)
  const { analytics: { store } } = reduxStoreData
  const {
    phoneNumber: { phoneNumberFormatted, phoneNumberWithoutSpaces },
  } = brandContactDetails(brandNameInLowerCase())
  const { homepageLink } = brandLinks(brandNameInLowerCase())
  const { errorBrandName } = brandName(brandNameInLowerCase())

  useEffect(() => {
    const pathvalue = "error404"

    const updateAnalytics = () => {
      updatePageAnalytics({
        url: pathvalue,
        title: pathvalue
      })
    }

    if (store !== pathvalue) {
      updateAnalytics()
      // need to set store value manually, otherwise page name event is not triggered
      // on your-needs page after restart button is clicked on error404 page.
      dispatch(updateStoreAnalytics({
        store: 'error404'
      }))
    }
    // With dependecy array populated, DL is fired multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row>
      <Col xs="12" style={{ marginTop: 30 }}>
        <Heading align="center" id="error">
          Oops, page not found
        </Heading>
      </Col>
      <Col xs="12" md={{ span: 4, offset: 4 }}>
        <Button id="btn_restart" onClick={onClick} kind="primary">Restart Quote</Button>
      </Col>
      <Col xs="12">
        <p id="contact" style={{ textAlign: 'center', fontSize: 20 }}>Call us on <TelLink href={`tel:${phoneNumberWithoutSpaces}`}>{phoneNumberFormatted}</TelLink></p>
        <p id="goBack" style={{ textAlign: 'center' }}> or go back to <Link href={`${homepageLink}`}>{errorBrandName}</Link></p>
      </Col>
    </Row>
  )
}

ErrorPage.propTypes = {
  onClick: PropTypes.func,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
}

export default ErrorPage
