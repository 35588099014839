import {
  GET_BANKHOL_REQUEST,
  GET_BANKHOL_SUCCESS,
  GET_BANKHOL_ERROR,
} from 'actions/bankholidays'

const bankHolidays = (
  state = {},
  action,
) => {
  switch (action.type) {
    case GET_BANKHOL_REQUEST:
      return Object.assign({}, state, { loading: true, data: action.payload })
    case GET_BANKHOL_SUCCESS:
      return Object.assign({}, state, { loading: false, data: action.payload })
    case GET_BANKHOL_ERROR:
      return Object.assign({}, state, { loading: false, error: action.payload })
    default:
      return state
  }
}

export default bankHolidays
