import styled, { keyframes, css } from 'styled-components'
import { Col } from '@bgllife/ui.componentlibrary'

const loading = keyframes`
  from {left: -200px; width: 30%;}
  50%  {width: 30%;}
  70%  {width: 70%;}
  80%  {left: 50%;}
  95%  {left: 120%;}
  to   {left: 100%;}
`

const animation = (state) => {
  switch (state) {
    case 'entering':
      return css`
        display: block;
      `
    case 'entered':
      return css`
        opacity: 1;
        display: block;
      `
    case 'exiting':
      return css`
        opacity: 0;
        display: block;
      `
    case 'exited':
      return css`
        display: none;
      `
    default:
      return ''
  }
}

const Column = styled(Col)`
  overflow: auto;
  opacity: 0;
  transition: opacity ease-in 250ms;
  height: initial;

  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;

  -ms-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;

  max-height: 80%;
  min-height: 80%;
  max-width: 40%;
  min-width: 40%;
  @supports (justify-content: center) {
    max-height: none;
    min-height: 0px;
    max-width: none;
    min-width: 0px;
  }

  &.entered {
    opacity: 1;
    transition: opacity ease-in 250ms;
  }
`

const Container = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
`

const TextContainer = styled.div`
  padding: 15px;
`

const Title = styled.div`
  color: ${props => props.theme.components.interstitialModal.titleColor};
  padding: 15px 0;
  font-size: 24px;
  span.title {
    color: ${props => props.theme.components.interstitialModal.textColor};
    display: block;
    font-size: 18px;
  }
`

const MessageContainer = styled.div`
  height: 70px;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
`

const Messages = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  display: none;
  transition: opacity 1000ms ease-in;
  ${props => animation(props.status)}
  span {
    display: block;
    color: ${props => props.theme.components.interstitialModal.textColor};
    font-size: 18px;
  }

  color: ${props => props.theme.components.interstitialModal.titleColor};
  font-size: 22px;
`

const Progress = styled.div`
  height: 4px;
  width: 100%;
  max-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 20px auto 0px;
  &:before {
    display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: ${props => props.theme.components.interstitialModal.titleColor};
      animation: ${loading} 2s linear infinite;
  }
`

const SubTitle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${props => props.theme.components.interstitialModal.textColor};
`

const BottomBar = styled.div`
  background: ${props => props.theme.components.interstitialModal.footerBackgroundColor};
  padding: ${props => props.theme.components.interstitialModal.footerPadding};
  margin-top: 30px;
`

export {
  Column,
  Container,
  Title,
  Messages,
  Progress,
  SubTitle,
  BottomBar,
  MessageContainer,
  TextContainer,
}
