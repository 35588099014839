import { themes } from '@bgllife/ui.componentlibrary'
import borders from './border'
import { theme as colors, color, text } from './colors'
import { typeScale } from './typography'

const qqwComponents = {
  components: {
    layout: {
      headerHeight: '49px',
      footerHeight: '0px',
    },
    header: {
      helpLinkColor: color.white,
      background: color.japaneseIndigo,
      callbackLink: color.white,
      borderBottom: borders.none,
      closeButtonColor: color.white,
      seperatorColor: color.white,
      logoWidthDesktop: "100px",
      logoHeightDesktop: "32px",
      logoWidthTablet: "90px",
      logoHeightTablet: "29px",
      logoWidthMobile: "90px",
      logoHeightMobile: "29px",
      logoPaddingSmall: "0",
      marginRightMobile: "20px",
    },
    polarQuestion: {
      questionWrapper: {
        activeHoverFill: color.japaneseIndigo,
      },
      iconText: {
        width: '120px',
      },
    },
    modal: {
      headingColor: color.japaneseIndigo,
      closeIcon: {
        fill: color.pleasantPurple,
        width: '25px',
        height: '25px',
        background: 'none',
        padding: '0',
        radius: '0',
      },
    },
    infoPanel: {
      borderTop: borders.none,
      helpTextColor: color.raisinBlack,
      backgroundColor: color.azureishWhite,
      headingColor: color.amazonite,
      textContentColor: color.black,
      buttonTextColor: color.raisinBlack,
      footerTextColor: color.sonicSilver,
      chartShadow: '0 0 15px 0 rgba(0, 147, 123, 0.25)',
      chartBorderRadius: borders.radius.r3,
      quoteColor: color.purpureus,
      quoteFontWeight: typeScale.fontWeight.bold,
      quoteBorder: borders.border1pxPastelGrey,
      linkUnderline: typeScale.textDecoration.none,
      linkColor: color.amazonite,
      accordion: {
        iconColor: color.amazonite,
        labelColor: color.amazonite,
        toggleIconColor: text.light,
        contentColor: color.black,
      }
    },
    coverAmounts: {
      color: color.black,
      hover: {
        background: color.amazonite,
      }
    },
    coverCalculator: {
      headingColor: color.japaneseIndigo,
      textColor: color.onyx,
      coverAmountTextColor: color.japaneseIndigo,
    },
    progressBar: {
      barColor: '#B9C4D3',
      activeColor: color.japaneseIndigo,
      textColor: color.japaneseIndigo,
      inactiveTextColor: color.japaneseIndigo,
      containerBorder: '#ccc 2px dotted',
      containerBackgroundColor: 'transparent',
      fontSize: '14px',
    },
    largeIcon: {
      growPathFill: color.amazonite,
      growPathStroke: color.japaneseIndigo,
      circleStroke: color.japaneseIndigo,
      circleLineStroke: color.japaneseIndigo,
    },
    inputBreakdown: {
      typography: {
        color: color.japaneseIndigo
      },
    },
    interstitialModal: {
      titleColor: color.amazonite,
      textColor: color.raisinBlack,
      progressBarColor: color.amazonite,
      footerPadding: '15px',
      footerBackgroundColor: color.azureishWhite,
    },
    trustPilot: {
      textColor: color.black,
      ratingTextColor: color.amazonite,
    }
  },
  pages: {
    amount: {
      calculatorOptions: {
        color: color.raisinBlack,
        active: {
          background: color.amazonite,
          borderColor: color.amazonite,
          boxShadow: `0 1px 0 0 ${color.amazonite}`,
        }
      },
      otherAmount: {
        color: color.raisinBlack,
      },
    }
  },
  misc: {
    toolTipLabelText: {
      color: color.japaneseIndigo,
    },
  },
  global: {
    pageBackgroundColor: color.athensGray,
  },
  colors,
  text,
}

const beagleStreetTheme = themes.beaglestreet.merge(qqwComponents).getTheme()

export default beagleStreetTheme
