import { CALL_API } from '../middleware/callAPIMiddleware'
import API_MAP from '../services/endpoint'

export const GET_INITIALISE_REQUEST = 'GET_INITIALISE_REQUEST'
export const GET_INITIALISE_SUCCESS = 'GET_INITIALISE_SUCCESS'
export const GET_INITIALISE_ERROR = 'GET_INITIALISE_ERROR'

export const getInitialise = (campaignCode) => ({

  [CALL_API]: {
    url: `${API_MAP.initialise}`,
    method: 'GET',
    headers: {
      'BGLLife-CampaignCode': campaignCode,
    },
    types: [GET_INITIALISE_REQUEST, GET_INITIALISE_SUCCESS, GET_INITIALISE_ERROR],
    campaignCode,
  },
  type: GET_INITIALISE_REQUEST,
  campaignCode,
})

export const SELECT_INITIALISE = 'SELECT_INITIALISE'

export const selectInitialise = (initialiseNumber) => ({
  type: SELECT_INITIALISE,
  initialiseNumber,
})
