import {
  GET_INITIALISE_REQUEST,
  GET_INITIALISE_SUCCESS,
  GET_INITIALISE_ERROR,
  SELECT_INITIALISE,
} from 'actions/initialise'

const initialise = (
  state = {
    loading: undefined, // Bool
    uniqueNumber: undefined, // Number
    campaignCode: undefined, // String
  },
  action,
) => {
  switch (action.type) {
    case GET_INITIALISE_REQUEST:
      return Object.assign({}, state, { loading: true, campaignCode: action.payload })
    case GET_INITIALISE_SUCCESS:
      return Object.assign({}, state, { loading: false, uniqueNumber: action.payload, campaignCode: action.headers[Object.keys(action.headers)[0]] })
    case GET_INITIALISE_ERROR:
      return Object.assign({}, state, { loading: false, error: action.payload })
    case SELECT_INITIALISE:
      return Object.assign({}, state, { selected: action.initialiseNumber })
    default:
      return state
  }
}

export default initialise
