import {
  DEFAULT_CI_RULE_REQUEST,
  DEFAULT_CI_RULE_SUCCESS,
  DEFAULT_CI_RULE_ERROR,
  ADD_CI_SUCCESS,
} from '../actions/businessRules'

const initialState = {
  isFetching: false,
  sumAssuredLowerBound: undefined, // number
  defaultPercentage: undefined, // number
  context: undefined, // string
  defaultAmount: undefined, // number
}

const defaultCriticalIllness = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case DEFAULT_CI_RULE_REQUEST:
      return Object.assign({}, state, { isFetching: true })
    case DEFAULT_CI_RULE_SUCCESS:
      return Object.assign({}, state, { ...action.payload, isFetching: false })
    case ADD_CI_SUCCESS:
      return Object.assign({}, state, { ...action.payload, isFetching: false })
    case DEFAULT_CI_RULE_ERROR:
      return Object.assign({}, state, { isFetching: false, error: action.payload })
    default:
      return state
  }
}

export default defaultCriticalIllness
