import { themes } from '@bgllife/ui.componentlibrary'
import borders from './border'
import { theme as colors, color, text } from './colors'
import { typeScale } from './typography'

const qqwComponents = {
  components: {
    layout: {
      headerHeight: '94px',
      footerHeight: '0px',
    },
    error: {
      background: color.budget.mordantRed,
      color: color.white,
      borderRadius: borders.radius.r0,
    },
    header: {
      helpLinkColor: color.black,
      background: color.white,
      callbackLink: color.budget.honoluluBlue,
      borderBottom: borders.border1pxSolidSpanishGray,
      closeButtonColor: color.budget.honoluluBlue,
      seperatorColor: color.budget.honoluluBlue,
      logoWidthDesktop: "125px",
      logoHeightDesktop: "auto",
      logoWidthTablet: "125px",
      logoHeightTablet: "auto",
      logoWidthMobile: "94px",
      logoHeightMobile: "auto",
      logoPaddingSmall: "10px 0",
      marginRightMobile: "10px",
    },
    heading: {
      headingOne: {
        color: color.budget.purple,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    paragraph: {
      color: color.budget.darkGrey,
    },
    checkboxSelector: {
      checkbox: {
        color: color.budget.darkGrey,
        marketingOptions: {
          _input: {
            fill: color.budget.darkGrey,
            borderColor: color.budget.darkGrey,
          },
        },
      },
      labelBox: {
        color: color.budget.darkGrey,
      },
      styledInputHidden: {
        checkedLabel: {
          background: color.budget.purple,
        },
      },
    },
    polarQuestion: {
      paragraph: {
        color: color.budget.darkGrey,
        fontWeight: typeScale.fontWeight.bold,
      },
      subParagraph: {
        color: color.budget.darkGrey,
      },
      iconText: {
        color: color.budget.darkGrey,
        width: '120px',
      },
      questionWrapper: {
        labelCheckedColor: color.budget.purple,
        svgSelectedCircleFill: color.white,
        svgSelectedFill: color.budget.purple,
        svgSecondFill: color.budget.purple,
        checkedCircleStroke: color.budget.purple, // circle outline when input is checked
        uncheckedCircleStroke: color.budget.spanishGray, // circle outline when input is not checked
        svgUncheckedDiagonalFill: color.budget.spanishGray,
        svgCheckedDiagonalFill: color.budget.purple,
        selectedOutlineFill: color.budget.spanishGray,
        svgTickGrow: color.white,
      }
    },
    DOB: {
      title: {
        color: color.budget.darkGrey,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    radioInput: {
      activeLabel: {
        background: color.budget.purple,
        color: color.white,
        borderColor: color.budget.purple,
      },
      item: {
        color: color.budget.purple,
        _hover: {
          background: color.budget.purple,
          color: color.white,
          borderColor: color.budget.purple,
        },
      },
      legend: {
        color: color.budget.darkGrey,
        fontWeight: typeScale.fontWeight.bold,
      }
    },
    input: {
      bar: {
        background: color.budget.purple,
      }
    },
    largeIcon: {
      primaryPathFill: color.budget.spanishGray,
      circleFill: color.white,
      circleStroke: color.budget.spanishGray,
      circleLineColor: color.budget.spanishGray,
      shadowFill: color.budget.spanishGray,
      growPathFill: color.budget.spanishGray,
      growPathStroke: color.budget.darkGrey,
      strokeColor: color.budget.darkGrey,
      _hover: {
        fill: color.budget.purple,
        primaryFill: color.budget.purple,
      },
      svgFill: color.budget.spanishGray,
    },
    button: {
      variant: {
        primary: {
          background: color.budget.honoluluBlue,
        },
        underline: {
          color: color.budget.darkGrey,
        },
      },
    },
    modal: {
      headingColor: color.budget.purple,
      closeIcon: {
        fill: color.pleasantPurple,
        width: '25px',
        height: '25px',
        background: 'none',
        padding: '0',
        radius: '0',
      },
    },
    inputWithLabelTransition: {
      input: {
        color: color.budget.purple,
      },
      label: {
        color: color.budget.darkGrey,
      },
      text: {
        color: color.budget.darkGrey,
      },
    },
    inputWithLabelAbove: {
      label: {
        color: color.budget.darkGrey,
      },
    },
    tooltip: {
      tooltipLink: {
        color: color.budget.darkGrey,
      }
    },
    inputPound: {
      regularPound: {
        signColor: color.budget.purple70,
      },
      largePound: {
        signColor: color.budget.purple70,
      },
      input: {
        color: color.budget.purple,
      },
    },
    infoPanel: {
      borderTop: borders.border1pxSolidSpanishGray,
      helpTextColor: color.budget.honoluluBlue,
      backgroundColor: colors.white,
      headingColor: color.budget.purple,
      textContentColor: color.budget.darkGrey,
      buttonTextColor: color.budget.purple,
      footerTextColor: color.budget.darkGrey,
      chartShadow: 'none',
      chartBorderRadius: borders.radius.r0,
      quoteColor: color.budget.purple,
      quoteFontWeight: typeScale.fontWeight.regular,
      quoteBorder: borders.none,
      linkUnderline: typeScale.textDecoration.underline,
      linkColor: color.budget.darkGrey,
      accordion: {
        iconColor: color.budget.purple,
        labelColor: color.budget.purple,
        toggleIconColor: color.budget.honoluluBlue,
        contentColor: color.budget.darkGrey,
      }
    },
    coverAmounts: {
      color: color.budget.purple,
      hover: {
        background: color.budget.purple,
      }
    },
    coverCalculator: {
      headingColor: color.budget.darkGrey,
      textColor: color.budget.darkGrey,
      coverAmountTextColor: color.budget.darkGrey,
    },
    progressBar: {
      barColor: '#e6e6e6',
      activeColor: '#6859a1',
      textColor: '#6859a1',
      inactiveTextColor: '#6f6e6e',
      containerBorder: '1px solid #ccc',
      containerBackgroundColor: '#f6f6f6',
      fontSize: '12px',
    },
    iconWithLabel: {
      color: color.budget.darkGrey,
    },
    interstitialModal: {
      titleColor: color.budget.purple,
      textColor: color.budget.darkGrey,
      progressBarColor: color.budget.purple,
      footerPadding: '0',
      footerBackgroundColor: 'none',
    },
    trustPilot: {
      textColor: color.budget.darkGrey,
      ratingTextColor: color.budget.purple,
    }
  },
  pages: {
    amount: {
      calculatorOptions: {
        color: color.budget.purple,
        active: {
          background: color.budget.purple,
          borderColor: color.budget.purple,
          boxShadow: `0 1px 0 0 ${color.budget.purple}`,
        }
      },
      otherAmount: {
        color: color.budget.purple,
      },
    }
  },
  misc: {
    toolTipLabelText: {
      color: color.budget.darkGrey,
    },
  },
  global: {
    pageBackgroundColor: color.budget.alabaster,
  },
  colors,
  text,
}

const budgetLifeTheme = themes.budgetlife.merge(qqwComponents).getTheme()

export default budgetLifeTheme
