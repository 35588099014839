import {
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
  GET_TERM_FAILURE,
} from 'actions/businessRules'


const initialState = {
  isFetching: false,
  minimum: 0,
  maximum: 0,
  effectiveDate: undefined,
  absoluteExpiration: undefined,
}

const term = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_TERM_REQUEST:
      return Object.assign({}, state, { isFetching: true })
    case GET_TERM_SUCCESS:
      return Object.assign({}, state, { ...action.payload, isFetching: false })
    case GET_TERM_FAILURE:
      return Object.assign({}, state, { isFetching: false, error: action.payload })
    default:
      return state
  }
}

export default term
