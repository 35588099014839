import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { beagleTheme, budgetLifeTheme, virginMoneyTheme } from 'theme'

// import * as serviceWorker from './serviceWorker'
import { brandNameInLowerCase } from 'common/utils'
import App from './App'
import { store, persistor } from './store'
import history from './internalHistory'

const brandName = brandNameInLowerCase()
let brandTheme = {}

if (brandName === 'virginmoney') {
  brandTheme = virginMoneyTheme
} else if (brandName === 'budget') {
  brandTheme = budgetLifeTheme
} else {
  brandTheme = beagleTheme
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={brandTheme}>
          <App />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

if (window.Cypress) {
  window.store = store
}

// serviceWorker.unregister()
