import { createGlobalStyle } from 'styled-components'
import normalize from 'normalize.css'
import { query } from 'theme'

const GlobalStyles = createGlobalStyle`
  @import url(${normalize});
  * {
    box-sizing: border-box;
  }
  html {
    scroll-behavior: 'smooth';
    -webkit-overflow-scrolling: touch;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    line-height: 1.5;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: 'smooth';
    &.darker-bg {
        background: ${props => props.theme.global.pageBackgroundColor};
    }
  }
  .allow-scroll {
    overflow: visible;
  }
 /* .infopanel-open {
   height: 100%;
      max-height: 500px;
      overflow-y: visible;
      scroll-behavior: smooth;
  }  */
  /* #root .scrollClass {
    overflow-y: scroll;
    max-height: 500px;
  } */
  .scrollContainer.open {
    scroll-behavior: 'smooth';
  }
  .scrollClass {
    overflow-y: scroll!important;
    max-height: 500px;
    scroll-behavior: smooth!important;
  }
  #root.scrollClass {
    scroll-behavior: 'smooth';
  }
  #root {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    scroll-behavior: 'smooth';
    -webkit-overflow-scrolling: touch;
  }
  .u-clearfix::after {
    display: block;
    content: "";
    clear: both;
  }

  .tooltip_container {
    &.center {
      > div {
      &:before {
        ${query.combine(['extraSmall', 'small', 'medium'], `
          left: 0;
          right: 0;
          margin: 0 auto;
        `)}
      }
    }
    }
  }

  .ci_container {
    &:hover {
      .tooltip_container {
        div:last-child {
          @media only screen and (min-width: 991px) {
            display: block!important;
            top: -754%;
            right: -300px;
          }
        }
      }
    }
  }

  .header-fixed {
    header {
      @media only screen and (max-width: 450px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
      }
    }
  }

`
export default GlobalStyles
