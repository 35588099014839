import { FORM_SUBMIT } from 'middleware/formSubmit'
import { GUIDANCE_SUBMIT } from 'middleware/guidanceSubmit'

const UPDATE_FORM = 'UPDATE_FORM'
const REMOVE_FORM = 'REMOVE_FORM'
const REMOVE_VALUE = 'REMOVE_VALUE'
const RESET_FORM = 'RESET_FORM'
const RESET_INVIDUAL_FORM = 'RESET_INVIDUAL_FORM'
const UPDATE_STATE_OBJECT = 'UPDATE_STATE_OBJECT'

export const updateForm = (key, value) => ({
  type: UPDATE_FORM,
  key,
  value,
})

export const updateStateObject = (form) => ({
  type: UPDATE_STATE_OBJECT,
  form,
})

export const removeForm = (key, value) => ({
  type: REMOVE_FORM,
  key,
  value,
})

const FORM_REQUEST = 'FORM_REQUEST'
const FORM_SUCCESS = 'FORM_SUCCESS'
const FORM_ERROR = 'FORM_ERROR'

export const submitForm = () => ({
  [FORM_SUBMIT]: {
    types: [FORM_REQUEST, FORM_SUCCESS, FORM_ERROR],
  },
})

const GUIDANCE_REQUEST = 'GUIDANCE_REQUEST'
const GUIDANCE_SUCCESS = 'GUIDANCE_SUCCESS'
const GUIDANCE_ERROR = 'GUIDANCE_ERROR'

export const submitGuidance = () => ({
  [GUIDANCE_SUBMIT]: {
    types: [GUIDANCE_REQUEST, GUIDANCE_SUCCESS, GUIDANCE_ERROR]
  }
})

export const resetIndividualForm = () => ({
  type: RESET_INVIDUAL_FORM,
})

export {
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_ERROR,
  UPDATE_FORM,
  UPDATE_STATE_OBJECT,
  REMOVE_FORM,
  REMOVE_VALUE,
  RESET_FORM,
  RESET_INVIDUAL_FORM,
  GUIDANCE_REQUEST,
  GUIDANCE_SUCCESS,
  GUIDANCE_ERROR,
}
