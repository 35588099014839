export const primaryFont = 'Montserrat, sans-serif'

export const typeScale = {
  align: "left",
  font: {
    'font-family': 'Montserrat, sans-serif',
    'font-weight': 400,
    'line-height': '21px',
  },
  h1: `${29 / 16}rem`,
  h2: '35px',
  h4: '18px',
  headingMargin: '30px 0 30px 0',
  headingLineHeight: '1.2',
  paragraph: `${14 / 16}rem`,
  helperText: '0.8rem',
  copyrightText: '0.7rem',
  fontWeight: {
    bold: '700',
    regular: '400',
  },
  textDecoration: {
    underline: 'underline',
    none: 'none',
  },
}
