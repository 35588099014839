import { combineReducers } from 'redux'

import initialise from './initialise'
import form from './form'
import startClose from './startClose'
import term from './term'
import businessRules from './businessRules'
import trustpilot from './trustpilot'
import criticalIllness from './criticalIllness'
import address from './address'
import analytics from './analytics'
import bankHolidays from './bankholidays'

const appReducer = combineReducers({
  initialise,
  form,
  startClose,
  term,
  businessRules,
  trustpilot,
  criticalIllness,
  address,
  analytics,
  bankHolidays,
})

export default appReducer
