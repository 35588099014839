import React from 'react'
import PropTypes from 'prop-types'
import Overlay from 'components/Overlay'

import Icon from 'components/Icon'
import {
  ModalContainer,
  Heading,
  CloseBtn,
  Content,
  Column,
} from './style'


const Modal = ({
  heading,
  children,
  toggle,
  preventToggle,
  isVisible,
  onError,
  submit,
  size = {
    xs: 12,
    sm: 6,
    lg: 5,
  },
  showCloseButton,
}) => {

  return (
    <Overlay onError={onError} preventToggle={preventToggle} state={{ isVisible, toggle, submit }}>
      {({ getModalProps, status, handleSubmit }) => (
        <Column data-testid="modal-overlay" {...size} className={status}>
          <ModalContainer {...getModalProps()}>
            <Heading>
              {heading}
              {showCloseButton &&
                <CloseBtn type="button" data-id="close" aria-label="Close" onClick={toggle}>
                  <Icon kind="close-modal" size={25} />
                </CloseBtn>
              }
            </Heading>
            <Content onSubmit={handleSubmit}>{children}</Content>
          </ModalContainer>
        </Column>
      )}
    </Overlay>
  )
}

Modal.propTypes = {
  showCloseButton: PropTypes.bool,
  toggle: PropTypes.func,
  preventToggle: PropTypes.bool,
  onError: PropTypes.func,
  isVisible: PropTypes.bool,
  heading: PropTypes.string,
  size: PropTypes.object,
  submit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.node,
  ]),
}

export default Modal
