import { css } from 'styled-components'
import { sizes } from './mediaQueries'

const container = css`
  width: 100%;
  padding: 0 15px 15px 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${sizes[0]}px) {
    max-width: 540px;
  }

  @media (min-width: ${sizes[1]}px) {
    max-width: 720px;
  }

  @media (min-width: ${sizes[2]}px) {
    max-width: 960px;
  }

  @media (min-width: ${sizes[3]}px) {
    max-width: 1140px;
  }
`

export default container
