import { CALL_API } from '../middleware/callAPIMiddleware'

export const GET_BANKHOL_REQUEST = 'GET_BANKHOL_REQUEST'
export const GET_BANKHOL_SUCCESS = 'GET_BANKHOL_SUCCESS'
export const GET_BANKHOL_ERROR = 'GET_BANKHOL_ERROR'

export const getUKBankHolidays = () => ({
  [CALL_API]: {
    url: 'https://www.gov.uk/bank-holidays.json',
    method: 'GET',
    headers: {},
    types: [GET_BANKHOL_REQUEST, GET_BANKHOL_SUCCESS, GET_BANKHOL_ERROR],
  },
  type: GET_BANKHOL_REQUEST,
})
