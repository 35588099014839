import {
  AMOUNT_RULE_REQUEST,
  AMOUNT_RULE_SUCCESS,
  AMOUNT_RULE_ERROR,
  SET_AMOUNTS,
} from 'actions/businessRules'

const businessRules = (
  state = {
    loading: undefined, // Bool
    amounts: {}, // Obj
  },
  action,
) => {
  switch (action.type) {
    case AMOUNT_RULE_REQUEST:
      return Object.assign({}, state, { loading: true })
    case AMOUNT_RULE_SUCCESS:
      return Object.assign({}, state, { loading: true, amounts: action.payload })
    case AMOUNT_RULE_ERROR: {
      return Object.assign({}, state, { error: action.payload })
    }
    case SET_AMOUNTS:
      return Object.assign({}, state, { loading: false, amounts: action.amounts })
    default:
      return state
  }
}

export default businessRules
