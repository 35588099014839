import React from 'react'
import { useSelector } from 'react-redux'
import { isUndefinedOrNull } from 'common/utils'
import { Container, Bar } from './style'

import routes from '../../common/routes'

const ProgressBar = () => {

  const currentPath = window.location.pathname
  const helpType = useSelector(state => state.form.needs.value)
  const isGuidance = helpType === 'calculator'
  let currentProgress = 0
  let complete = false
  let hide = false

  // Ugly but, it's because of the 2 way split at the start
  // otherwise could of done this logic in the routes file
  if (currentPath === '/indicative-quote' || currentPath === '/summary') {
    currentProgress = 100
    complete = true
  } else if (currentPath === '/') {
    currentProgress = 0
  } else if (currentPath === '/name' && isGuidance) {
    currentProgress = 20
  } else if (currentPath === '/partner' && isGuidance) {
    currentProgress = 20
  } else if (currentPath === '/address' && isGuidance) {
    currentProgress = 35
  } else if (currentPath === '/cover-type' && isGuidance) {
    currentProgress = 80
  } else if (currentPath === '/error500') {
    currentProgress = 0
  } else {
    currentProgress = (!isUndefinedOrNull(routes.components[currentPath]) ? routes.components[currentPath].progress : 0)
  }

  if (isUndefinedOrNull(routes.components[currentPath])) {
    hide = true
    if (currentPath === '/summary' || currentPath === '/indicative-quote') {
      hide = false
    }
  }

  return (
    <Container style={{ display: (hide ? 'none' : 'block') }}>
      <div>
        <Bar id="quote_progress" amount={currentProgress}>
          <li className={complete ? 'active complete' : 'active'}>Your details</li>
          <li className={complete ? 'active last' : ''}>Your quick quote</li>
          <li>Lifestyle questions</li>
          <li>Medical questions</li>
          <li>Final price & payment</li>
        </Bar>
      </div>
    </Container>
  )
}

export default ProgressBar
