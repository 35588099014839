import {
  START_AGAIN,
  CLOSE_APP,
  SET_REFERRER,
} from 'actions/startClose'

const initState = {
  referrer: undefined, // String
}

const appResets = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case START_AGAIN:
      return Object.assign({}, state, action.payload)
    case SET_REFERRER:
      return Object.assign({}, state, { referrer: action.referrer })
    case CLOSE_APP:
      return Object.assign({}, state, action)
    default:
      return state
  }
}

export default appResets
