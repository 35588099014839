import beagleStreetLogo from 'assets/images/logo.svg'
import virginMoneyLogo from 'assets/images/virginmoney.png'
import { color } from 'theme/colors'
import decreasingGraphVirginmoney from 'assets/images/decreasing-graph-virginmoney.jpg'
import decreasingGraphBeagleStreet from 'assets/images/decreasing-graph-mobile.png'
import decreasingGraphBudget from 'assets/images/decreasing-graph-budget.jpg'

import { removeWhitespace } from './utils'

const brandChartImage = (brand) => {
  if (brand === 'virginmoney') return decreasingGraphVirginmoney
  if (brand === 'budget') return decreasingGraphBudget

  return decreasingGraphBeagleStreet
}

const brandPhoneNumbers = (brand) => {
  let phoneNumberFormatted = "0800 048 0490"

  if (brand === 'budget') phoneNumberFormatted = "0330 018 8806"
  if (brand === 'virginmoney') phoneNumberFormatted = "0800 294 7575"

  const phoneNumberWithoutSpaces = removeWhitespace(phoneNumberFormatted)

  return {
    phoneNumberFormatted,
    phoneNumberWithoutSpaces,
  }
}

const brandName = (brand) => {
  let fullBrandName = 'Beagle Street'
  let alternativeBrandName = 'Beagle Street'
  let toolTipBrandName = 'Beagle Street Life Insurance’s'
  let errorBrandName = "Beagle Street homepage"

  if (brand === 'budget') {
    fullBrandName = 'Budget Life'
    alternativeBrandName = 'Budget Insurance'
    toolTipBrandName = 'Budget Life Insurance’s'
    errorBrandName = "Budget Life Insurance"
  }

  if (brand === 'virginmoney') {
    fullBrandName = 'Virgin Money'
    alternativeBrandName = 'Virgin Money'
    toolTipBrandName = 'Virgin Money Life Insurance’s'
    errorBrandName = "Virgin Money Life Insurance"
  }

  return {
    brandName: fullBrandName,
    alternativeBrandName,
    toolTipBrandName,
    errorBrandName
  }
}

const brandLinks = (brand) => {
  let policyTermsAndConditionsLink = 'https://cdn.bgllife.co.uk/beaglestreet/downloads/beagle-street-policy-terms-and-conditions-V9.pdf'
  let privacyLink = "https://www.beaglestreet.com/privacy-policy/"
  let callbackUrl = "https://www.beaglestreet.com/call-back"
  let homepageLink ="https://beaglestreet.com"

  if (brand === 'budget') {
    policyTermsAndConditionsLink = 'https://cdn.bgllife.co.uk/budget/life-policy-tcs.pdf'
    privacyLink = "https://www.budgetinsurance.com/life-privacy-policy/"
    callbackUrl = ""
    homepageLink ="https://www.budgetinsurance.com/life-insurance/"
  }

  if (brand === 'virginmoney') {
    policyTermsAndConditionsLink = 'https://cdn.bgllife.co.uk/virginmoney/virgin-money-policy-terms-and-conditions.pdf'
    privacyLink = "https://life.virginmoney.com/static/PrivacyAndCookiePolicy"
    callbackUrl = ""
    homepageLink ="https://uk.virginmoney.com/insurance/life-insurance/lifestyle/"
  }

  return {
    policyTermsAndConditionsLink,
    privacyLink,
    callbackUrl,
    homepageLink
  }
}

const brandDetails = (brand) => {
  const contactdetails = brandPhoneNumbers(brand)
  const links = brandLinks(brand)
  const brandNames = brandName(brand)

  return {
    contactdetails,
    links,
    brandNames,
  }
}

const brandHeaderValues = (brand) => {
  const {
    contactdetails,
    links: { callbackUrl, homepageLink },
    brandNames: { alternativeBrandName, }
  } = brandDetails(brand)

  let logoImgSrc
  let iconColor

  switch (brand) {
    case 'budget':
      logoImgSrc = "https://cdn.bgllife.co.uk/budget/logo/logo.png"
      iconColor = color.budget.honoluluBlue
      break
    case 'virginmoney':
      logoImgSrc = virginMoneyLogo
      iconColor = color.white
      break
    default:
      logoImgSrc = beagleStreetLogo
      iconColor = color.white
  }

  return {
    contactdetails,
    logoImgSrc,
    iconColor,
    callbackUrl,
    homepageLink,
    brandNameLogoAltText: alternativeBrandName,
  }
}

const brandContactDetails = (brand) => {
  const phoneNumber = brandPhoneNumbers(brand)

  return {
    phoneNumber
  }
}

export {
  brandHeaderValues,
  brandContactDetails,
  brandName,
  brandLinks,
  brandDetails,
  brandChartImage,
}
