import { join } from 'lodash'
import { formatCurrency } from '../../common/utils'

const dataLayerPush = (obj) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push(obj)
}

/**
 * Function to push error to DL and return the DL object.
 * @param {string} errorType - Page/Topic of error, for example eg. Name.
 * @param {(string|object)} message - What the error is, whether its an array or single message.
 * @param {number} amount - Amount entered.
 * @param {number} term  - Term entered.
 * @param {number} ciValue - Calculated critical illness amount.
 * @param {number} ciAmount - Entered ciAmount, mainly for Quote page.
 * @param {string} location - Summary page dropdown selected.
 * @returns {object} Object for datalayer, needs return so we can post to dispatch event.
 */
const errorTypeDatalayer = (
  errorType,
  message,
  amount,
  term,
  ciValue,
  ciAmount,
  location,
) => {
  const errorObject = {
    event: 'pageError',
    'Error Message': ''
  }

  if (typeof message === 'string') {
    errorObject["Error Message"] = `${errorType}: ${message}`
    if (amount) errorObject["Error Message"] = `Cover amount - entered £${formatCurrency(amount)} - ${message}`
    if (term) errorObject["Error Message"] = `Cover length - entered ${term} - ${message}`
    if (ciValue) errorObject['Error Message'] = `Critical Illness - entered £${formatCurrency(ciValue)} - ${message}`
    if (ciAmount) errorObject['Error Message'] = `Critical Illness - entered £${formatCurrency(ciAmount)} - ${message}`
    if (location && amount) errorObject['Error Message'] = `${location} - entered ${amount} - ${message}`
  }
  else if (Array.isArray(message)) errorObject["Error Message"] = `${errorType}: ${join(message, ' | ')}`

  dataLayerPush(errorObject)
  return errorObject
}

export {
  dataLayerPush,
  errorTypeDatalayer,
}