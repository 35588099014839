import { chooseComponent } from 'common/utils'

const routes = {
  paths: {
    split: [
      '/your-needs',
    ],
    default: [
      '/name',
      '/partner',
      '/address',
      '/cover-type',
      '/cover-amount',
      '/cover-length',
      '/critical-illness',
      '/child-critical-illness',
      '/contact-details',
    ],
    /*
      Guidance Journey Path
    */
    guidance: [
      '/protect',
      '/payout-type',
      '/name',
      '/partner',
      '/children',
      '/address',
      '/mortgage',
      '/bills',
      '/debts',
      '/savings',
      '/cover-type',
      '/contact-details',
    ],
  },
  components: {
    '/protect': {
      component: 'Guidance',
      store: 'protect',
      skippable: true,
      progress: 10,
    },
    '/payout-type': {
      component: 'Guidance/PayoutType',
      store: 'payoutType',
      skippable: false,
      progress: 15,
    },
    '/your-needs': {
      component: 'Needs',
      store: 'needs',
      skippable: false,
      progress: 0,
    },
    '/name': {
      component: 'Name',
      store: 'name',
      skippable: false,
      progress: 10,
    },
    '/partner': {
      component: 'Partner',
      store: 'name',
      skippable: true,
      progress: 10,
    },
    '/address': {
      component: chooseComponent('Address'),
      store: 'address',
      skippable: false,
      progress: 30,
    },
    '/cover-type': {
      component: 'Guidance/CoverType',
      store: 'type',
      skippable: false,
      progress: 40,
    },
    '/cover-amount': {
      component: chooseComponent('Amount'),
      store: 'amount',
      skippable: false,
      progress: 50,
    },
    '/cover-length': {
      component: chooseComponent('Length'),
      store: 'term',
      skippable: false,
      progress: 60,
    },
    '/critical-illness': {
      component: 'PolarQuestion',
      store: 'ci',
      skippable: false,
      progress: 70,
    },
    '/contact-details': {
      component: 'Contact',
      store: 'contact',
      skippable: false,
      progress: 90,
    },
    '/child-critical-illness': {
      component: 'PolarQuestion',
      store: 'ci',
      skippable: true,
      progress: 80,
    },
    /*
      Guidance Journey Components/Containers
    */
    '/children': {
      component: 'Guidance/Children',
      store: 'children',
      skippable: false,
      progress: 30,
    },
    '/mortgage': {
      component: 'Guidance/Mortgage',
      store: 'mortgage',
      skippable: false,
      progress: 40,
    },
    '/bills': {
      component: 'Guidance/Bills',
      store: 'bills',
      skippable: false,
      progress: 50,
    },
    '/debts': {
      component: 'Guidance/Debts',
      store: 'debts',
      skippable: false,
      progress: 60,
    },
    '/savings': {
      component: 'Guidance/Savings',
      store: 'savings',
      skippable: false,
      progress: 70,
    },
  },
}

export default routes
