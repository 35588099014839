import {
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_ERROR,
  UPDATE_FORM,
  UPDATE_STATE_OBJECT,
  REMOVE_VALUE,
  RESET_FORM,
  RESET_INVIDUAL_FORM,
  GUIDANCE_REQUEST,
  GUIDANCE_SUCCESS,
  GUIDANCE_ERROR,
} from 'actions/form'

export const initState = {
  who: {},
  needs: '',
  name: {},
  gender: {},
  dob: {},
  smoker: {},
  type: {},
  ci: {},
  contact: {},
  quote: {},
  term: {},
  amount: {},
  data: {},
  calculator: {},
  children: {},
  mortgage: {},
  bills: {},
  debts: {},
  savings: {},
  payoutType: {}
}

const form = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case FORM_REQUEST:
    case GUIDANCE_REQUEST:
      return Object.assign({}, state, { loading: true })
    case FORM_SUCCESS:
    case GUIDANCE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: Object.assign({}, state.data, action.payload),
      })
    case FORM_ERROR:
    case GUIDANCE_ERROR:
      return Object.assign({}, state, { loading: false, error: action.payload })
    case UPDATE_FORM:
      return Object.assign({}, state,
        {
          [action.key]:
            { ...state[action.key], ...action.value, isDirty: true },
        },
      )
    case UPDATE_STATE_OBJECT:
      return Object.assign({}, state, action.form)
    case REMOVE_VALUE:
      return Object.keys(action.data)
        .filter(key => ['value'].includes(key))
        .reduce((obj, key) => {
          obj[key] = action.data[key]
          return Object.assign(
            {},
            state,
            {
              [action.storeKey]:
                { ...obj, isDirty: true },
            },
          )
        }, {})
    case RESET_INVIDUAL_FORM:
      return Object.assign({}, state, { data: {}, error: '' }) // need to replace data to empty for both
    case RESET_FORM:
      return initState
    default:
      return state
  }
}

export default form
