import { color } from "./colors"

const borders = {
  none: 'none',
  border1pxSolidSpanishGray: `1px solid ${color.budget.spanishGray}`,
  border1pxPastelGrey: `1px solid ${color.pastelGrey}`,
  radius: {
    r0: '0',
    r3: '3px',
  }
}

export default borders
